import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { CommentsModalContext } from "../contexts/CommentsModalContext";
import CustomMedia from "./CustomMedia";

const YipImages = (props) => {
	const { imageContainerCounter, height, maxHeight, yip } = props;
	const [hovering, setHovering] = useState(false);
	const { setShowComments, setCommentModalData } =
		useContext(CommentsModalContext);
	useEffect(() => {
		// console.log("Yip: '" + yip.id + "' data: " + JSON.stringify(yip.photos));
	}, [yip]);

	const handleOpenComments = () => {
		setCommentModalData(yip);
		setShowComments(true);
	};
	return (
		<Grid
			className="post-yip-images"
			xs={12}
			container
			item
			rowGap={1}
			columnSpacing={0.5}
			p={1}
			sx={{
				height: height,
				maxHeight: maxHeight,
				// backgroundColor: "#DBA622",
				backgroundColor: "white",
				display: "flex",
				flex: 1,
				flexDirection: "row",
				alignItems: "center",
				cursor: "pointer",
				borderRadius: "5px",
				overflowY: "scroll",
				overflowX: "hidden",
			}}
			onClick={handleOpenComments}
		>
			{yip.photos?.length > 0 &&
				yip.photos.map((photo, index) => {
					switch (true) {
						case yip.photos.length == 1:
							return (
								<Grid
									container
									item
									display={"flex"}
									xs={12}
									// overflowX={"hidden"}
									style={{
										maxWidth: "100%",
									}}
									key={`yip-photo-${index}`}
								>
									<CustomMedia
										photo={photo}
										onClick={() => {}}
									/>
								</Grid>
							);
						case yip.photos.length == 2:
							return (
								<Grid
									container
									item
									display={"flex"}
									xs={12}
									height={"75%"}
									key={`yip-photo-${index}`}
								>
									<CustomMedia
										photo={photo}
										onClick={() => {}}
									/>
								</Grid>
							);

						case yip.photos.length > 2:
							//  && yip.photos.length < 5
							if (index === 0) {
								return (
									<Grid
										// container
										item
										display={"flex"}
										xs={12}
										key={`yip-photo-${index}`}
										// backgroundColor={"red"}
									>
										<CustomMedia
											photo={photo}
											onClick={() => {}}
										/>
									</Grid>
								);
							}
							if (index < 4) {
								return (
									<Grid
										container
										item
										display={"flex"}
										direction={"column"}
										xs={yip.photos.length == 3 ? 6 : 4}
										width={"100%"}
										maxHeight={"20%"}
										key={`yip-photo-${index}`}
										// bgcolor={"blue"}
									>
										<div
											style={{
												display: "flex",
												// flex: 1,
												height: "10vh",
												maxHeight: "10vh",
											}}
										>
											<CustomMedia
												photo={photo}
												onClick={() => {}}
											/>
											{yip.photos?.length > 0 &&
											yip.photos.length > 4 &&
											index == 3 &&
											yip.photos.length > imageContainerCounter ? (
												<div
													style={{
														position: "absolute",
														fontSize: "min(5vw, 5vh)",
														color: "white",
														top: "50%",
														transform: "translateX(50%)",
														// background: "rgba(0,0,0,0.5)",
													}}
												>
													{"+" +
														(yip.photos.length - imageContainerCounter - 1)}
												</div>
											) : (
												""
											)}
										</div>
									</Grid>
								);
							}
					}
				})}
		</Grid>
	);
};

export default YipImages;
