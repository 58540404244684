import React, { useContext } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GLChatMessages from "./GLChatMessages";
import ChatInput from "./ChatInput";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import { ChatContext } from "../contexts/ChatContext";
import { Paths } from "./Router";
import { Grid, Typography } from "@mui/material";
import resetMessageCount from "../util/resetMessageCount";
import { UserContext } from "../contexts/UserContext";

function Chat() {
  const navigate = useNavigate();
  const { data, dispatch } = useContext(ChatContext);
  const [user, setUser] = useContext(UserContext)

  return (
    <ChatContext.Provider value={{ data, dispatch }}>
      <Grid container xs={12} item className="chat" height={"80%"}>
        <Grid container item xs={12} className="chatInfo">
          <Grid item xs={2} >
           <Typography id="chatUserfirstname"> {data.chatUser?.firstName}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={10}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid
              item
              xs={9}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CameraAltIcon />
              <PersonAddIcon />
              <MoreHorizIcon />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <CloseIcon onClick={() => {
                resetMessageCount(user);
                navigate(Paths.LETSCONNET)
              }
              } />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="glmessages"sx={{overflow:"scroll"}}>
          <GLChatMessages />
        </Grid>
        </Grid>

        <Grid item xs={12}>
          <ChatInput />
        </Grid>
    </ChatContext.Provider>
  );
}

export default Chat;
