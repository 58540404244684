import React, { useContext, useState, useEffect } from "react";
import Sidbar from "../components/Sidbar";
import Chat from "../components/Chat";
import "./GLChatingSystem.css";
import { Grid } from "@mui/material";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { ChatContext } from "../contexts/ChatContext";
import { doc, getDoc } from "firebase/firestore";
import admin from "../util/admin";

const GLChatingSystem = () => {
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);
  const { data, dispatch } = useContext(ChatContext);
  const { db } = admin();
  const [unreadCount, setUnreadCount] = useState(0);

  

  return (
    // <div className='glchatingsystem'>
    <Grid  container item className='glchatingsystem' xs={12} style={{height: "90vh", overflow: "scroll"}}>
      {/* <div className='container'> */}
      <AuthContext.Provider value={auth}>
        <UserContext.Provider value={[user, setUser]}>
          <ChatContext.Provider value={{ data, dispatch }}>
              <Grid item xs={4} className='container'>
                <Sidbar className="sidebar" />
              </Grid>
              <Grid item xs={8} style={{   border: "1px solid rgb(255, 255, 255)",
                  borderRadius: "10px", width: "65%", height: "80%"}}>
                  <Chat />
              </Grid>
          </ChatContext.Provider>
        </UserContext.Provider>
      </AuthContext.Provider>
      {/* </div>  */}
    </Grid>
    // </div>
  );
}

export default GLChatingSystem;
