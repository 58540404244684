import { Button, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import Carousel from "../components/Carousel";
import { UserContext } from "../contexts/UserContext";
import Signup from "./Signup";
import { SignupContext } from "../contexts/SignupContext";
import LCBgplain from "../assets/Background/LCBgplain.jpg";
import GlDrawer1 from "../components/GlDrawer1";
import LetsconnetPink from "../assets/Background/LetsconnetPink.jpg";
import dashboardImage from "../assets/dashboardImage.png";

export const Home = () => {
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [initializing, setInitializing] = useState(true);
  const [signup, setSignup] = useState(false);
  useEffect(() => {
    if (user) {
      navigate("/letsconnect");
    }
    setInitializing(false);
  }, [user]);
  if (initializing) {
    return <div> Loading...</div>;
  }
  return (
    <Grid
      id="HomeContainer"
      container
      item
      display={"flex"}
      direction={"column"}
      xs={12}
      sx={{
        bgcolor: "#556D30",
        // maxWidth: "100vw",
        Height: "vh",
        backgroundImage: `url(${LetsconnetPink})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SignupContext.Provider value={[signup, setSignup]}>
        <Body />
        <Modal
          open={signup}
          onClose={() => {
            setSignup(false);
          }}
          className="signup-modal"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            // overflowY: "scroll",
            // backgroundColor: "white",
          }}
        >
          <Signup />
        </Modal>
      </SignupContext.Provider>
    </Grid>
  );
};

const Body = () => {
  const navigate = useNavigate();
  const [signup, setSignup] = useContext(SignupContext);
  return (
    <Grid container xs={12} alignSelf={"center"}>
      <Grid
        item
        xs={12}
        alignSelf={"center"}
        display={"flex"}
        flexDirection={"row"}
      >
        <Grid
          item
          container
          xs={9}
          alignSelf={"center"}
          marginLeft={"20px"}
          marginTop={"50px"}
        >
          <Typography fontWeight={"bold"} fontSize={"40px"}>
            Let's Get You That Perfect Social Experience.
            <br />
            <span>
              <Grid item container xs={12}>
                <Grid item xs={6} display="flex" justifyContent={"flex-end"}>
                  <Button
                    onClick={() => {
                      setSignup(true);
                      // navigate("/signup");
                    }}
                    variant="secondary"
                  >
                    <Typography
                      fontFamily={"cursive"}
                      fontWeight={"bold"}
                      color={"purple"}
                      fontSize={"24px"}
                      textTransform={"none"}
                    >
                      Sign up Now!
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      // setSignup(true)
                      navigate("/login");
                    }}
                    variant="secondary"
                  >
                    <Typography
                      fontFamily={"cursive"}
                      fontWeight={"bold"}
                      color={"purple"}
                      fontSize={"24px"}
                      textTransform={"none"}
                    >
                    Or   Login!
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </span>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Grid item container xs={12} marginTop={"50px"}>
            <Grid item xs={10}>
              <img
                src={dashboardImage}
                alt="dashboard"
                style={{
                  width: "100%",
                  height: "100%",
                  // objectFit: "scale-down",
                  alignSelf: "flex-start",
                  justifyContents: "start",
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container height={"20vh"}></Grid>
      {/* <Grid container item xs={12} sm={6} md={4} marginLeft={"20px"} > */}
      {/* <Grid container
		  item xs={6} sm={6} md={4} lg={2} xl={2}
		      display={"flex"}
          direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
           alignSelf={"center"}
           marginLeft={"18px"}
          >
        <Carousel width={"5%"} height={"5%"} objectFit={" scale-down"}  />
        </Grid> */}
    </Grid>
  );
};

export default Home;
