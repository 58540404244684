import admin from "./util/admin"
import { useContext, useEffect, useMemo, useState} from "react";
import { UserContext } from "./contexts/UserContext";
import { useUser } from "./hooks/useUser";
import { AuthContext } from "./contexts/AuthContext";
import { FirebaseContext } from "./contexts/FirebaseContext";
import { StorageContext } from "./contexts/StorageContext";
import { light, ThemeContext } from "./contexts/ThemeContext";
import Router from "./components/Router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { EditProfileContext } from "./contexts/EditProfileContext";
import { PhotosModalContext } from "./contexts/PhotosModalContext";
 import { ChatContextProvider} from "./contexts/ChatContext";


function App() {
  const {db, auth, storage, onAuthStateChanged, sessionIdKey } = admin(); // Call the admin function to get the parameters

  const [theme, setTheme] = useState(light);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [showPhotos, setShowPhotos] = useState(false);
  // const {state:data, dispatch} = useContext(ChatContext)

  const sessionID = useMemo(() => {
    return localStorage.getItem(sessionIdKey);
  }, []);

  const [user, setUser] = useUser();
  useEffect(() => {
    
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
    });

    return () => {};
  }, [user, setUser, auth, onAuthStateChanged]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      <AuthContext.Provider value={auth}>
        <FirebaseContext.Provider value={db}>
          <StorageContext.Provider value={storage}>
            <ThemeContext.Provider value={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <PhotosModalContext.Provider value={{ showPhotos, setShowPhotos }}>

                <EditProfileContext.Provider value={[ editProfileModal, setEditProfileModal ]}>
                <ChatContextProvider>
								<Router/>
                </ChatContextProvider>
                </EditProfileContext.Provider>
              </PhotosModalContext.Provider>
              </LocalizationProvider>
            </ThemeContext.Provider>
          </StorageContext.Provider>
        </FirebaseContext.Provider>
      </AuthContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
