import React, { useContext, useEffect, useState } from 'react';
import GLChatMessage from './GLChatMessage';
import '../screens/GLChatingSystem.css';
import { ChatContext } from '../contexts/ChatContext';
import { onSnapshot, doc } from '@firebase/firestore';
import admin from '../util/admin';
import { Grid } from '@mui/material';

function GLChatMessages() {
  const [glmessages, setMessages] = useState([]); // Initialize messages as an empty array
  const { data, dispatch } = useContext(ChatContext);
  const { db } = admin();


  useEffect(()=>{
    const getmessages = () => {
      const unsub = onSnapshot(
        doc(db, "chats", data.chatId),
        (doc) => {
          if (doc.exists()) {
          setMessages(doc.data().messages);
          }
        }
      );
      return () => {
        unsub();
      };
    };
    data.chatUser.id && getmessages();
  }, [data.chatUser, db, setMessages, data.chatId]);

  return (
    <ChatContext.Provider value={{data, dispatch}}>
    <Grid container item xs={12}  >
      {glmessages && glmessages.map(m => (
        <GLChatMessage glmessage={m} key={m.id} />
      ))}
    </Grid>
    </ChatContext.Provider>
  );
}

export default GLChatMessages;
