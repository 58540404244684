import React from "react";
import Modal from "react-modal";
import { Button, Alert, AlertTitle } from "@mui/material";

const AlertModal = ({
  isOpen,
  closeModal,
  alertMessage,
  alertTitle,
  alertSeverity,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Alert Modal"
      style={{
        overlay: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          width: "80%",
          height: "25%",
          maxWidth: "100%",
          maxHeight: "100%",
          margin: "auto",
          border: "4px solid #000", // Thick border
          borderRadius: "8px", // Rounded corners
          backgroundColor: "peachpuff", // Light yellow background
        //   textAlign: "center",
        },
      }}
    >
      <Alert severity={alertSeverity}>
        <AlertTitle>{alertTitle}</AlertTitle>
        {alertMessage}
      </Alert>
      <Button
        variant="contained"
        color="primary"
        onClick={closeModal}
        style={{ marginTop: "20px" }}
      >
        OK
      </Button>
    </Modal>
  );
};

export default AlertModal;
