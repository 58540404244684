import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomMedia from "../components/CustomMedia";
import { ThemeContext } from "../contexts/ThemeContext";
import LCBgplain from "../assets/Background/LCBgplain.jpg";
import {
  ref,
  uploadBytes,
  list,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";

import firebase from "firebase/app";
import "firebase/firestore";

// import { StorageContext } from "../contexts/StorageContext";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";

import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { Label, Output } from "@mui/icons-material";
import GlDrawer1 from "../components/GlDrawer1";

const doGetFetch = async (path) => {
  const url =
    "https://us-central1-gl-ss-functions.cloudfunctions.net/api/" + path;
  try {
    const response = await fetch(url, { method: "GET" });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const doPostFetch = async (path, body) => {
  const url =
    "https://us-central1-gl-ss-functions.cloudfunctions.net/api/" + path;
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
function LatestLaEvents() {
  return (
    <Grid
      container
      xs={12}
      display={"flex"}
      direction={"row"}
      //   height={"100%"}
      sx={{
        bgcolor: "#dba622",
        flex: 1,
        Width: "vw",
        Height: "vh",
        maxHeight: 'vh',
        alignItems: "center",
        backgroundImage: `url(${LCBgplain})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Body />
    </Grid>
  );
}

const Body = () => {
  // const theme = useContext(ThemeContext);
  const [showPhoto, setShowPhoto] = useState(false);
  const [inputPhoto, setInputPhoto] = useState(null);
  // const [photos, setPhotos] = useState([]);
  const [events, setEvents] = useState([]);
  const [modalStartTime, setModalStartTime] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [eventDays, setEventDays] = useState([]);
  const [noEvents, setNoEvents] = useState("  ");

  // const storage = useContext(StorageContext);

  const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    // width: 15,
  }));

  const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === "light"
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light,
    // width: 200,
  }));

  const fetchEventByDate = async (date) => {
    try {
      const date1 = date.toDate();
      const firebasedate = date1.toLocaleDateString().replaceAll("/", "-");
      const eventsByDates = await doPostFetch("geteventsbydates", {
        firebasedate,
      });

      if (eventsByDates.length === 0) {
        setNoEvents("No events found on the selected date:"); //noEvents, setNoEvents
        setEvents([]);

        return;
      }
      setNoEvents(" ");
      setTimeout(() => {
        setEvents(eventsByDates);
      }, 1000);
    } catch (error) {
      setNoEvents("No events found on the selected date");
      setEvents([]);
    }
  };

  const handleDateChange = (date) => {
    console.log("Selected Date:", date);
    setSelectedDate(date);
    fetchEventByDate(date);
  };

  // Function to fetch all dates with events
  const fetchdatesWithEvents = async () => {
    try {
      const datesWithEvents = await doGetFetch("getallglevents");
      if (datesWithEvents.length === 0) {
        setEventDays([]);
        return;
      }
      // const eventDays = datesWithEvents.map((event) => {
      //     const covt = new Date(event.eventDate);
      //     return dayjs(covt);
      // });
      setEventDays(datesWithEvents);
      setNoEvents(" ");
    } catch (error) {
      setNoEvents("Error event data. Please check back later.");
    }
  };

  // Function to check if a date has an event
  const isEventDay = (date) => {
    const dateString = date.toISOString().split("T")[0];
    return eventDays.includes(dateString);
  };

  // Custom function to render date cells with events in red font color
  const renderDayContents = (date, _, __) => {
    // fetchdatesWithEvents();
    return (
      <div style={{ color: isEventDay(date) ? "red" : "inherit" }}>
        {date.getDate()}
      </div>
    );
  };

  useEffect(() => {
    const fetchOneEvent = async () => {
      try {
        const event = await doGetFetch("getthelatestevent");
        if (event.length === 0) {
          setNoEvents(
            "No Events entered in the system yet. Please check back later."
          );
          setEvents([]);
          return;
        }
        const evt = event[0];
        setNoEvents(" ");
        setEvents(event);
        const covt = new Date(evt.eventDate);
        setSelectedDate(dayjs(covt));
        console.log("Selected Date:", selectedDate);
      } catch (error) {
        setNoEvents(
          "There is an error fetching the latest event. Please check back later."
        );
      }
    };
    fetchdatesWithEvents();
    fetchOneEvent();
  }, []);

  return (
    <Grid item container xs={12} display={"flex"} justifyContent={"center"}>
      <Grid item xs={4} alignSelf={"center"}>
        <GlDrawer1 />
      </Grid>
      <Grid
            item
            xs={8}
            justifyContent={"center"}
            overflow={"scroll"}
       >
        <Grid
          container
          item
          xs={12}
          display={"flex"}
          direction={"row"}
          alignSelf={"center"}
          justifyContent={"center"}
        >
          <Modal
            open={showPhoto}
            onClose={() => {
              setShowPhoto(false);
              setInputPhoto(null);
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "min(5vw, 5vh)",
              }}
            >
              <CustomMedia
                photo={inputPhoto}
                onClick={() => {}}
                startTime={modalStartTime}
                autoplay={true}
                setStartTime={setModalStartTime}
              />
            </div>
          </Modal>
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                margin: "min(.5vh,.5vw)",
                color: "red",
              }}
            >
              {" "}
              {noEvents}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: "blue",
              width: "100%",
              autocomplete: "off",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  id="date-picker"
                  label="World's Latest Events"
                  //  defaultValue={selectedDate}
                  value={selectedDate}
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  fontSize="16px"
                  sx={{ width: 20 }}
                  slots={{
                    openPickerIcon: EditCalendarRoundedIcon,
                    openPickerButton: StyledButton,
                    day: StyledDay,
                  }}
                  slotProps={{
                    openPickerIcon: { fontSize: "large" },
                    openPickerButton: { color: "secondary", size: "50%" },
                    textField: {
                      variant: "filled",
                      focused: true,
                      color: "secondary",
                    },
                  }}
                  renderDay={renderDayContents}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid
                item
                xs={12}
                marginLeft={"25px"}
                marginBottom={"20px"}
                sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexWrap: "wrap",
                }}
          >
            {events.map((item) => {
              return (
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={12}
                  >
                        <Typography
                            variant=" "
                            fontSize={"min(1.5vw, 1.5vh)"}
                            style={{
                                textAlign: "center",
                                margin: "min(.5vh,.5vw)",
                            }}
                        >
                            <Typography>{item.eventName}</Typography>
                            <Typography color={"green"}>
                                {" "}
                                **** {item.location} ****{" "}
                            </Typography>
                        </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    display={"flex"}
                    flexDirection={"row"}
                    alignSelf={"center"}
                    justifySelf={"center"}
                    xs={12}
                    sm={10}
                    md={10}
                    style={{ outline: "5px solid purple" }}
                     height={"250px"}
                     width={"100%"}
                    overflow={"scroll"}
                    

                  >
                    {item.items.map((file, index) => {
                      return (
                        <Grid
                          item
                          key={`${file.url}`}
                          xs={6}
                          sm={6}
                          md={4}
                          // display={"flex"}
                          style={{
                            height: "70%",
                            // maxHeight: "50%",
                            width: "650%",
                          }}
                          p={1}
                        >
                          <CustomMedia
                            photo={file}
                            onClick={(e) => {
                              e.preventDefault();
                              if (file.type === "video/mp4") {
                                e.target.playing
                                  ? e.target.play()
                                  : e.target.pause();
                                setModalStartTime(e.target.currentTime);
                              }
                              setShowPhoto(true);
                              setInputPhoto(file);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LatestLaEvents;
