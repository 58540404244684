import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import admin from '../util/admin';
import SearchModal from './glModals/SearchModal';
import { Paths } from "./Router";

const MemberSearch = ({ searchTerm }) => {
  const [members, setMembers] = useState([]);
  const { db } = admin();
  const {open, setOpen }= useState(false);
//   const {close, setclose }= useState(false);

const handleClose = () => {
     // Define handleClose function logic here
     console.log('Search modal closed');   
     Navigate(Paths.LETSCONNET);

  };
  useEffect(() => {

    if(searchTerm)
    {
        setOpen(true);
    }
    const fetchMembers = async () => {
      try {
        const searchTermTrimLC = searchTerm.trim().toLowerCase();
        const queryValue = searchTermTrimLC + '\uf8ff'; // '\uf8ff' is a special Unicode character to make the query behave like 'LIKE' operator
        if (searchTermTrimLC && searchTermTrimLC !== '') { // Check if searchTerm is truthy before calling trim
          const q = query(collection(db, 'users'), 
          where('name', '>=', searchTermTrimLC),
          where("name", "<=", queryValue),
          );
          const querySnapshot = await getDocs(q);
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          setMembers(data);
        } else {
          setMembers([]);
        }
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers(); // Call fetchMembers directly, no need for additional condition here
  }, [searchTerm, db, setOpen]);

  return (
    <SearchModal isOpen={open} onClose={handleClose}>
      <div>
        <h2>Search Results</h2>
        <ul>
          {members.map((member) => (
            <li key={member.id}>
              <Link to={`/member/${member.id}`}>
                <img src={member.photoURL} alt="Member" />
                {member.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </SearchModal>
  );
};

export default MemberSearch;

// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import admin from '../util/admin';
// // import Modal from './Modal'; // Import your modal component
// import SearchModal from './glModals/SearchModal';

// const MemberSearch = ({ searchTerm, onClose }) => {
//   const [members, setMembers] = useState([]);
//   const { db } = admin();

//   useEffect(() => {
//     const fetchMembers = async () => {
//       try {
//         const q = query(collection(db, 'users'), where('name', '>=', searchTerm));
//         const querySnapshot = await getDocs(q);
//         const data = [];
//         querySnapshot.forEach((doc) => {
//           data.push({ id: doc.id, ...doc.data() });
//         });
//         setMembers(data);
//       } catch (error) {
//         console.error('Error fetching members:', error);
//       }
//     };

//     if (searchTerm.trim() !== '') {
//       fetchMembers();
//     } else {
//       setMembers([]);
//     }
//   }, [searchTerm, db]);

//   return (
//     <SearchModal isOpen={!!searchTerm} onClose={onClose}>
//       <div>
//         <h2>Search Results</h2>
//         <ul>
//           {members.map((member) => (
//             <li key={member.id}>
//               <Link to={`/member/${member.id}`}>
//                 <img src={member.photoURL} alt="Member" />
//                 {member.name}
//               </Link>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </SearchModal>
//   );
// };

// export default MemberSearch;
