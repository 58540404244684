import React, { useContext, useState } from "react";
import {TextField, Button, Grid } from "@mui/material";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";

const OrganizerForm = ({handleOpenOrgModal, titleOrgEvent,setTitleOrgEvent,}) => {
  const [organizerCode, setOrganizerCode] = useState("");
  const [EventAccessCode, setEventAccessCode] = useState("");
  const db = useContext(FirebaseContext);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (e.target.id === "EventOrganizerCode") {
      setOrganizerCode(value);
    } else {
      setEventAccessCode(value);
    }
    setTitleOrgEvent(titleOrgEvent); // Set the titleOrgEvent to the value of the EventOrganizerCode
  };
  const handleClick =async () => {

    try {
        // Check if the EventAccessCode code exists in the "accessCodes" collection
        const accessCodeQuery = query(
          collection(db, "organizercodes"),
          where("rsvpAccessCode", "==", EventAccessCode),
          where("organizerCode", "==", organizerCode),
          limit(1)
        );
        const accessCodeSnapshot = await getDocs(accessCodeQuery);
        if (accessCodeSnapshot.empty) {
          // setAccessCode("BAD ACCESS CODE");
          handleOpenOrgModal("BAD CODE", "BAD CODE");
          return;
        }
      } catch (error) {
        console.error("Error checking codes:", error);
        return;
      }   
    handleOpenOrgModal(organizerCode, EventAccessCode); // Pass the organizerCode and EventAccessCode to handleOpenOrgModal
  };
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >

      <Grid item xs={9} container justifyContent="center" position={"flex-end"}>
        <Grid item xs={12} container justifyContent="center">
          <h3 style={{ textAlign: "center" }}>Organizer please, create your invite list Here</h3>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="EventOrganizerCode"
            label="Organizer Code"
            variant="outlined"
            fullWidth
            value={organizerCode}
            onChange={handleChange}
            inputProps={{
              onInput: (e) => (e.target.value = e.target.value.toUpperCase()),
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "cursive",
                fontWeight: "bold",
              },
            }} // Enlarge the font size
          />
          <TextField
            id="EventAccessCode"
            label="Event Access Code"
            variant="outlined"
            fullWidth
            value={EventAccessCode}
            onChange={(e) => {
              setEventAccessCode(e.target.value);
            }}
            inputProps={{
              onInput: (e) => (e.target.value = e.target.value.toUpperCase()),
              style: {
                fontSize: "16px",
                fontFamily: "cursive",
                fontWeight: "bold",
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
                fontFamily: "cursive",
                fontWeight: "bold",
              },
            }} // Enlarge the font size
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            id="GoButton"
            type="button"
            onClick={handleClick} // Call handleClick which passes the Codes
            variant="contained"
            color="primary"
          >
            Go
          </Button>
        </Grid>
      </Grid>
    </Grid>
    );
};

export default OrganizerForm;
