import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import resetNotificationCount from "../../util/resetNotificationCount";

const GlnotifyModal = ({ Open, Close, modalTitle, modalContent, userID }) => {
  const navigate = useNavigate();

  if (!Open) return null;

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "purple",
            fontFamily: "cursive",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          },
        },
      },
    },
  });
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white", //'background.paper',
        boxShadow: 24,
        p: 4,
        minWidth: 300,
        height: "500px",
        overflow: "scroll",
      }}
    >
      <Grid item container xs={12}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={"column"}
          alignItems={"flex-end"}
        >
          <CloseIcon onClick={(()=>{
            resetNotificationCount(userID);
            Close();
          }
            )} />
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h6"
              id="modal-modal-title"
              style={{ color: "purple" }}
            >
              {modalTitle}
            </Typography>
          </ThemeProvider>
          <List
          //   id="transpose"
          >
            {modalContent && modalContent.map((notification, index) => (
              <ListItemButton
                key={index}
                disablepadding="true"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "cursive",
                }}
                onClick={() => {
                  console.log(notification);
                  navigate(`/user/${notification.senderId}`);
                }}
              >
                <Avatar
                  src={notification.senderPhotoURL}
                  alt="Notification"
                  // style={{ width: "10%", objectFit: "scale-down" }}
                />
                <ListItemText
                  primary={`${notification.sender} ${
                    notification.type === "like"
                      ? "liked your comment."
                      : notification.type === "comment"
                      ? "commented on your post."
                      : ""
                  }`}
                  sx={{
                    marginLeft: 1,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlnotifyModal;
