import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import EmployeeCard from "../components/EmployeeCard";
import Dubem1 from "../assets/Employees/IMG_4606_pp.jpg";
import George1 from "../assets/Employees/IMG_4563.jpg";
import Ugo1 from "../assets/Employees/Ugo1.jpg";

export const About = () => {
	return (
		<Grid
			container
			direction={"column"}
			xs={12}
			bgcolor={"#670404"}
			display={"flex"}
			// sx={{
			// 	minHeight: "100vh",
			// 	maxWidth: "vw",
			// 	maxHeight: "vh",
			// }}
		>
			<Body />
		</Grid>
	);
};

const Body = () => {
	return (
		<Grid
			item
			xs={12} 
						display={"flex"}
			// minHeight={"95vh"}
			bgcolor={"#DBA622"}
		>
			<Grid
				container
				 item
				xs={4} 
				display={"flex"}
				// bgcolor={'green'}
				// justifyContent={"center"}
				// alignItems={"center"}
			>
				<Grid item xs={12} 
					//  style={{
					// 	display: "flex",
					// 	flexDirection: "column",
					// 	backgroundColor: "white",
					// 	alignItems: "center",
					// 	textAlign: "center",
					// 	borderRadius: "10px",
					// 	width: "90%",
					// 	height: "95%",
					// }} 
				>
					<Typography
						// p={2}
						variant="h5"
					>
						{/* {" "} */}
						Our Company
						{" "}
					</Typography>
					<Typography 
					p={2}
					>
						{/* {" "} */}
						Established in 2016, Galore Land Services Prides itself on rendering
						quality software Services to its clients.Our Goal is to use
						cutting-edge technologies to design products and Services that do
						not discriminate against any group of people and are accessible to
						individuals with disabilities.
						{/* {" "} */}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				
				item
				xs={8}
				// display={"flex"}
				// direction={"column"}
			>
			<Grid container item xs={12}
			
			 >
			<Grid item xs={12} >
				<EmployeeCard
					src={Dubem1}
					name="Dr. Mrs. Dubem Anumba"
					title="Executive Director of Projects"
					info="Highly organized and detail-oriented software engineering professional
                     with over 15 years of progressive experience in the entertainment and payroll industries. 
                     Felicitas is the Chief Executive Officer of Glaore Land Services."
				/>
				</Grid>
				<Grid item xs={12} >
				<EmployeeCard
					src={George1}
					name="George Anumba"
					title="Senior Software Engineer"
					info="Software Engineer Guru with over 4 years 
                    experiencing building interactive and responsive user interfaces. 
                    George is a Senior Programmer at Galore Land Services."
				/>
				</Grid>
				<Grid item xs={12} 
				//sm={12} md={12} lg={12} xl={12}
				>
				<EmployeeCard
					src={Ugo1}
					name="Raphael Anumba"
					title="Software Engineer"
					info="Creative full Stack Web Developer,
					 with a skill of providing innovative ideas that will make a business unique.
					 Enjoys problem solving and improving the growth and use of applications.
					 UCLA Full Stack Certification"
				/>
				</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default About;
