// PopupModal.js
import React from "react";
import { Box, Typography, Button, Grid, ListItemSecondaryAction } from "@mui/material";
import NewCheckout from "../components/NewCheckout"; 
import { createTheme, ThemeProvider } from "@mui/material";
import { purple } from "@mui/material/colors";

const SoulMateModal = ({ onClose, modalTitle, modalContent }) => {

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "purple",
            fontFamily: "cursive",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",

          },
        },
      },
    },
  });  
  return (

     <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: "orange", //'background.paper',
      boxShadow: 24,
      p: 4,
      minWidth: 300,
      // maxWidth: '80vw',
      // maxHeight: '80vh',
      // overflow: 'auto',
    }}> 
        <ThemeProvider theme={theme}>

      <Typography variant="h6" id="modal-modal-title" style={{color: purple}}>
        {modalTitle}
      </Typography>
      </ThemeProvider>

      <Typography variant="body1" id="modal-modal-description" 
        // sx={{ mt: 2 }}
      >
        <NewCheckout />
        {/* {modalContent} */}
      </Typography>

      <Button onClick={onClose} 
      sx={{ mt: 2 }}
      >Close</Button>
      </Box> 
  );
};




export default SoulMateModal;
