import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { ThemeContext } from "../contexts/ThemeContext";

const ScrollGrid = ({ children, title }) => {
	const theme = useContext(ThemeContext);
	const innerPadding = "10px";
	const outerPadding = "20px";
	const innerMargin = "10px";
	const outerMargin = "20px";
	return (
		<Grid
			item
			id="column"
			xs={12}
			sm={12}
			md={4}
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				maxHeight: "85vh",
			}}
			// bgcolor={"red"}
		>
			<Typography
				width={"100%"}
				variant="h5"
				fontWeight={"bold"}
				fontSize={"min(4.5svw, 300px)"}
				style={{
					textAlign: "center",
				}}
			>
				{title}
			</Typography>
			<Grid
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "95%",
					maxWidth: "95%",
					background: theme.main,
					padding: outerPadding,
					borderRadius: innerPadding,
					flex: 1,
					overflowY: "scroll",
				}}
			>
				{children}
			</Grid>
		</Grid>
	);
};

export default ScrollGrid;
