import { Paper, Typography } from "@mui/material";
import React from "react";
import CustomMedia from "./CustomMedia";

const SportsCard = (props) => {
	const { src, onClick, title, description } = props;

	return (
		<Paper
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "center",
				width: "90%",
				padding: "10px",
				height: "100%",
				maxHeight: "100%",
				marginBottom: "min(1vh,1vw)",
			}}
		>
			<CustomMedia
				photo={src}
				onClick={() => {}}
			/>

			{title ? (
				<Typography
					width={"100%"}
					variant="h5"
					fontWeight={"bold"}
				>
					{title}
				</Typography>
			) : null}
			{description ? (
				<Typography width={"100%"}>{description}</Typography>
			) : null}
		</Paper>
	);
};

export default SportsCard;
