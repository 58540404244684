import React, { useEffect } from "react";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Grid, Typography } from "@mui/material";
import CarouselData from "../assets/carouselImages";
import CustomMedia from "./CustomMedia";
import RightBlock from "../assets/RightBlock.png";
import LeftBlock from "../assets/LeftBlock.png";
import MiddleBlock from "../assets/MiddleBlock.png";

const Carousel = (props) => {
  const {
    containerHeight,
    containerWidth,
    objectFit,
    photos,
    carouselHeight,
    carouselWidth,
    autoplay,
    backgroundColor,
    borderRadius,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: backgroundColor ? backgroundColor : "#f5f5f5",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: containerWidth,
        height: containerHeight,
        borderRadius: borderRadius ? borderRadius : "min(2vw, 2vh)",
      }}
    >
      <Swiper
        // className='carousel'
        style={{
          maxWidth: "90vw",
          width: carouselWidth,
          height: carouselHeight,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        //   borderRadius: "min(2vw, 2vh)",
        }}
        autoplay={
          autoplay && {
            delay: 2500,
            disableOnInteraction: false,
          }
        }
        // install Swiper modules
        modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        // navigation={{ clickable: true  }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSwiper={() => console.log(photos)}
        // onSlideChange={handleSlideTimer}
        // onLoadedData={handleSlideTimer}
      >
        {photos && photos.length > 0
          ? photos.map((photo, index) => {

              // console.log("photo: " + JSON.stringify(photo));
              if (photo && photo.type) {
                return (
                  <SwiperSlide
                  key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomMedia photo={photo} onClick={() => {}} />
                  </SwiperSlide>
                );
              }
            })
          : CarouselData.map((item, index) => (
              <SwiperSlide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={item}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    objectFit: objectFit,
                  }}
                />
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
