import React from "react"
const pages1 = [
    {
        id: 1,
        name: "Socials",
        type: "internal",
        link: "/",
    },
    {
        id: 2,
        name: "Sports Highlights",
        type: "internal",
        link: "/sports",

    },
    {
        id: 3,
        name: "Latest Happenings",
        type: "internal",
        link: "/latestlaevents",
    },
    {
        id: 4,
        name: "Login",
        type: "internal",
        link: "/login",
    },
    {
        id: 5,
        name: "You'r Invited",
        type: "internal",
        link: "/invite",
    },
    
];
export default pages1