import React from "react";
import Modal from "react-modal";
import {
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Box,
} from "@mui/material";
//  import Baloons from ".../assets/Invites/Baloons.jpg";

Modal.setAppElement("#root");

const GuestFieldModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  firstName,
  setFirstName,
  fields,
  setFields,
  middleName,
  setMiddleName,
  lastName,
  setLastName,
  email,
  setEmail,
  foodChoice,
  setFoodChoice,
  noOfGuests,
  handleNoOfGuestsChange,
  renderGuestFields,
}) => {
  const handleGuestsChange = (value) => {
    // Limit the number of guests to 1 if the value is greater than 1
    const newNumberOfGuests = value > 1 ? 1 : value;
    handleNoOfGuestsChange(newNumberOfGuests);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Invitation Form Modal"
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          width: "55%",
          height: "80%",
          maxWidth: "100%",
          maxHeight: "100%",
          margin: "auto",
          overflow: "scroll",
          border: "4px solid #000", // Thick border
          borderRadius: "8px", // Rounded corners
          backgroundColor: "peachpuff", // Light yellow background
        },
      }}
    >
      <div>
        <h6
          style={{
            textAlign: "center",
            fontFamily: "cursive",
            color: "purple",
            fontWeight: "bold",
            fontSize: "11px",
          }}
        >
          Please enter your information here. <br />
          Items marked with a <span style={{ color: "red" }}>*</span> are
          required to successfully complete the RSVP. <br />
          We need your information for logistics purposes. <br />
          Thank you!
        </h6>

        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          X
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          label="First Name *"
          variant="outlined"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setFields({ ...fields, subject: `RSVP for ${e.target.value}` });
            setFields({
              ...fields,
              body: `Hello ${e.target.value}, Thanks for sending your RSVP, it is successfully recorded.`,
            });
          }}
          fullWidth
          InputProps={{
            style: {
              marginBottom: "20px",
              border: "2px solid black",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "blue",
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: "bold",
              color: "red",
              fontSize: "18px",
            },
          }}
        />
        <TextField
          label="Middle Name"
          variant="outlined"
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
          fullWidth
          InputProps={{
            style: {
              marginBottom: "20px",
              border: "2px solid black",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "blue",
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: "bold",
              fontSize: "18px",
              color: "purple",
            },
          }}
        />
        <TextField
          label="Last Name *"
          variant="outlined"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          fullWidth
          InputProps={{
            style: {
              marginBottom: "20px",
              border: "2px solid black",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "blue",
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: "bold",
              fontSize: "18px",
              color: "red",
            },
          }}
        />
        <TextField
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setFields({ ...fields, recipient: e.target.value });
          }}
          fullWidth
          InputProps={{
            style: {
              marginBottom: "20px",
              border: "2px solid black",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "blue",
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: "bold",
              color: "purple",
              fontSize: "18px",
            },
          }}
        />
        <div style={{ marginBottom: "20px" }}>
          <span style={{ fontWeight: "bold", color: "red" }}>
            Food Choice *
          </span>
          <div>
            <RadioGroup
              aria-label="foodChoice"
              name="foodChoice"
              value={foodChoice}
              onChange={(e) => setFoodChoice(e.target.value)}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="Beef" control={<Radio />} label="Beef" />
              <FormControlLabel
                value="Chicken"
                control={<Radio />}
                label="Chicken"
              />
              <FormControlLabel value="Fish" control={<Radio />} label="Fish" />
            </RadioGroup>
          </div>
        </div>
        {renderGuestFields()}
        <TextField
          label="Number of Guests"
          variant="outlined"
          type="number"
          value={noOfGuests}
          onChange={(e) => handleGuestsChange(Number(e.target.value))} //handleNoOfGuestsChange(e.target.value)}
          fullWidth
          InputProps={{
            inputProps: { min: 0, max: 1 },
            style: {
              marginBottom: "20px",
              border: "2px solid black",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "blue",
            },
          }}
          InputLabelProps={{
            style: {
              fontWeight: "bold",
              color: "purple",
              fontSize: "18px",
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{
            marginBottom: "20px",
            border: "2px solid black",
            borderRadius: "8px",
            fontSize: "18px",
            fontWeight: "bold",
            color: "purple",
          }}
        >
          Submit RSVP
        </Button>
      </form>
    </Modal>
  );
};

export default GuestFieldModal;
