
 /*********Live environment ***********************/

module.exports = {
  apiKey: "AIzaSyAoBKvpsFWfBgbO0XmyQbzQWYO7ZYJ8ETA",
  authDomain: "galore-land.firebaseapp.com",
  projectId: "galore-land",
  storageBucket: "galore-land.appspot.com",
  messagingSenderId: "863752466191",
  appId: "1:863752466191:web:42118741f621e1a284987a",
  measurementId: "G-9JVRJB46YZ",
};

/*  ****Dev environment***
module.exports = {
  apiKey: "AIzaSyBr0_570zi2SBLB0G1SlWdUT_yT6W9YBfY",
  authDomain: "galorelanddev.firebaseapp.com",
  projectId: "galorelanddev",
  storageBucket: "galorelanddev.appspot.com",
  messagingSenderId: "547288839741",
  appId: "1:547288839741:web:1eabda655844229a871c54",
  measurementId: "G-5K5NW6R2D5"
};

 **/

 

