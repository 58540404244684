import React, { useEffect, useState, useContext } from "react";
import "../screens/GLChatingSystem.css";
import { AuthContext } from "../contexts/AuthContext";
import { onSnapshot, doc } from "firebase/firestore";
import admin from "../util/admin";
import { ChatContext } from "../contexts/ChatContext";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useCallback } from 'react';
import { signOut } from "firebase/auth";
import { UserContext } from "../contexts/UserContext";

const Chats1 = () => {
  const auth = useContext(AuthContext);
  const { data, dispatch } = useContext(ChatContext);
  const [chats, setChats] = useState({});
  const navigate = useNavigate();
  const { db } = admin();
  const [user, setUser] = useContext(UserContext)



const getChats = useCallback(() => {
  if(!auth.currentUser){
    signOut(auth)
    .then(() => {
      setUser(null);
      console.log("Sign-out successful.");
      navigate("/");
    })
    .catch((error) => {
      console.log("An error happened at Sidebar Component");
    });  }

  const unsub = onSnapshot(
    doc(db, "userChats", auth.currentUser.uid),
    (doc) => {
      if (doc.exists()) {
        setChats(doc.data());
      }
    }
  );
  return () => {
    unsub();
  };
}, [auth, chats, db, navigate, setUser]);


  useEffect(() => {
    auth.currentUser && getChats();
  }, [auth.currentUser, getChats]);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };
  // console.log("Chats", chats)

  return (
    <AuthContext.Provider value={auth}>
      <ChatContext.Provider value={{ data, dispatch }}>
        <div className="chats">
          {Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            .map((chat) => (
              <div
                className="userChat"
                key={chat[0]}
                onClick={() => handleSelect(chat[1].userInfo)}
              >
                <div
                  className="userChatInfo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <div style={{
                  display: "flex",
                  // alignItems:  "center",
                  flex: "1",
                  }}>
                    <img src={chat[1].userInfo.photoURL} alt="" />
                    {chat[1].userInfo.firstName}
                  </div>
                  {chat[1].lastmessage && (
                    <Typography>{chat[1].lastmessage}</Typography>
                  )}
                </div>
              </div>
            ))}
        </div>
      </ChatContext.Provider>
    </AuthContext.Provider>
  );
};

export default Chats1;
