import { Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState, } from 'react'
import './FriendYipFeed.css'
import Yip from './Yip';
import { useWindowSize } from "@uidotdev/usehooks";
import { YipFeedContext } from '../contexts/YipFeedContext';

const FriendYipFeed = (props) => {
    const { width, height } = useWindowSize();
    const [yips, setYips] = useContext(YipFeedContext)


    return (
        <Grid
            container
            item
            direction={"column"}
            // bgcolor={"green"}
            justifyContent={"center"}
            xs={12}
        >
            <Grid
                
                item
                xs={12} sm={6} md={4} lg={3} xl={2}
                // bgcolor={"#0b0b0b"}
                direction={"row"}
                alignContent={"center"}
                justifyContent={"center"}
                // maxHeight={height * .85}
            >
                <div className="friend-yip-list" >
                    {yips && yips.map((yip, index) => {
                        return (
                            <Yip key={`yip-${index}`} yip={yip} />
                        )
                    })}
                </div>

            </Grid >
        </Grid >
    )
}

export default FriendYipFeed
