import React, { useContext, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AuthContext } from "../contexts/AuthContext";
import { ChatContext } from "../contexts/ChatContext";
import TextField from "@mui/material/TextField";
import { arrayUnion, doc, updateDoc } from "@firebase/firestore";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import admin from "../util/admin";
import { Grid } from "@mui/material";

function ChatInput() {
  const [text, setText] = useState("");
  const [media, setMedia] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Flag to track media upload status
  const { db, storage } = admin();

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const handleSend = async () => {
    // Check if there is ongoing media upload, if yes, return
    if (isUploading) return;

    setIsUploading(true); // Set flag to indicate media upload is in progress

    try {
      if (media) {
        const storageRef = ref(storage, `${currentUser.uid}/chats/${uuid()}`);
        const uploadTask = uploadBytesResumable(storageRef, media);

        uploadTask.on(
          "state_changed",
          () => {}, // Progress tracking function (optional)
          async (error) => {
            setIsUploading(false); // Reset flag in case of error
            console.error("Error uploading media:", error);
            // Handle error appropriately, such as setting an error state
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              const mediaType = media.type.startsWith("image") ? "img" : "video";
              await updateChatMessages(downloadURL, mediaType); // Update chat messages with media URL and type
            } catch (error) {
              setIsUploading(false); // Reset flag in case of error
              console.error("Error getting download URL:", error);
              // Handle error appropriately, such as setting an error state
            }
          }
        );
      } else {
        await updateChatMessages(); // Update chat messages without media
      }
    } catch (error) {
      setIsUploading(false); // Reset flag in case of error
      console.error("Error sending message:", error);
      // Handle error appropriately, such as setting an error state
    }
  };

  const updateChatMessages = async (mediaURL = null, mediaType = null) => {
    const messageRef = doc(db, "chats", data.chatId);

    const message = {
      id: uuid(),
      text,
      senderId: currentUser.uid,
      date: new Date(),
      media: mediaURL,
      mediaType: mediaType,
    };

    await updateDoc(messageRef, {
      messages: arrayUnion(message),
    });

    // Clear text and media state after sending message
    setText("");
    setMedia(null);
    setIsUploading(false); // Reset flag after message is sent
  };

  return (
    <Grid container item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "", alignContent: "center", justifyContent: "center" }}>
      <Grid item xs={10}>
        <TextField
          id="Chat-Input"
          label="Type your message..."
          multiline
          maxRows={4}
          onChange={(e) => setText(e.target.value)}
          value={text}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} sx={{ justifyContent: "flex-start", display: "flex", flexDirection: "row" }}>
        <SendIcon style={{ alignSelf: "center" }} onClick={handleSend} />
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "", alignContent: "center", justifyContent: "center" }} >
        <input
          id="chatfile"
          type="file"
          accept="image/*,video/*"
          onChange={(e) => setMedia(e.target.files[0])}
        />
        <label htmlFor="chatfile" style={{ fontWeight: "bold" }}>
          <AttachFileIcon />
        </label>
      </Grid>
    </Grid>
  );
}

export default ChatInput;


// import React, { useContext, useState } from "react";
// import SendIcon from "@mui/icons-material/Send";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import { AuthContext } from "../contexts/AuthContext";
// import { ChatContext } from "../contexts/ChatContext";
// import TextField from "@mui/material/TextField";
// import { arrayUnion, doc, getDoc, serverTimestamp, updateDoc } from "@firebase/firestore";
// import { v4 as uuid } from "uuid";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import admin from "../util/admin";
// import { Grid } from "@mui/material";

// function ChatInput() {
//   const [text, setText] = useState("");
//   const [img, setImg] = useState(null);
//   const [isUploading, setIsUploading] = useState(false); // Flag to track image upload status
//   const { db, storage } = admin();

//   const { currentUser } = useContext(AuthContext);
//   const { data } = useContext(ChatContext);

//   const handleSend = async () => {
//     // Check if there is ongoing image upload, if yes, return
//     if (isUploading) return;

//     setIsUploading(true); // Set flag to indicate image upload is in progress

//     try {
//       if (img) {
//         const storageRef = ref(storage, `${currentUser.uid}/chats/${uuid()}`);
//         const uploadTask = uploadBytesResumable(storageRef, img);

//         uploadTask.on(
//           "state_changed",
//           () => {}, // Progress tracking function (optional)
//           async (error) => {
//             setIsUploading(false); // Reset flag in case of error
//             console.error("Error uploading image:", error);
//             // Handle error appropriately, such as setting an error state
//           },
//           async () => {
//             try {
//               const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//               await updateChatMessages(downloadURL); // Update chat messages with image URL
//             } catch (error) {
//               setIsUploading(false); // Reset flag in case of error
//               console.error("Error getting download URL:", error);
//               // Handle error appropriately, such as setting an error state
//             }
//           }
//         );
//       } else {
//         await updateChatMessages(); // Update chat messages without image
//       }
//     } catch (error) {
//       setIsUploading(false); // Reset flag in case of error
//       console.error("Error sending message:", error);
//       // Handle error appropriately, such as setting an error state
//     }
//   };

//   const updateChatMessages = async (imgURL = null) => {
//     const messageRef = doc(db, "chats", data.chatId);

//     const message = {
//       id: uuid(),
//       text,
//       senderId: currentUser.uid,
//       date: new Date(),
//       img: imgURL,
//     };

//     await updateDoc(messageRef, {
//       messages: arrayUnion(message),
//     });

//     // Clear text and image state after sending message
//     setText("");
//     setImg(null);
//     setIsUploading(false); // Reset flag after message is sent
//   };

//   return (
//     <Grid container item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "", alignContent: "center", justifyContent: "center" }}>
//       <Grid item xs={10}>
//         <TextField
//           id="Chat-Input"
//           label="Type your message..."
//           multiline
//           maxRows={4}
//           onChange={(e) => setText(e.target.value)}
//           value={text}
//           fullWidth
//         />
//       </Grid>
//       <Grid item xs={2} sx={{ justifyContent: "flex-start", display: "flex", flexDirection: "row" }}>
//         <SendIcon style={{ alignSelf: "center" }} onClick={handleSend} />
//       </Grid>

//       <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "", alignContent: "center", justifyContent: "center" }} >
//         <input
//           id="chatfile"
//           type="file"
//           onChange={(e) => setImg(e.target.files[0])}
//         />
//         <label htmlFor="chatfile" style={{ fontWeight: "bold" }}>
//           <AttachFileIcon />
//         </label>
//       </Grid>
//     </Grid>
//   );
// }

// export default ChatInput;
