import React from "react";
import Modal from "react-modal";
import {
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";
//  import Baloons from ".../assets/Invites/Baloons.jpg";

const ServiceLetterAgree = ({ Open, Close }) => {
  if (!Open) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white", //'background.paper',
        boxShadow: 24,
        p: 4,
        minWidth: 300,
        height: "500px",
        overflow: "scroll",
      }}
    >
      <div>
        <h6
          style={{
            textAlign: "center",
            fontFamily: "cursive",
            color: "purple",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Service Letter and Privacy Agreement
          <br /> <br />
        </h6>
        <p style={{ textAlign: "center", fontSize: "12px" }}>
          Last Updated: <span>April 4, 2024</span>
        </p>
        <button
          onClick={Close}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          X
        </button>
        <h6
          style={{
            fontFamily: "cursive",
             color: "purple",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Service Letter Agreement
        </h6>
          <Typography fontStyle={"italic"}>
            The purpose of this service letter agreement is to outline the scope
            of the usage services that Galore Land (The parent Company) will
            provide to its clients.
          </Typography>
          <Typography fontWeight={"bold"}>Services to Be Rendered</Typography>
          LetsConnet.com agrees to allow its clients to socialise. Information
          on all clients will be taken and made available to other clients. Upon
          checking the agreed checkbox, every registered client has agreed to be
          contacted by other clients by the provided means of contact. The
          clients must provide two true current photographs. Any
          misrepresentation of self is liable for prosecution by law.
          <Typography fontWeight={"bold"}>Timeline</Typography>
          Every registered client is valid for two years. After two years, the
          clients must update their pictures. The website will be available 24
          hours a week for clients' access.
          <Typography fontWeight={"bold"}>Terms of Payment</Typography>
          All payments are to be made through the website. The website will not
          store any payment information. The website will not be responsible for
          any payment information stored by the client.
          <Typography fontWeight={"bold"}>Refund Policy</Typography>
          All payments are non-refundable. The website will not refund any
          payments made by the client.
          <Typography fontWeight={"bold"}>Cancellation Policy</Typography>
          The client can cancel their subscription at any time. The website will
          not refund any payments made by the client.
          <Typography fontWeight={"bold"}>Contact Information</Typography>
          The client can contact the website at any time. The website will
          respond to the client within 24 hours.
          <Typography fontWeight={"bold"}>Disclaimer</Typography>
          Galore Land Services and LetsConnet.com are not liable for any issues
          arising from relationships formed on this site.

          <h6
          style={{
            fontFamily: "cursive",
             color: "purple",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Privacy Agreement
        </h6>
        <p style={{ textAlign: "center", fontSize: "12px" }}>
          At{" "}
          <span>
            <mark>Galore Land Services and LetsConnet.com,</mark>
          </span>
          we value your privacy and are committed to protecting your personal
          information. This Privacy Policy is designed to help you understand
          how we collect, use, disclose, and safeguard your personal information
          when you visit our website or use our products and services.
        </p>
        <p style={{ textAlign: "center", fontSize: "12px" }}>
          Please read this Privacy Policy carefully to understand your rights
          and responsibilities regarding your personal information. By using the
          Website or our Services, you consent to the Privacy Policy of the
          Website.
        </p>
        <ol>
          <li>
            <Typography fontWeight={"bold"}>Introduction</Typography>
            <Typography>
              We are committed to respecting your online privacy. We further
              recognize your need for appropriate protection and management of
              any personally identifiable information ("Personal Information")
              you share with us. Information that is considered personal about
              you by us includes, but is not limited to, your name, address,
              email address, and phone number.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>Information We Collect</Typography>
            <Typography>
              In general, you can visit our website without telling us who you
              are or revealing any information about yourself. In some areas of
              the site, we ask you to provide information that will enable us to
              offer services that require registration, assist you with
              technical support issues, or to follow up with you. Generally,
              Galore Land Services and LetsConnet.com request identifying
              information when you:
              <ul>
                <li>Register on any of our websites.</li>
                <li>Place an order.</li>
                <li>
                  Provide feedback to an online survey or tell us about an idea
                  or suggestion.
                </li>
                <li>
                  Participate in a rebate program, contest, or other promotional
                  offer.
                </li>
                <li>Request a special service.</li>
                <li>
                  Request information or files from technical support or
                  customer service.
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>How We Use Information</Typography>
            <Typography>
              Galore Land uses the information you provide to help us improve
              our services to you, to provide you with the products you have
              requested, to inform you about additional products or services
              that may be of interest to you, and to provide support when
              needed.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>Access to Information</Typography>
            <Typography>
              We do not sell or rent your personal information to others. We do
              not share your personal information with others, except as
              explicitly stated in this Privacy Policy.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>Security of Information</Typography>
            <Typography>
              We are committed to keeping your personal information secure. We
              have implemented technical, administrative, and physical security
              measures that are designed to protect your information from
              unauthorized access, disclosure, use, and modification.
            </Typography>
          </li>
        </ol>
        {/* </Typography> */}

      </div>
    </Box>
  );
};

export default ServiceLetterAgree;
