import admin from "./admin";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const resetNotificationCount = async (userID) => {
  const { db } = admin();

  // Access the specific notification document using its ID
  const notificationDocRef = doc(db, "notifications", userID);
  const notificationDocSnap = await getDoc(notificationDocRef);
  if (!notificationDocSnap.exists()) return;

  // Access the "notifications" field from the notifications document
  const notificationData = notificationDocSnap.data();
  console.log("notificationData at resetNotificationCount =", notificationData, " user.id =", userID);

  // Reset the count of notifications and update read to false
  const notifies = notificationData.notifies || [];
  const resetCount = notifies.reduce((count, notifies) => {
    if (notifies.senderId && !notifies.read) {
      // Update read to false
      notifies.read = true;
    }
    return count;
  }, 0);

  // Update the notifications document with modified notifies
  await updateDoc(notificationDocRef, { notifies });

  console.log("resetCount=", resetCount);
  return resetCount;
};

export default resetNotificationCount;
