import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "../screens/GLChatingSystem.css";
import Chioma1 from "../assets/Profileimages/Chioma1.jpg";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Avatar } from "@mui/material";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Paths } from "./Router";

const GlChatInfoErrorModal = (props) => {
  const { onClose, open } = props;
  const navigate = useNavigate();

  // const handleCloseAlert = () => {
  //   onClose();
  //   // navigate("/LetsConnet");
  // };

  return (
    <Dialog open={open}>
      <div onClick={onClose}>X</div>
      <DialogTitle>
        Please update your information (Profile picture, etc to use this
        Feature)
      </DialogTitle>
    </Dialog>
  );
};

const ChatsNavbar = () => {
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    // navigate("/LetsConnet");
    navigate(Paths.LETSCONNET);
    // setOpen(false);
  };

  useEffect(() => {
    // setCurrentUser()
  }, []);

  const hasPhotoURL = auth && auth.currentUser && auth.currentUser.photoURL;
  const hasdisplayName = user && user.displayName;
  return (
    <Grid container item xs={12} className="ChatsNavbar">
      {hasPhotoURL && (
        <Grid
          item
          container
          xs={12}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item xs={3} sx={{ textWrap: "wrap", fontWeight: "bold" }}>
            Galore Chat
          </Grid>
          <Grid
            item
            container
            xs={9}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
          <Grid item container  xs={9} sm={9}  md={9} maxWidth={"90%"}>
              <img
                src={auth.currentUser.photoURL}
                alt=""
                style={{
                  backgroundColor: "#ddddf7",
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            {hasdisplayName && <span style={{textWrap:"wrap"}}>{user.displayName}</span>}
          </Grid>
          </Grid>
        </Grid>
      )}
      <GlChatInfoErrorModal open={!hasPhotoURL} onClose={handleClose} />
    </Grid>
  );
};

export default ChatsNavbar;
