import React, { useContext } from "react";
import ChatsNavbar from "./ChatsNavbar";
import Search from "./Search";
import Chats1 from "./Chats1";
import { AuthContext } from "../contexts/AuthContext";
import { UserContext } from "../contexts/UserContext";
import {ChatContext} from "../contexts/ChatContext"
import { useNavigate } from "react-router";
import { signOut } from "firebase/auth";

const Sidbar = () => {
  const auth = useContext(AuthContext);
  const [user, setUser] = useContext(UserContext)
  const {data, dispatch} =useContext(ChatContext)
  const navigate = useNavigate();

  if(!auth.currentUser){
    signOut(auth)
    .then(() => {
      setUser(null);
      console.log("Sign-out successful.");
      navigate("/");
    })
    .catch((error) => {
      console.log("An error happened at Sidebar Component");
    });  }


  return (
    <div className="sidebar">
      <AuthContext.Provider value={auth}>
      <UserContext.Provider value={[user, setUser]}>
        <ChatsNavbar />
        <Search />
        <ChatContext.Provider value={{data, dispatch}}>
        {auth.currentUser ? (
            <Chats1 />
          ) : (
            // Redirect to login page if user is not authenticated
            navigate("/login")
          )}
        </ChatContext.Provider>
      </UserContext.Provider>
      </AuthContext.Provider>
    </div>
  );
};

export default Sidbar;
