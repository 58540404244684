import { useContext, useEffect, useState } from "react";
import admin from "./admin";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";

const useUnreadChatCount = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [user, setUser] = useContext(UserContext)
  const {db} = admin();

  useEffect(() => {
    const fetchUserChats = async () => {

      // Ensure db object is defined
      if (!db) return;

      const docRef = doc(db, "userChats", user.uid);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) return;
      
      const userChatsData = docSnap.data();
      
      // Check if userChatsData is defined and not empty
      if (!userChatsData || Object.keys(userChatsData).length === 0) {
        return; // No user chats found, return early
      }
      
      const chatKeys = Object.keys(userChatsData);
      
      // Ensure chatKeys array is not empty
      if (chatKeys.length === 0) {
        return; // No chat keys found, return early
      }
      
      const chatKey0 = chatKeys[0];
      
      // Access the specific chat document using its ID
      const chatDocRef = doc(db, "chats", chatKey0);
      const chatDocSnap = await getDoc(chatDocRef);
      
      if (!chatDocSnap.exists()) return;
      

      // // Access the specific chat document using its ID
      // const chatDocRef = doc(db, "chats", chatKey0);
      // const chatDocSnap = await getDoc(chatDocRef);
      // if (!chatDocSnap.exists()) return;

      // Access the "messages" field from the chat document
      const messages = chatDocSnap.data().messages;
      
      // Calculate the count of unread messages
      const unreadCount = messages.filter(message => message.senderId !== user.uid && !message.messageRead).length;
      setUnreadCount(unreadCount);
    };

    fetchUserChats();
  }, [user.uid, db]);

  return unreadCount;
};

export default useUnreadChatCount;
