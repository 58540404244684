import {
  Button,
  Grid,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PostYipContext } from "../contexts/PostYipContext";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { setDoc, doc, getDoc, collection } from "firebase/firestore";
import dayjs from "dayjs";
import { customAlphabet } from "nanoid";
import { YipFeedContext } from "../contexts/YipFeedContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./PostYipModal.css";
import { StorageContext } from "../contexts/StorageContext";
import { AuthContext } from "../contexts/AuthContext";
import {
  ref,
  uploadBytes,
  list,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { updateProfile } from "firebase/auth";
import YipImages from "./YipImages";
import PostYipImages from "./PostYipImages";
import { height } from "@mui/system";

const PostYipModal = (props) => {
  const { postYipModal, setPostYipModal } = useContext(PostYipContext);
  const [user, setUser] = useContext(UserContext);
  const db = useContext(FirebaseContext);
  const storage = useContext(StorageContext);
  const auth = useContext(AuthContext);
  const [yips, setYips] = useContext(YipFeedContext);
  const [textCount, setTextCount] = useState(200);
  const [yipText, setYipText] = useState("");
  const [inputPhoto, setInputPhoto] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [imageContainerCounter, setImageContainerCounter] = useState(0);
  const navigate = useNavigate();
  const nanoId = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz@.!", 20);

  useEffect(() => {
    setYipText("");
  }, [postYipModal]);

  const handleSubmit = async () => {
    if (user == null) {
      navigate("/login");
      return;
    }

    const postYipText = yipText.trim();
    if (postYipText === "") {
      return;
    }

    const photoArr = photos?.map((photo) => {
      return photo.name;
    });

    const id = nanoId();
    const yip = {
      id: id,
      text: postYipText,
      owner: user.uid,
      likes: [],
      comments: [],
      timestamp: dayjs().format("MM-DD-YYYY HH:mm:ss"),
      photos: photoArr,
    };

    await setDoc(doc(db, "yips", id), yip)
      .then(async () => {
        setYips((prevYips) => [...prevYips, yip]);
        await getDoc(doc(db, "users", user.uid)).then((data) => {
          const userYips = data.data().yips;

          setDoc(
            doc(db, "users", user.uid),
            {
              yips: userYips ? [...userYips, id] : [id],
            },
            { merge: true }
          );
          photos?.forEach(async (inputPhoto) => {
            if (inputPhoto) {
              const storageRef = ref(
                storage,
                `${user.uid}/yips/${id}/${inputPhoto.name}`
              );
              const uploadTask = uploadBytesResumable(storageRef, inputPhoto);

              // Listen for state changes, errors, and completion of the upload.
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      console.log("Upload is paused");
                      break;
                    case "running":
                      console.log("Upload is running");
                      break;
                    default:
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      // User canceled the upload
                      break;

                    // ...

                    case "storage/unknown":
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      console.log("File available at", downloadURL);
                    }
                  );
                }
              );
            }
          });
          setYipText("");
          setPostYipModal(false);
        });
      })
      .catch((error) => {
        // setYipText("");
        // setPostYipModal(false);
        console.error("Error adding document: ", error);
      });
  };

  return (
    <Modal
      open={postYipModal}
      onClose={() => {
        setPostYipModal(false);
        setYipText("");
        setTextCount(200);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
		maxHeight: "80%", // Set maximum height here
    	overflowY: "auto", // Enable vertical scrolling if content exceeds maxHeight
      }}
    >
      <div className="post-yip-modal"  style={{ textAlign: "center" }}>
        <div className="post-yip-modal-container">
          <div className="close-button">
            <CloseRoundedIcon
              onClick={() => {
                setPostYipModal(false);
                setYipText("");
                setTextCount(200);
              }}
            />
          </div>
          <Typography variant={"h4"}>Post a Yip</Typography>
          <PostYipImages
            photos={photos}
            imageContainerCounter={imageContainerCounter}
          />
          <div
            className="post-yip-images-container"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              padding: "min(1vw, 1vh)",
              margin: "min(10px, 10px)",
            }}
          >
            <label htmlFor="postYipPhotoInput">
              <i></i>
              <input
                type="file"
                id="postYipPhotoInput"
                hidden
                multiple
                accept="image/png, image/jpeg, image/jpg, video/*"
                onChange={(e) => {
                  setPhotos([]);
                  let counter = 0;
                  setImageContainerCounter(0);

                  Object.entries(e.target.files).forEach((file, index) => {
                    if (index < 3) {
                      counter++;
                    }
                    console.log(counter);
                    if (file[1].size > 5000000000) {
                      alert("File size is too big!");
                      return;
                    }

                    if (file[1]) {
                      console.log(file[1]);
                      setPhotos((prevPhotos) => [...prevPhotos, file[1]]);
                      return;
                    }
                  });
                  setImageContainerCounter(counter);
                }}
                style={{
                  height: "10px", // Adjust the height as needed
                  width: "30px",
				  alignContent: "center"
                }}
              />
            </label>
            <Button
              style={{ color: "purple", height: "20px", textTransform: "none" }}
              onClick={() => {
                document.getElementById("postYipPhotoInput").click();
              }}
            >
              Choose Files
            </Button>
          </div>
          <TextField
            type="text"
            id="post-yip-text"
            multiline
            fullWidth
            placeholder="Say your mind!" //"Yip to your fellow friends."
            value={yipText}
            onChange={(e) => {
              if (e.target.value.length > 200) {
                return;
              }
              setTextCount(200 - e.target.value.length);
              setYipText(e.target.value);
            }}
            sx={{
              // textAlign: "flex-start",
              justifyContent: "center",
              // alignItems: "flex-start",
              "& .MuiInputBase-root": {
                display: "flex",
                flexDirection: "column",
                height: "100%",
                textAlign: "flex-start",
              },
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: "5px",
              // flex: 1,
              // height: "40%",
              width: "80%",
            }}
          />
        </div>

        <div className="post-yip-modal-footer">
          <Typography
            style={{
              fontSize: "min(1.5vw, 1.5vh)",
            }}
          >
            Maximum Characters: {textCount}{" "}
          </Typography>
          <Button
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "min(1.5vw, 1.5vh)",
              textTransform: "none",
              margin: "min(5px, 5px)",
            }}
            variant="contained"
            onClick={
              handleSubmit
              // () => {
              //   console.log(photos);
              // }
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PostYipModal;
