import {
  Button,
  Grid,
  Input,
  List,
  ListItem,
  Modal,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";

import OnlineFriends from "../components/OnlineFriends";
import "./FriendProfile.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import CommentsModal from "../components/CommentsModal";
import { CommentsModalContext } from "../contexts/CommentsModalContext";

import { YipFeedContext } from "../contexts/YipFeedContext";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import Yip from "../components/Yip";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  where,
  query,
} from "@firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";

import FriendProfileCard from "../components/FriendProfileCard";
import FriendYipFeed from "../components/FriendYipFeed";

const FriendProfile = () => {
  // const auth = useContext(AuthContext);
  const [user, setUser] = useContext(UserContext);
  const [yips, setYips] = useState([]);
  const navigation = useNavigate();
  const userId = useParams().id;
  const db = useContext(FirebaseContext);
  const gettingYips = React.useRef(false);

  useEffect(() => {
    const getYips = async () => {
      if (gettingYips.current) {
        return;
      }
      // const docSnap = await getDoc(docRef)
      gettingYips.current = true;
      const queryDoc = await getDoc(doc(db, "users", userId));
      if (queryDoc.exists()) {
        let userYips = [];
        const q = query(collection(db, "yips"), where("owner", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          userYips.push(doc.data());
        });

        setYips(userYips);
      }
      gettingYips.current = false;
    };

    getYips();
  }, [user, userId]);

  if (!user) {
    return <div>loading...</div>;
  }

  return (
    <Grid
      container
      direction={"column"}
      bgcolor={"#DBA622"}
      sx={{ display: "flex", minHeight: "vh", maxWidth: "vw", maxHeight: "vh" }}
    >
      <YipFeedContext.Provider value={[yips, setYips]}>
        <Body />
      </YipFeedContext.Provider>
    </Grid>
  );
};

const Body = () => {
  const [showComments, setShowComments] = useState(false);
  const [commentModalData, setCommentModalData] = useState();

  return (
    <CommentsModalContext.Provider
      value={{
        showComments,
        setShowComments,
        commentModalData,
        setCommentModalData,
      }}
    >
      <Grid
        container
        item
        xs={12}
        gap={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "space-around",
        }}
      >
        <Grid
          item
          style={{ 
            display: "flex", 
          justifyContent: "flex-end" 
          }}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <FriendProfileCard />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{
             display: "flex",
              justifyContent: "flex-start",
            //  alignSelf: "center",
            // alignItems: "center",
            // border: "1px solid black",
          }}
        >
          <FriendYipFeed />
          <CommentsModal />
        </Grid>
      </Grid>
    </CommentsModalContext.Provider>
  );
};

export default FriendProfile;
