import React, { useContext, useEffect, useState } from "react";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { Grid, Typography } from "@mui/material";
import "./FriendProfileCard.css";
import { AuthContext } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { AddRounded } from "@mui/icons-material";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import { FirebaseContext } from "../contexts/FirebaseContext";
import dayjs from "dayjs";
import { useWindowSize } from "@uidotdev/usehooks";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const FriendProfileCard = (props) => {
	const [user, setUser] = useContext(UserContext);
	const db = useContext(FirebaseContext);
	const navigate = useNavigate();
	const { height } = useWindowSize();
	const id = useParams().id;
	const [friendData, setFriendData] = useState();
	const [initializing, setInitializing] = useState(true);
	useEffect(() => {
		// console.log("User Profile Id" + id);
		const getProfilePic = async () => {
			const docRef = doc(db, "users", id);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				console.log("Document data:", docSnap.data());
				setFriendData(docSnap.data());
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
			setInitializing(false);
		};

		getProfilePic();

		return () => {
			setInitializing(true);
		};
	}, [user, id]);

	if (initializing) <div>loading...</div>;

	return (
		<Grid
			item
			xs={12} container
			// bgcolor={"skyblue"}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				color: "#670404",
				height: 1,
			}}
		>
			<Grid container item xs={12} justifyContent={"center"} 
				// className="profile-container"
				// maxHeight={height * 0.85}
				backgroundColor={"#f5f5f5"}
			>
				<Grid
					container
					item
					direction={"column"}
					// bgcolor={"purple"}
					display={"flex"}
					xs={12}
					height={1}
					maxWidth={"275px"}
				>
					<Grid
						container
						item
						xs={1}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						// bgcolor={"red"}
						width={1}
					>
						<AddRounded
							style={{
								height: "min(6vw, 6vh)",
								width: "min(6vw, 6vh)",
								padding: "min(.8vw, .8vh)",
								cursor: "pointer",
								// backgroundColor: "green",
							}}
						/>
						<NotificationsRoundedIcon
							style={{
								height: "min(6vw, 6vh)",
								width: "min(6vw, 6vh)",
								padding: "min(1vw, 1vh)",
								// backgroundColor: "green",
							}}
						/>
					</Grid>
					<Grid
						container
						item
						xs={4}
						direction={"row"}
						// bgcolor={"blue"}
						display={"flex"}
						justifyContent={"center"}
						alignContent={"center"}
						width={1}
					>
						{friendData && friendData.photoURL ? (
							<img
								src={friendData.photoURL}
								alt="profile-pic"
								style={{
									height: "min(20vw, 20vh)",
									width: "min(20vw, 20vh)",
									borderRadius: "50%",
									// backgroundColor: "green",
									objectFit: "cover",
									cursor: "pointer",
								}}
							/>
						) : (
							<AccountCircleRoundedIcon
								style={{
									height: "min(20vw, 20vh)",
									width: "min(20vw, 20vh)",
									borderRadius: "50%",
									// backgroundColor: "green",
									objectFit: "cover",
								}}
							/>
						)}
					</Grid>
					<Grid
						container
						item
						xs={0.5}
						direction={"column"}
						display={"flex"}
						// bgcolor={"lightgreen"}
						justifyContent={"center"}
						textAlign={"center"}
						width={1}
					>
						<Typography variant={"h5"}>
							{friendData && friendData.firstName}
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs={1.75}
						direction={"row"}
						// bgcolor={"#66cccc"}
						display={"flex"}
						justifyContent={"center"}
						alignContent={"center"}
						textAlign={"center"}
						width={1}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<EventAvailableRoundedIcon
								style={{
									display: "flex",
									alignSelf: "center",
									padding: "min(.8vw, .8vh)",
									height: "min(4vw, 4vh)",
									width: "min(4vw, 4vh)",
									// backgroundColor: "green",
								}}
							/>
							<Typography
								style={{
									fontSize: "min(5vw, 5vh)",
								}}
							>
								{friendData &&
									dayjs(friendData.joinDate).format("MM/DD/YYYY")}
							</Typography>
						</div>
					</Grid>
					<Grid
						container
						item
						xs={2}
						direction={"column"}
						// bgcolor={"magenta"}
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						width={1}
					>
						<div className="profile-details-container">
							<Typography
								fontWeight={"bold"}
								sx={{ fontSize: "min(6vw,6vh)" }}
							>
								Details
							</Typography>
							<div
								style={{
									display: "flex",
									// width: "100%",

									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
									wordBreak: "break-word",
									// backgroundColor: "red",
								}}
							>
								<Typography
									fontWeight={"bold"}
									sx={{ fontSize: "min(4vw,4vh)" }}

								>
									Biography:{" "}
								</Typography>
								<Typography style={{ wordBreak: "break-word" }}>
									{friendData?.notes}
								</Typography>
							</div>

							<Typography
								sx={{
									fontSize: "min(1.0vw, 1.0vh)",
									width: "100%",
									// backgroundColor: "green",
									// overflowY: "scroll",
									maxHeight: "min(10vw, 10vh)",
								}}
							></Typography>
							<div
								className="details"
								style={{
									maxWidth: "100%",
								}}
							>
								{friendData?.dateOfBirth ? (
									<div 
									// className="detail-text"
									>
										<Typography id="bold" style={{ fontSize: "14px" }}> Age: </Typography>
										<Typography id="detail" style={{ fontSize: "12px" }}>
											{dayjs().diff(
												dayjs(friendData?.dateOfBirth.toDate()),
												"year"
											)}
										</Typography>
									</div>
								) : ( 
									<></>
								)}
								<div className="detail-text">
									<Typography id="bold" style={{ fontSize: "14px" }}>Gender:</Typography>{" "}
									<Typography id="detail" style={{ fontSize: "12px" }}> {friendData?.gender} </Typography>
								</div>
								<div className="detail-text">
									<Typography id="bold" style={{ fontSize: "14px" }}>Country of Origin: </Typography>
									<Typography id="detail" style={{ fontSize: "12px" }}>{friendData?.origin}</Typography>
								</div>
								<div className="detail-text">
									<Typography id="bold"style={{ fontSize: "14px" }}>State of Origin: </Typography>
									<Typography id="detail" style={{ fontSize: "12px" }}> {friendData?.region}</Typography>
								</div>
								<div className="detail-text">
									<Typography id="bold" style={{ fontSize: "14px" }}>Town of Origin: </Typography>
									<Typography id="detail" style={{ fontSize: "12px" }}>{friendData?.town} </Typography>
								</div>
								<div className="detail-text">
									<Typography id="bold"style={{ fontSize: "14px" }}>Race:</Typography>{" "}
									<Typography id="detail" style={{ fontSize: "12px" }}>{friendData?.race}</Typography>
								</div>
								<div className="detail-text">
									<Typography id="bold" style={{ fontSize: "14px" }}>Occupation:</Typography>{" "}
									<Typography id="detail" style={{ fontSize: "12px" }}>
										{" "}
										{friendData?.occupation}{" "}
									</Typography>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FriendProfileCard;
