import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { StorageContext } from "../contexts/StorageContext";
import { UserContext } from "../contexts/UserContext";
import Carousel from "./Carousel";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import YipImages from "./YipImages";
// import video from "../assets/Sports/Giannis Antetokounmpo.mp4";

const YipContent = (props) => {
	const { yip } = props;
	const theme = useContext(ThemeContext);
	const storage = useContext(StorageContext);
	const [user] = useContext(UserContext);
	// const [photos, setPhotos] = useState([]);
	const [initializing, setInitializing] = useState(false);
	const gettingPhotos = React.useRef(false);

	useEffect(() => {
		// setInitializing(false);
	}, [user]);

	if (initializing) {
		return <></>;
	}
	return (
		<Grid
			className="yip-content"
			container
			// item
			display={"flex"}
			direction={"column"}
			px={"min(2vw, 2vh)"}
			py={"min(1vw, 1vh)"}
			// maxHeight={"40vh"}
			maxWidth={"100%"}
			// xs={"auto"}
		>
			{yip && yip.photos ? (
				<Grid
					item
					container
					className="yip-image-container"
					// xs={8}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					bgcolor={theme.bgColor}
					maxWidth={"100%"}
					borderRadius={"min(2vw, 2vh)"}
					p={"min(1vw, 1vh)"}
				>
					<YipImages yip={yip} />
				</Grid>
			) : (
				<></>
			)}
			<Grid
				item
				container
				className="yip-text"
				display={"flex"}
				direction={"column"}
				pt={"min(1vw, 1vh)"}
			>
				<Typography
					style={{
						color: "#670404",
						fontSize: "min(2.7svh,2.7svw)",
					}}
				>
					{yip && yip.text}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default YipContent;
