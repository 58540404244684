import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../contexts/FirebaseContext";
import Modal from "react-modal";
import {
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Box,
} from "@mui/material";
import Baloons from "../assets/Invites/Baloons.jpg";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import LCBgplain from "../assets/Background/LCBgplain.jpg";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import InvitationListForm from "./InvitationListForm";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import GLTableAssignment from "./GLTableAssignment"; // Import the new component
import AccessCodeForm from "./AccessCodeForm";
import OrganizerForm from "./OrganizerForm";
import GuestFieldModal from "./glModals/GuestFieldModal"; // Import the GuestFieldModal component
import AlertModal from "./glModals/AlertModal";
import { Html } from "@mui/icons-material";

Modal.setAppElement("#root");

const Invite = ({ code }) => {
  const [accessCode, setAccessCode] = useState("");
  const [organizerCode, setOrganizerCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [noOfGuests, setNoOfGuests] = useState(0);
  const [guests, setGuests] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orgModalIsOpen, setOrgModalIsOpen] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const db = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [EventAccessCode, setEventAccessCode] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [titleOrgEvent, setTitleOrgEvent] = useState("Invitation List Form"); // New state variable [title
  const storage = getStorage();
  const [fields, setFields] = useState({
    recipient: "",
    subject: "",
    body: "",
  });
  const [foodChoice, setFoodChoice] = useState(""); // State to manage selected food choice

  const handleFoodChoiceChange = (index, value) => {
    const newGuests = [...guests];
    newGuests[index].foodChoice = value;
    setGuests(newGuests);
  };

  const handleCloseOrgModal = () => {
    setOrgModalIsOpen(false);
  };

  const handleProceedClick = (accessCode) => {
    // Handle the access code received from AccessCodeForm
    setAccessCode(accessCode); // Set the access code state
    // Validate Access Code
    if (accessCode.trim() === "BAD ACCESS CODE") {
      const message = "Your Access Code does not exist.";
      const title = "Invalid Access Code  Error!";
      const severity = "error";

      // Show alert in separate modal
      setAlertMessage(message);
      setAlertTitle(title);
      setAlertSeverity(severity);
      setAlertModalIsOpen(true);
      setAccessCode(""); // Clear the access code state
      setModalIsOpen(false); // Close the main modal if validation passes

      return; // Exit function
    }

    setModalIsOpen(true); // Open the main modal if validation passes
  };

  const handleOpenOrgModal = (
    EventAccessCode,
    organizerCode,
    titleOrgEvent
  ) => {
    // Validate organization Code
    setEventAccessCode(EventAccessCode);
    setOrganizerCode(organizerCode);
    setModalTitle(titleOrgEvent);
    if (
      EventAccessCode.trim() === "BAD CODE" || organizerCode === "BAD CODE"
    ) {
      const message = "Your Organization or EventAccess Code does not exist.";
      const title = "Organization or EventAccess Code  Error!";
      const severity = "error";

      // Show alert in separate modal
      setAlertMessage(message);
      setAlertTitle(title);
      setAlertSeverity(severity);
      setAlertModalIsOpen(true);

      return; // Exit function
    }

    setOrgModalIsOpen(true);
  };

  const sendConfirmationEmail = async () => {
    try{
      const emailQuery = query(
        collection(db, "email"),
        where("to", "==", email),
        where("message.subject", "==", fields.subject),
        limit(1)
      );
      const emailSnapshot = await getDocs(emailQuery);
      if (emailSnapshot.empty) {
        await addDoc(collection(db, "email"), {
          from: "Galore Land Services",
          to: email,
          message: {
            subject: fields.subject,
            html: '<p> Thanks for sending your RSVP, it is successfully recorded. </p>',
          },
        });
      }
    console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };


  const sendEmail = async () => {
    // Call the sendAcknowledgementEmail function
    try {
      // Replace 'recipient', 'subject', and 'body' with actual data
      const response = await fetch(
        "https://us-central1-gl-ss-functions.cloudfunctions.net/api/sendAcknowledgementEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fields }),
        }
      );
      //const data = await response.json(); // Parse JSON response
      if (response.status === 200) {
        alert(
          "Your RSVP was successfully sent. Please check your email for confirmation."
        );
      }
    } catch (error) {
      console.error("Error sending acknowledgement email:", error);
    }
  };
  const handleInvitListSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {
      console.error("Error adding/updating access code record: ", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // // Check if the access code exists in the "accessCodes" collection
      // const accessCodeQuery = query(
      //   collection(db, "accessCodes"),
      //   where("accessCode", "==", accessCode),
      //   limit(1)
      // );
      // const accessCodeSnapshot = await getDocs(accessCodeQuery);

      // // If the access code does not exist, create it
      // if (accessCodeSnapshot.empty) {
      //   await addDoc(collection(db, "accessCodes"), {
      //     accessCode: accessCode,
      //     eventTitle: "",
      //   });
      // }

      // // Check if the user's firstName and lastName exists in the "invitationList" collection.
      // const invitationListQuery = query(
      //   collection(db, "invitationList"),
      //   where("firstName", "==", firstName),
      //   where("lastName", "==", lastName),
      //   limit(1)
      // );
      // const invitationListSnapshot = await getDocs(invitationListQuery);

      // if (invitationListSnapshot.empty) {
      //   const message =
      //     "So sorry, your name is not on the invited guests list. Pleaase,contact the event organizer to add you to the list.";
      //   const title = "Not in the list Error!";
      //   const severity = "error";

      //   // Show alert in separate modal
      //   setAlertMessage(message);
      //   setAlertTitle(title);
      //   setAlertSeverity(severity);
      //   setAlertModalIsOpen(true);
      //   setModalIsOpen(true);

      //   return; // Exit function
      // }

      // Check if the RSVP record exists in the "rsvps" collection
      const rsvpQuery = query(
        collection(db, "rsvps"),
        where("accessCode", "==", accessCode),
        where("invitedRecord.email", "==", email),
        limit(1)
      );
      const rsvpSnapshot = await getDocs(rsvpQuery);

      if (rsvpSnapshot.empty) {
        // Create a new RSVP record if it does not exist
        await addDoc(collection(db, "rsvps"), {
          accessCode: accessCode,
          invitedRecord: {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            email: email,
            foodChoice: foodChoice,
            guests: guests,
          },
        });
      } else {
        const message =
          "You have already RSVP'd for this event. Please exit and contact the event organizer for any changes.";
        const title = "Invalid Access Code  Error!";
        const severity = "error";

        // Show alert in separate modal
        setAlertMessage(message);
        setAlertTitle(title);
        setAlertSeverity(severity);
        setAlertModalIsOpen(true);
        setModalIsOpen(true);

        return; // Exit function
      }

      console.log("Access code record added/updated successfully!");
      // Send email to the provided email address
      // setFields({ ...fields, recipient: email, subject: "Your RSVP",
      // body: "Thanks for sending your RSVP, it is successfully recorded."})
      await sendConfirmationEmail();
      //await sendEmail();
      // Call GLTableAssignment function to generate table assignments

      GLTableAssignment(db, accessCode, EventAccessCode);

      // Reset form fields
      setModalIsOpen(false);
      setAccessCode("");
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setEmail("");
      setFoodChoice("");
      setNoOfGuests(0);
      setGuests([]);
      navigate("/");
    } catch (error) {
      console.error("Error adding/updating access code record: ", error);
    }
  };

  const handleGuestNameChange = (index, value) => {
    // Create a copy of the current guests array
    const newGuests = [...guests];
    // Update the name of the guest at the specified index
    newGuests[index] = {
      ...newGuests[index],
      name: value,
    };
    // Update the guests state with the modified array
    setGuests(newGuests);
  };

  // const renderGuestFields = () => {
  //   return guests.map((guest, index) => (
  //     <div key={index} style={{ marginBottom: "20px" }}>
  //       <TextField
  //         label={`Guest ${index + 1} Name`}
  //         variant="outlined"
  //         value={guest.name}
  //         onChange={(e) => handleGuestNameChange(index, e.target.value)}
  //         fullWidth
  //         style={{
  //           marginBottom: "10px",
  //           position: "sticky",
  //           top: "0",
  //           color: "blue",
  //           fontWeight: "bold",
  //         }}
  //       />
  //       {/* Radio buttons for food choice */}
  //       <RadioGroup
  //         aria-label="foodChoice"
  //         name="foodChoice"
  //         value={foodChoice}
  //         onChange={(e) => handleFoodChoiceChange(index, e.target.value)}
  //         sx={{ display: "flex", flexDirection: "row" }}
  //       >
  //         <FormControlLabel value="Beef" control={<Radio />} label="Beef" />
  //         <FormControlLabel
  //           value="Chicken"
  //           control={<Radio />}
  //           label="Chicken"
  //         />
  //         <FormControlLabel value="Fish" control={<Radio />} label="Fish" />
  //       </RadioGroup>
  //     </div>
  //   ));
  // };
  const renderGuestFields = () => {
    return guests.map((guest, index) => (
      <div key={index} style={{ marginBottom: "20px" }}>
        <TextField
          label={`Guest ${index + 1} Name`}
          variant="outlined"
          value={guest.name}
          onChange={(e) => handleGuestNameChange(index, e.target.value)}
          fullWidth
          style={{
            marginBottom: "10px",
            position: "sticky",
            top: "0",
            color: "blue",
            fontWeight: "bold",
          }}
        />
        {/* Radio buttons for food choice */}
        <RadioGroup
          aria-label={`foodChoice-${index}`} // Unique aria-label for each guest
          name={`foodChoice-${index}`} // Unique name for each guest
          value={guest.foodChoice} // Use guest's food choice state
          onChange={(e) => handleFoodChoiceChange(index, e.target.value)} // Pass index to the handler
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <FormControlLabel value="Beef" control={<Radio />} label="Beef" />
          <FormControlLabel value="Chicken" control={<Radio />} label="Chicken" />
          <FormControlLabel value="Fish" control={<Radio />} label="Fish" />
        </RadioGroup>
      </div>
    ));
  };
  
  const handleNoOfGuestsChange = (value) => {
    // Handle backspace and delete keys
    if (value === "" || value === "0") {
      setNoOfGuests(0);
      setGuests([]);
      return;
    }
    if (value > "1") {
      setNoOfGuests(1);
    }

    const newValue = parseInt(value);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 3) {
      setNoOfGuests(newValue);
      setGuests(Array(newValue).fill({ name: "", foodChoice: "" }));
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setAccessCode("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setFoodChoice("");
    setNoOfGuests(0);
    setGuests([]);
    navigate("/");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="70vh"
      width={"100%"}
      style={{
        backgroundImage: `url(${Baloons})`,
        objectFit: "cover",
        backgroundSize: "cover",
        backgroundPosition: "center", // Center the background image
      }}
    >
      <Grid item container xs={12} justifyContent="center" sx={{ sm: 12 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger screens
            alignItems: "center", // Center the items vertically
            width: "100%", // Full width for the Box container
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "48%" }, // Full width on small screens, 48% width on larger screens
              marginRight: { xs: 0, sm: "2%" }, // No margin on small screens, 2% margin on larger screens
              marginLeft: { xs: 0, sm: "1.3%" }, // No margin on small screens, 1.3% margin on larger screens
              bgcolor: "orange",
              borderRadius: "50%",
              marginBottom: { xs: "20px", sm: 0 }, // Space between forms on small screens
            }}
          >
            <AccessCodeForm
              handleProceedClick={handleProceedClick}
              fields={fields}
              setFields={setFields}
            />
          </Box>
          <Box
            sx={{
              width: "2%",
              bgcolor: "white",
              display: { xs: "none", sm: "block" }, // Hide on small screens, show on larger screens
            }}
          />
          <Box
            sx={{
              width: { xs: "100%", sm: "48%" }, // Full width on small screens, 48% width on larger screens
              marginRight: { xs: 0, sm: "1.3%" }, // No margin on small screens, 1.3% margin on larger screens
              marginLeft: { xs: 0, sm: "2%" }, // No margin on small screens, 2% margin on larger screens
              bgcolor: "violet",
              borderRadius: "70%",
              marginTop: { xs: "20px", sm: 0 }, // Space between forms on small screens
            }}
          >
            <OrganizerForm
              handleOpenOrgModal={handleOpenOrgModal}
              titleOrgEvent={titleOrgEvent}
              setTitleOrgEvent={setTitleOrgEvent}
            />
          </Box>
        </Box>
      </Grid>

      <GuestFieldModal
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        handleSubmit={handleSubmit}
        firstName={firstName}
        setFirstName={setFirstName}
        fields={fields}
        setFields={setFields}
        middleName={middleName}
        setMiddleName={setMiddleName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        foodChoice={foodChoice}
        setFoodChoice={setFoodChoice}
        noOfGuests={noOfGuests}
        handleNoOfGuestsChange={handleNoOfGuestsChange}
        renderGuestFields={() => renderGuestFields()} // Pass renderGuestFields as a function
      />
      <AlertModal
        isOpen={alertModalIsOpen}
        closeModal={() => setAlertModalIsOpen(false)}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
        alertSeverity={alertSeverity}
      />

               <InvitationListForm
                Open={orgModalIsOpen}
                Close={handleCloseOrgModal}
                modalTitle={titleOrgEvent}
              />   
    </Grid>
  );
};

export default Invite;

