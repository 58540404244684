import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import "./Contact.css";

export const Contact = () => {
	
	return (
		<Grid
			container
			direction={"column"}
			xs={12}
			sx={{
				display: "flex",
				bgcolor: "#670404",
				minHeight: "vh",
				maxWidth: "vw",
				maxHeight: "vh",
			}}
		>
			<Body />
		</Grid>
	);
};

const Body = () => {
	const [fields, setFields] = useState({
		email: "",
		firstName: "",
		middleName: "",
		lastName: "",
		message: "",
	});
	const resetScreen = () => {
		setFields({
			email: "",
			firstName: "",
			middleName: "",
			lastName: "",
			message: "",
		});
	};
	const handleContribute = async () => {
		try{
			console.log(fields);
			const response = await fetch("https://us-central1-gl-ss-functions.cloudfunctions.net/api/userSendsInquiry", {
				// mode: 'no-cors',	
			method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(fields),
			});
			// const data = await response.json();
			// console.log(data);
			// if(data.status === 200)
			if(response.status === 200)
			{
				 alert("Thank you for your contribution! We will get back to you soon");
				resetScreen();
			}

		}
		catch(err){
			console.log(err);
			alert("Please try again later");
		}	
		
	}
	return (
		<Grid item className="contact-Grid-item" xs={12}>
			<Grid container item className="contact-container-item" xs={12} >
				<div className="contact-div">
					<Typography variant="h4">Connect With US</Typography>
					<Typography>
						It will be great to hear from you! Please give us your contact
						information in the form below and we will get back to you in
						earnest. Thank you for contributing! (*Please note that the fields
						with stars are required)
					</Typography>
					<div className="contact-info-div">
						<Grid container xs={12} md={6} lg={4}>
							<Grid item xs={12} md={6} lg={4}>Phone: +1(818)-523-6270</Grid>
						</Grid>
						<Grid item  xs={12} md={6} lg={4}>
							Email:
							<a  href="galorelandservicesllc@gmail.com">
								galorelandservicesllc@gmail.com
							</a>
						</Grid>
					</div>
					<div className="contact-user-info-div">
						<TextField
							value={fields.email}
							required
							type="email"
							id="email"
							label="Email"
							onChange={(e) =>
								setFields({ ...fields, email: e.target.value })
							}
						/>
						<TextField
							value={fields.firstName}
							required
							type="First Name"
							id="First Name"
							label="First Name"
							onChange={(e) =>
								setFields({ ...fields, firstName: e.target.value })
							}
						/>
						<TextField
							value={fields.middleName}
							type="Middle Name"
							id="Middle Name"
							label="Middle Name"
							onChange={(e) =>
								setFields({ ...fields, middleName: e.target.value })
							}
						/>
						<TextField
							value={fields.lastName}
							required
							type="Last Name"
							id="Last Name"
							label="Last Name"
							onChange={(e) =>
								setFields({ ...fields, lastName: e.target.value })	
							}
						/>
						<TextField
							value={fields.message}
							required
							type="Questions and Comments"
							id="Questions and Comments"
							label="Questions and Comments"
							onChange={(e) =>
								setFields({ ...fields, message: e.target.value })
							}
						/>

						<div
							style={{
								display: "flex",
								justifyContent: "space-evenly",
								// backgroundColor: 'pink',
							}}
						>
							<Button className="contact-div-btn" variant="contained" onClick={handleContribute}>Send</Button>
							<Button className="contact-div-btn" variant="contained" onClick={resetScreen}>Reset</Button>
						</div>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default Contact;
