import image0 from "../assets/Carouselimages/Chioma1.jpg";
import image1 from "../assets/Carouselimages/JenAnumbaSprts.png";
import image2 from "../assets/Carouselimages/JenHairdoFashion1.png";
import image3 from "../assets/Carouselimages/partty.jpg";
import image4 from "../assets/Carouselimages/NnamdiAsomugha1.jpg";
import image5 from "../assets/Carouselimages/Nova.jpg";
import image6 from "../assets/Carouselimages/FaithAnumba3.jpg";

let CarouselData = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
];

export default CarouselData;
