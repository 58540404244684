// import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router";
// import {
//   Button,
//   Grid,
//   Modal,
//   ThemeProvider,
//   Typography,
//   createTheme,
// } from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
// import "./MessageNotification.css";
// import { UserContext } from "../contexts/UserContext";
// import { AuthContext } from "../contexts/AuthContext";
// import useUnreadChatCount from "../util/useUnreadChatCount"; // Import the custom hook
// import useUnreadNotificationCount from "../util/useUnreadNotificationCount"; // Import the custom hook
// import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
// import { collection, query, where, getDocs, onSnapshot, doc } from "firebase/firestore";
// import admin from "../util/admin";
// import SearchModal from "./glModals/SearchModal";
// import GlNotifyModal from "../components/glModals/GlnotifyModal"; // Import the notifyModal component
// import { Paths } from "./Router";
// import { Link } from "react-router-dom";
// import resetNotificationCount from "../util/resetNotificationCount";

// const MessageNotification = () => {
//   const [searchTerm, setSearchTerm] = useState(""); // State for searchTerm
//   const navigate = useNavigate();
//   const [user, setUser] = useContext(UserContext);
//   const auth = useContext(AuthContext);
//   const unreadChatsCount = useUnreadChatCount();
//   const unreadNotificationsCount = useUnreadNotificationCount(); // Initialize unread notifications count

//   const [members, setMembers] = useState([]);
//   const [notifications, setNotifications] = useState([]);
//   const { db } = admin();
//   const [openSearchModal, setOpenSearchModal] = useState(false); // State for the search modal
//   const [openNotifyModal, setOpenNotifyModal] = useState(false); // State for the notifies modal
//   const [modalClosed, setModalClosed] = useState(true); // State to track if the modal is closed

//   const theme = createTheme({
//     components: {
//       MuiTypography: {
//         styleOverrides: {
//           root: {
//             color: "purple",
//             fontFamily: "cursive",
//             textTransform: "none",
//             fontSize: "12px",
//             fontWeight: "bold",
//             textAlign: "center",
//           },
//         },
//       },
//     },
//   });

//   const handleClose = () => {
//     setOpenSearchModal(false);
//     setOpenNotifyModal(false);
//     setSearchTerm("");
//     setModalClosed(true); // Update modal state to closed
//   };

//   const handleCloseNotModal = () => {
//     setOpenNotifyModal(false);
//     setSearchTerm("");
//     setModalClosed(true); // Update modal state to closed
//   };
//   const openSearchModel = () => {
//     if (searchTerm) {
//       setOpenSearchModal(true);
//     }
//   };

//   const openNotificationListModal = () => {
//     const unsub = onSnapshot(
//       doc(db, "notifications", user.uid),
//       (doc) => {
//         if (doc.exists()) {
//           setNotifications(doc.data().notifies);
//         }
//       }
//     );
//     setOpenNotifyModal(true);
//     setModalClosed(false); // Update modal state to open

//     return () => {
//       unsub();
//     };
//   };

//   const handleNotificationsClick = () => {
//     if (modalClosed) {
//       resetNotificationCount(user.uid); // Call resetNotificationCount only when modal is closed
//       // No need to reset unreadNotificationsCount here since it's controlled by the useUnreadNotificationCount hook
//     }
//   };

//   useEffect(() => {
//     const fetchMembers = async () => {
//        try {
//         if (searchTerm && searchTerm.trim() !== "") {
//           const q = query(
//             collection(db, "users"),
//              where("firstName", "array-contains", searchTerm.trim()),

//             );
//           const querySnapshot = await getDocs(q);
//           const data = [];
//           querySnapshot.forEach((doc) => {
//             data.push({ id: doc.id, ...doc.data() });
//           });
//           setMembers(data);
//         } else {
//           setMembers([]);
//         }
//       } catch (error) {
//         console.error("Error fetching members:", error);
//       }
//     };
//     searchTerm && fetchMembers();
//   }, [db, searchTerm]);

//   return (
//     <>
//       <Grid item xs={12}>
//         <Modal
//           open={openSearchModal}
//           onClose={handleClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <SearchModal
//             Open={openSearchModal}
//             Close={handleClose}
//             modalTitle={`Search Resuls for: ${searchTerm}`}
//             modalContent={members}
//           />
//         </Modal>
//       </Grid>
//       <Grid item xs={12}>
//         <Modal
//           open={openNotifyModal}
//           onClose={handleCloseNotModal}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <GlNotifyModal
//             Open={openNotifyModal}
//             Close={handleCloseNotModal}
//             modalTitle={"Your Notifications"}
//             modalContent={notifications}
//             userID={user.uid}
//           />
//         </Modal>
//       </Grid>
//       <Grid
//         container
//         item
//         xs={12}
//         direction="row"
//         justifyContent="flex-end"
//         spacing={1}
//         marginTop={1}
//       >
//         <Grid container item xs={12}>
//           <Grid item xs={12} sm={6} md={4} lg={3}>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 widows: "100%",
//               }}
//             >
//               <TextField
//                 fullWidth
//                 label="Search"
//                 size="small"
//                 sx={{
//                   width: "auto",
//                   height: "auto",
//                   "& input": { height: "100%" },
//                   fontSize: "6px",
//                 }}
//                 style={{ fontSize: "8px" }}
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//               <Button onClick={openSearchModel}>
//                 <SearchIcon
//                   style={{
//                     color: "black",
//                     cursor: "pointer",
//                     display: "flex",
//                     alignSelf: "flex-start",
//                   }}
//                   fontSize="medium"
//                 />
//               </Button>
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4} lg={3}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "relative",
//               }}
//             >
//               <Button onClick={openNotificationListModal}>
//                 <ThemeProvider theme={theme}>
//                   <Typography 
//                   // onClick={handleNotificationsClick}
                  
//                   >
//                     Notifications
//                     <NotificationsIcon style={{ color: "black" }} />
//                   </Typography>
//                   {unreadNotificationsCount > 0 && (
//                     <span className="count-indicator">
//                       {unreadNotificationsCount}
//                     </span>
//                   )}
//                 </ThemeProvider>
//               </Button>
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4} lg={3}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 position: "relative",
//               }}
//             >
//               <Button onClick={() => navigate("/glchatingsystem")}>
//                 <AuthContext.Provider value={auth}>
//                   <UserContext.Provider value={[user, setUser]}>
//                     <ThemeProvider theme={theme}>
//                       <Typography>
//                         Chats{" "}
//                         <MarkUnreadChatAltIcon
//                           style={{
//                             color: "black",
//                             // position: 'relative', zIndex: 1
//                           }}
//                         />
                     
//                      </Typography>
//                     </ThemeProvider>
//                   </UserContext.Provider>
//                 </AuthContext.Provider>
//                 {unreadChatsCount > 0 && (
//                   <span className="count-indicator">{unreadChatsCount}</span>
//                 )}
//               </Button>
//             </div>
//           </Grid>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default MessageNotification;



import React, { useContext, useEffect, useState,forwardRef } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Grid,
  Modal,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import "./MessageNotification.css";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import useUnreadChatCount from "../util/useUnreadChatCount"; // Import the custom hook
import useUnreadNotificationCount from "../util/useUnreadNotificationCount"; // Import the custom hook
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { collection, query, where, getDocs, onSnapshot, doc } from "firebase/firestore";
import admin from "../util/admin";
import SearchModal from "./glModals/SearchModal";
import GlNotifyModal from "../components/glModals/GlnotifyModal"; // Import the notifyModal component
import { Paths } from "./Router";
import { Link } from "react-router-dom";
import resetNotificationCount from "../util/resetNotificationCount";

const MessageNotification = forwardRef((props, ref) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for searchTerm
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);
  const unreadChatsCount = useUnreadChatCount();
  const unreadNotificationsCount = useUnreadNotificationCount(); // Initialize unread notifications count

  const [members, setMembers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const { db } = admin();
  const [openSearchModal, setOpenSearchModal] = useState(false); // State for the search modal
  const [openNotifyModal, setOpenNotifyModal] = useState(false); // State for the notifies modal
  const [modalClosed, setModalClosed] = useState(true); // State to track if the modal is closed

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "purple",
            fontFamily: "cursive",
            textTransform: "none",
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "center",
          },
        },
      },
    },
  });

  const handleClose = () => {
    setOpenSearchModal(false);
    setOpenNotifyModal(false);
    setSearchTerm("");
    setModalClosed(true); // Update modal state to closed
  };

  const handleCloseNotModal = () => {
    setOpenNotifyModal(false);
    setSearchTerm("");
    setModalClosed(true); // Update modal state to closed
  };

  const openSearchModel = () => {
    if (searchTerm) {
      setOpenSearchModal(true);
    }
  };

  const openNotificationListModal = () => {
    const unsub = onSnapshot(
      doc(db, "notifications", user.uid),
      (doc) => {
        if (doc.exists()) {
          setNotifications(doc.data().notifies);
        }
      }
    );
    setOpenNotifyModal(true);
    setModalClosed(false); // Update modal state to open

    return () => {
      unsub();
    };
  };

  const handleNotificationsClick = () => {
    if (modalClosed) {
      resetNotificationCount(user.uid); // Call resetNotificationCount only when modal is closed
      // No need to reset unreadNotificationsCount here since it's controlled by the useUnreadNotificationCount hook
    }
  };

  useEffect(() => {
    const fetchMembers = async () => {
       try {
        if (searchTerm && searchTerm.trim() !== "") {
          const q = query(
            collection(db, "users"),
            where("firstName", ">=", searchTerm.trim()),
            where("firstName", "<=", searchTerm.trim() + "\uf8ff")            //  where("fullName", "array-contains", searchTerm.trim()),

            );
          const querySnapshot = await getDocs(q);
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });
          setMembers(data);
        } else {
          setMembers([]);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };
    searchTerm && fetchMembers();
  }, [db, searchTerm]);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      openSearchModel();
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Modal
          open={openSearchModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SearchModal
            Open={openSearchModal}
            Close={handleClose}
            modalTitle={`Search Resuls for: ${searchTerm}`}
            modalContent={members}
          />
        </Modal>
      </Grid>
      <Grid item xs={12}>
        <Modal
          open={openNotifyModal}
          onClose={handleCloseNotModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <GlNotifyModal
            Open={openNotifyModal}
            Close={handleCloseNotModal}
            modalTitle={"Your Notifications"}
            modalContent={notifications}
            userID={user.uid}
          />
        </Modal>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        marginTop={1}
        ref={ref}

      >
        <Grid container item xs={12}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                widows: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Search"
                size="small"
                sx={{
                  width: "auto",
                  height: "auto",
                  "& input": { height: "100%" },
                  fontSize: "6px",
                }}
                style={{ fontSize: "8px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleEnterKeyPress}
              />
              <Button onClick={openSearchModel}>
                <SearchIcon
                  style={{
                    color: "black",
                    cursor: "pointer",
                    display: "flex",
                    alignSelf: "flex-start",
                  }}
                  fontSize="medium"
                />
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Button onClick={openNotificationListModal}>
                <ThemeProvider theme={theme}>
                  <Typography>
                    Notifications
                    <NotificationsIcon style={{ color: "black" }} />
                  </Typography>
                  {unreadNotificationsCount > 0 && (
                    <span className="count-indicator">
                    {unreadNotificationsCount}
                  </span>
                )}
              </ThemeProvider>
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Button onClick={() => navigate("/glchatingsystem")}>
              <AuthContext.Provider value={auth}>
                <UserContext.Provider value={[user, setUser]}>
                  <ThemeProvider theme={theme}>
                    <Typography>
                      Chats{" "}
                      <MarkUnreadChatAltIcon
                        style={{
                          color: "black",
                          // position: 'relative', zIndex: 1
                        }}
                      />
                     
                     </Typography>
                  </ThemeProvider>
                </UserContext.Provider>
              </AuthContext.Provider>
              {unreadChatsCount > 0 && (
                <span className="count-indicator">{unreadChatsCount}</span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </>
);
});

export default MessageNotification;

