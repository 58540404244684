// import React, { useState } from "react";
import {
  Event as EventIcon,
  Sports as SportsIcon,
} from "@mui/icons-material";
import { Diversity3 as Diversity3Icon } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageNotification from "../components/MessageNotification";
import LetsconnetPink from "../assets/Background/LetsconnetPink.jpg";
import { createTheme, ThemeProvider } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

/******************************************************* */
import {
  Grid,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import "./LetsConnect.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { YipFeedContext } from "../contexts/YipFeedContext";

import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import { collection, getDocs } from "@firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { EditProfileContext } from "../contexts/EditProfileContext";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import { StorageContext } from "../contexts/StorageContext";

import LetsConnetBody from "../components/LetsConnetBody";

import { AuthContext } from "../contexts/AuthContext";
import { StaticDatePicker } from "@mui/x-date-pickers";
/******************************************************* */

import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import "../components/ProfileCard.css";
import { signOut } from "firebase/auth";
import { AddRounded } from "@mui/icons-material";
import { PhotosModalContext } from "../contexts/PhotosModalContext";
import dayjs from "dayjs";
import { useWindowSize } from "@uidotdev/usehooks";
import { PostYipContext } from "../contexts/PostYipContext";
import GlDrawer1 from "../components/GlDrawer1";
import ProfileHead from "../components/ProfileHead";
import GLChatingSystem from "./GLChatingSystem";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "10px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const ItemIconList = ({ index }) => {
  const icons = [FavoriteIcon, EventIcon, Diversity3Icon, SportsIcon];
  const Icon = icons[index % icons.length];
  return <Icon />;
};

const LetsConnet = () => {
  const [user, setUser] = useContext(UserContext);
  const [yips, setYips] = useState([]);

  const auth = useContext(AuthContext);

  const storage = useContext(StorageContext);
  const navigation = useNavigate();
  const db = useContext(FirebaseContext);

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [profilPicture, setProfilePicture] = useState("");
  const [glsearch, setGlsearch] = useState("");
  const [unreadChatsCount, setUnreadChatsCount] = useState(5);
  const [notificationsCount, setNotificationsCount] = useState(5);
  const [searchString, setSearchString] = useState("");
  const [userName, setUserName] = useState("");
  const [datejoined, setDateJoined] = useState("");
  const [photosModalOpen, setPhotosModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const setHeaderInfo = async () => {
      if (user) {
        setProfilePicture(user.photoURL);
        setUserName(user.displayName);
        setDateJoined(dayjs(user.metadata.creationTime).format("MMMM D, YYYY"));
      }
    };
    const getYips = async () => {
      let tempYips = [];
      const querySnapshot = await getDocs(collection(db, "yips"));
      querySnapshot.forEach(async (yip) => {
        let tempMedia = [];
        let yipData = yip.data();
        if (yipData.photos) {
          const pathRef = ref(storage, `${yipData.owner}/yips/${yipData.id}`);
          const storageSnap = await list(pathRef, { maxResults: 100 });
          storageSnap.items.forEach(async (itemRef) => {
            let media = {};
            const url = await getDownloadURL(itemRef);
            const res = await getMetadata(itemRef);
            media.url = url;
            media.type = res.contentType;
            tempMedia.push(media);
          });
          yipData.photos = tempMedia;
          tempYips.push(yipData);
        }
        setTimeout(() => {
          setYips(tempYips);
        }, 1000);
      });
    };

    getYips();

    setHeaderInfo();
    // console.log("refreshing");
  }, [user, storage, db]);

  if (!user) {
     return <div>
     Please login...
     <LoginIcon style={{color: "green"}} onClick={() => {
         signOut(auth);
         navigation("/login");
         }  }/>

     </div>;
  }

  return (
    <Grid
      container
      item
      // spacing={2}
      xs={12}
      sx={{
        backgroundImage: `url(${LetsconnetPink})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Left Column */}
      <Grid item container xs={4} style={{ maxHeight: "600px" }}>
        <Grid item xs={12}>
          <ProfileHead />
        </Grid>
        <Grid item xs={12}>
          <GlDrawer1 />
        </Grid>
      </Grid>

      {/* Right Column */}
      <Grid
        item
        container
        xs={8}
        display={"block"}
        width={"100%"}
        spacing={2}
        maxHeight={"600px"}
        overflow={"scroll"}
      >
        <Grid item container xs={12} direction={"column"}>
          <YipFeedContext.Provider value={[yips, setYips]}>
            <AuthContext.Provider value={auth}> 
            <UserContext.Provider value={[user, setUser]}>
            <MessageNotification />
            <LetsConnetBody />
            </UserContext.Provider>
            </AuthContext.Provider>
          </YipFeedContext.Provider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LetsConnet;
