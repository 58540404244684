import React from "react";
import "./EmployeeCard.css";
import { Grid, Typography } from "@mui/material";

const EmployeeCard = ({ src, name, title, info }) => {
  return (
    <Grid
      container
      item
      xs={12}
      // spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
      }}
    >
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Grid container item xs={12}>
          <img
            src={src}
            style={{
              height: "200px",
              backgroundColor: "white",
            }}
          />
          <Typography
            style={{
              overflowY: "scroll",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {name}
            <br/><br/>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container display="flex" xs={12} sm={6} md={4} lg={3} xl={2}>
        <Grid
          item
          xs={12}
          style={{
            overflowY: "scroll",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {title}
        </Grid>
        <Grid
          item
          xs={12}
          p={2}
          style={{
            overflowY: "scroll",
            fontSize: "16px",
          }}
        >
          {info}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmployeeCard;
