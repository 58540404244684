// AccessCodeForm.js
import React, { useContext, useState } from "react";
import { TextField, Button, Grid } from "@mui/material";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import fallTree from "../assets/Background/fallTrees.png";
import { FirebaseContext } from "../contexts/FirebaseContext";

const AccessCodeForm = ({ handleProceedClick, fields, setFields }) => {
  const [accessCode, setAccessCode] = useState("");
  const db = useContext(FirebaseContext);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    setAccessCode(value);
    setFields({
      ...fields,
      subject: `Status of your RSVP for event code ${value}`,
    });
  };

  const handleClick = async () => {
    try {
      // Check if the access code exists in the "accessCodes" collection
      const accessCodeQuery = query(
        collection(db, "accessCodes"),
        where("accessCode", "==", accessCode),
        limit(1)
      );
      const accessCodeSnapshot = await getDocs(accessCodeQuery);
      if (accessCodeSnapshot.empty) {
        // setAccessCode("BAD ACCESS CODE");
        handleProceedClick("BAD ACCESS CODE");
        return;
      }
    } catch (error) {
      console.error("Error checking access code:", error);
      return;
    }

    handleProceedClick(accessCode); // Pass accessCode to handleProceedClick
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      display= "flex"
        flexDirection= "row"      
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
       spacing={2}
      justifyContent="center"
       
      >
        {/* <img src={fallTree} alt="fallTree" /> */}
      </Grid>
      <Grid item xs={9} container justifyContent="center"position={"flex-end"}>
        <Grid item xs={12} container justifyContent="center">
          <h2>Guest RSVP Form</h2>
          {/* <p>Enter your details below to RSVP</p> */}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Access Code"
            variant="outlined"
            fullWidth
            value={accessCode}
            onChange={handleChange}
            inputProps={{
              onInput: (e) => (e.target.value = e.target.value.toUpperCase()),
              style: {
                fontSize: "16px",
                fontFamily: "cursive",
                fontWeight: "bold",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "14px",
                fontFamily: "cursive",
                // fontWeight: "bold",
              },
            }} // Enlarge the font size
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            id="ProceedButton"
            onClick={handleClick} // Call handleClick which passes accessCode
            variant="contained"
            color="primary"
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccessCodeForm;
