import React, { useEffect, useState } from 'react';
import { Typography, Grid } from "@mui/material";
import { doc, setDoc, getDoc, increment } from "firebase/firestore";
import admin from '../util/admin';

const VisitorCounter = () => {
  const [visitorCount, setVisitorCount] = useState(0);
  const [incremented, setIncremented] = useState(false);

  useEffect(() => {
    // Check if we have already incremented the count
    if (!incremented) {
      // Initialize Firebase Firestore only in the client-side environment
      const { db } = admin();

      // Get the visitor count from Firestore
      const docRef = doc(db, "visitors", "counter");
      getDoc(docRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            // Update visitorCount state if document exists
            setVisitorCount(docSnap.data().count);
          } else {
            // Create the visitors collection and set initial count to 1
            setDoc(docRef, { count: 1 });
            
            // Set visitorCount state to 1
            setVisitorCount(1);
          }

          // Increment visitor count in Firestore if not already incremented
          if (!incremented) {
            setDoc(docRef, { count: increment(1) }, { merge: true })
              .then(() => {
                // Update local state to reflect the increment
                setVisitorCount(prevCount => prevCount + 1);
                // Set incremented flag to true to prevent multiple increments
                setIncremented(true);
              })
              .catch(error => {
                console.error("Error incrementing visitor count:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error getting document:", error);
        });
    }
  }, [incremented]);

  return (
    <Grid container item style={{ backgroundColor: "white", padding: "16px" }}>
      <Typography style={{ color: "black" }}>Total Views: {visitorCount}</Typography>
    </Grid>
  );
};

export default VisitorCounter;


// import React, { useEffect, useState } from 'react';
// import { Typography, Grid } from "@mui/material";
// import {  collection, doc, setDoc, getDoc, increment } from "firebase/firestore";
// import admin from '../util/admin';

// const VisitorCounter = () => {
//   const [visitorCount, setVisitorCount] = useState(0);

//   useEffect(() => {
//     // Initialize Firebase Firestore
//     const {db} = admin();

//     // Get the visitor count from Firestore
//     const docRef = doc(db, "visitors", "counter");
//     getDoc(docRef)
//       .then((docSnap) => {
//         if (docSnap.exists()) {
//           // Update visitorCount state if document exists
//           setVisitorCount(docSnap.data().count);

//           // Increment visitor count in Firestore
//           setDoc(docRef, { count: increment(1) }, { merge: true });
//         } else {
//           // Create the visitors collection and set initial count to 1
//           setDoc(docRef, { count: 1 });
          
//           // Set visitorCount state to 1
//           setVisitorCount(1);
//         }
//       })
//       .catch((error) => {
//         console.error("Error getting document:", error);
//       });
//   }, []);

//   return (
//     <Grid container item style={{ backgroundColor: "white", padding: "16px" }}>
//       <Typography style={{ color: "black" }}>Total Visitors: {visitorCount}</Typography>
//     </Grid>
//   );
// };

// export default VisitorCounter;

// // import React, { useEffect, useState } from 'react';
// // import { Typography, Button, Grid } from "@mui/material";

// // const VisitorCounter = () => {
// //   const [visitorCount, setVisitorCount] = useState(0);

// //   useEffect(() => {
// //     // Retrieve visitor count from localStorage
// //     const count = localStorage.getItem('visitorCount');
// //     if (count) {
// //       setVisitorCount(parseInt(count));
// //     } else {
// //       // If no visitor count found, initialize it to 0
// //       localStorage.setItem('visitorCount', 0);
// //     }
// //   }, []);

// //   const incrementVisitorCount = () => {
// //     // Increment visitor count and update localStorage
// //     const newCount = visitorCount + 1;
// //     localStorage.setItem('visitorCount', newCount);
// //     setVisitorCount(newCount);
// //   };

// //   return (
// //     <Grid container item  style={{backgroundColor:"white"}}>
// //       <Typography style={{color:"black"}}>Total Visitors: {visitorCount}</Typography>
// //       <Button onClick={incrementVisitorCount}>Increment Visitor Count</Button>
// //     </Grid>
// //   );
// // };

// // export default VisitorCounter;
