import { Button, Input, Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Carousel from "./Carousel";
import { PhotosModalContext } from "../contexts/PhotosModalContext";
import { StorageContext } from "../contexts/StorageContext";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { FirebaseContext } from "../contexts/FirebaseContext";
import {
  ref,
  uploadBytes,
  list,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

const PhotosModal = (props) => {
  const { showPhotos, setShowPhotos } = useContext(PhotosModalContext);
  const storage = useContext(StorageContext);
  const auth = useContext(AuthContext);
  const db = useContext(FirebaseContext);
  const [user, setUser] = useContext(UserContext);
  const [inputPhoto, setInputPhoto] = useState(null);
  const [photoHidden, setPhotoHidden] = useState(true);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (user) {
      const pathRef = ref(storage, `${user.uid}/photos`);
      let tempPhotos = [];
      const photosList = list(pathRef, { maxResults: 100 }).then((res) => {
        res.items.forEach((itemRef) => {
          let media = {
            url: null,
            type: null,
          };
          getDownloadURL(itemRef)
            .then((url) => {
              console.log(url);
              media.url = url;
              tempPhotos.push();
              // setPhotos((prevPhotos) => [...prevPhotos, url]);
            })
            .catch((error) => {
              console.log(error);
            });

          getMetadata(itemRef)
            .then((res) => {
              console.log(res.contentType);
              media.type = res.contentType;
            })
            .catch((error) => {
              console.log(error);
            });
          console.log(media);
          // tempPhotos.push(media);
        });
        console.log(tempPhotos);
        setPhotos(tempPhotos);
      });
    }
    return () => {};
  }, [user]);

  const handleUploadPhoto = async () => {
    console.log(inputPhoto);
    if (inputPhoto) {
      const storageRef = ref(storage, `${user.uid}/photos/${inputPhoto.name}`);
      uploadBytes(storageRef, inputPhoto).then(async (snapshot) => {
        // console.log(JSON.stringify(snapshot.ref));
        getDownloadURL(snapshot.ref)
          .then(async (url) => {
            updateProfile(auth.currentUser, { photoURL: url }).then(() => {
              console.log("User photo updated");
            });
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              setDoc(docRef, { ...docSnap.data(), photoURL: url })
                .then(() => {
                  console.log(
                    "Document successfully written!... " + JSON.stringify(user)
                  );
                })
                .catch((error) => {
                  console.error("Error updating firestore image ", error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });

      setPhotos((prevPhotos) => [
        ...prevPhotos,
        URL.createObjectURL(inputPhoto),
      ]);
    }
  };

  return (
    <Modal
      open={showPhotos}
      onClose={() => {
        setShowPhotos(false);
        setInputPhoto(null);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="photo-modal-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#DBA622",
          height: "80%",
          width: "75%",
          borderRadius: "min(5vh, 5vw)",
        }}
      >
        {inputPhoto && (
          <img
            style={{
              height: "30%",
              width: "30%",
            }}
            src={URL.createObjectURL(inputPhoto)}
            alt="inputPhoto"
          />
        )}

        <label htmlFor="photoInput">
          <input
            type="file"
            id="photoInput"
            // style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg,"
            onChange={(e) => {
              if (e.target.files[0]) {
                console.log(e.target.files[0]);
                setInputPhoto(e.target.files[0]);
                return;
              }
            }}
          />
        </label>

        <Button onClick={handleUploadPhoto}>Upload Photo</Button>
        <Carousel
          containerHeight={"50%"}
          containerWidth={"80%"}
          carouselHeight={"100%"}
          carouselWidth={"100%"}
          objectFit={"contain"}
          photos={photos}
        />
      </div>
    </Modal>
  );
};

export default PhotosModal;
