import { Button, Input, Modal, TextField, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import "./CommentsModal.css";
import Comment from "./Comment";
import { CommentsModalContext } from "../contexts/CommentsModalContext";
import { useUser } from "../hooks/useUser";
import { UserContext } from "../contexts/UserContext";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { SendRounded } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { nanoid } from "nanoid";
import Carousel from "./Carousel";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import { StorageContext } from "../contexts/StorageContext";

const CommentsModal = (props) => {
	const [user, setUser] = useContext(UserContext);
	const db = useContext(FirebaseContext);
	const storage = useContext(StorageContext);
	const {
		setShowComments,
		showComments,
		commentModalData,
		setCommentModalData,
	} = useContext(CommentsModalContext);
	const [commentInput, setCommentInput] = useState("");
	const [textCount, setTextCount] = useState(200);
	const [ownerData, setOwnerData] = useState({});
	const [initializing, setInitializing] = useState(false);
	const gettingPhotos = React.useRef(false);

	useEffect(() => {
		setInitializing(true);
		if (commentModalData) {
			const handleGetYipOwnerData = async () => {
				const docRef = doc(db, "users", commentModalData.owner);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					docSnap.data() && setOwnerData(docSnap.data());
					// console.log("Document data:", docSnap.data());
				} else {
					// doc.data() will be undefined in this case
					console.log("No such document!");
				}
			};
			handleGetYipOwnerData();
			// console.log("Owner Data: " + JSON.stringify(ownerData));
			// console.log("Comment Modal Data: " + JSON.stringify(commentModalData));
		}

		setInitializing(false);

		return () => {
			// cleanup
		};
	}, [commentModalData]);

	const handleAddComment = async () => {
		const docRef = doc(db, "yips", commentModalData.id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const yip = docSnap.data();
			yip.comments.push({
				id: nanoid(),
				commentOwner: user.displayName,
				commentOwnerId: user.uid,
				// commentOwnerPhotoURL: user.photoURL,
				commentText: commentInput,
				commentTime: dayjs().format("MM-DD-YYYY HH:mm:ss"),
			});
			await setDoc(docRef, yip).then(() => {
				console.log("Document successfully written!... " + JSON.stringify(yip));
				setCommentModalData((prev) => {
					prev.comments = yip.comments;
					return prev;
				});
				setCommentInput("");
			});
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
	};

	const handleDeleteComment = async (commentID) => {
		const docRef = doc(db, "yips", commentModalData.id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const yip = docSnap.data();
			console.log("Yip Data: " + JSON.stringify(yip.comments));
			const newComments = yip.comments.filter(
				(comment) => comment.id != commentID
			);
			yip.comments = newComments;
			await setDoc(docRef, yip).then(() => {
				setCommentModalData(() => {
					yip.photos = commentModalData.photos;
					return yip;
				});
				console.log(
					"Document successfully deleted!... " +
						JSON.stringify(commentModalData.comments)
				);
			});
		}
	};

	return (
		<Modal
			open={showComments}
			onClose={() => {
				setShowComments(false);
				setCommentInput("");
				setTextCount(200);
				setCommentModalData({});
			}}
		>
			<div className="content-container">
				<div className="comment-bar">
					<div className="comments-modal-header-container">
						<div className="comments-modal-top-header-container">
							<div className="comments-modal-image-container">
								<img
									className="comments-modal-header-image"
									src={ownerData && ownerData.photoURL}
									alt="comment-profile-pic"
								/>
							</div>
							<div className="comments-modal-header">
								<div className="comments-modal-header-text">
									<Typography
										style={{
											fontSize: "min(2.8vw, 2.8vh)",
											fontWeight: "bold",
										}}
									>
										{ownerData && ownerData.firstName}
									</Typography>
									<Typography
										style={{
											fontSize: "min(1.5vw, 1.5vh)",
										}}
									>
										{commentModalData &&
											dayjs(commentModalData.timestamp.seconds * 1000).format(
												"h:mm a, MMMM DD YYYY"
											)}
									</Typography>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
									// backgroundColor: "orange",
									justifyContent: "flex-end",
								}}
							>
								<CloseRoundedIcon
									style={{
										height: "min(4vw, 4vh)",
										width: "min(4vw, 4vh)",
										display: "flex",
									}}
									onClick={() => {
										setShowComments(false);
										setCommentInput("");
										setTextCount(200);
									}}
								/>
							</div>
						</div>
						<div className="original-yip-text">
							<Typography
								variant={"h4"}
								style={{
									width: "100%",
									fontSize: "min(2.5vw, 2.5vh)",
								}}
							>
								{commentModalData && commentModalData.text}
							</Typography>
						</div>
					</div>
					{commentModalData &&
						commentModalData.photos &&
						commentModalData.photos.length > 0 && (
							<Carousel
								containerWidth={"100%"}
								containerHeight={"70%"}
								carouselHeight={"90%"}
								carouselWidth={"100%"}
								borderRadius={"1px"}
								objectFit={"contain"}
								photos={commentModalData.photos}
							/>
						)}
					{/* <div
						style={{
							maxWidth: "100%",
							flexDirection: "column",
							alignItems: "flex-start",
							backgroundColor: "white",
							marginTop: "min(1vw, 1vh)",
							borderRadius: "5px",
							padding: "min(1vw, 1vh)",
							wordBreak: "break-word",
						}}
					>
						{JSON.stringify(commentModalData?.comments)}
					</div> */}
					<div className="commments-container">
						<Typography
							variant={"h4"}
							style={{
								// width: "100%",
								fontSize: "min(3vw, 3vh)",
								fontWeight: "bold",
							}}
						>
							Comments
						</Typography>
						{commentModalData && commentModalData.comments.length > 0 ? (
							<div className="comment-list">
								{commentModalData.comments.map((comment) => {
									return (
										<Comment
											key={comment.id}
											comment={comment}
											handleDelete={handleDeleteComment}
										/>
									);
								})}
							</div>
						) : (
							<Typography> No Comments </Typography>
						)}

						<div className="comment-input">
							<div
								style={{
									// width: "100%",
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
								}}
							>
								<div
									style={{
										display: "flex",
										flex: 1,
										// width: "100%",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										multiline
										maxRows={3}
										label="Add a Comment"
										variant="outlined"
										sx={{
											bgcolor: "white",
											borderRadius: "5px",
											marginRight: "min(1vw, 1vh)",
										}}
										value={commentInput}
										onChange={(e) => {
											if (e.target.value.length > 200) {
												return;
											}
											setCommentInput(e.target.value);
											setTextCount(200 - e.target.value.length);
										}}
									/>
									<SendRounded
										style={{
											height: "min(3vw, 3vh)",
											width: "min(3vw, 3vh)",
											display: "flex",
											cursor: "pointer",
										}}
										onClick={handleAddComment}
									/>
								</div>

								<Typography
									style={{
										fontSize: "min(1.5vw, 1.5vh)",
									}}
								>
									Maximum Characters: {textCount}
								</Typography>
							</div>

							{/* <Button onClick={handleAddComment}></Button> */}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CommentsModal;
