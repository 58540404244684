import React, { useState } from "react";

const CustomMedia = (props) => {
	const { photo, onClick, startTime, setStartTime, autoplay } = props;
	const [hovering, setHovering] = useState(true);

	return (
		<>
			{photo.type == "video/mp4" ? (
				<video
					resource="video/mp4"
					src={photo.url + "#t=0.1"}
					style={{
						height: "100%",
						width: "100%",
						maxHeight: "300px",
						cursor: "pointer",
						borderRadius: "5px",
						backgroundColor: "white",
					}}
					preload="auto"
					controls={hovering}
					autoPlay={autoplay}
					onPlay={(e) => {
						if (startTime) {
							e.target.currentTime = startTime;
							console.log(startTime);
						}
					}}
					onMouseOver={() => {
						setHovering(true);
					}}
					onMouseLeave={() => {
						setHovering(false);
					}}
					onClick={
						onClick
							? (e) => {
									onClick(e);
							  }
							: null
					}
					onLoad={(e) => {
						// console.log("Video Loaded");
					}}
					onCanPlay={(e) => {
						// if (startTime) {
						// 	e.target.currentTime = startTime;
						// 	console.log(startTime);
						// 	console.log(e.target.currentTime);
						// }
					}}
					onDurationChange={(e) => {}}
				/>
			) : (
				<img
					src={photo.url}
					alt="post-yip-photo"
					style={{
						display: "flex",
						width: "100%",
						height: "100%",
						maxHeight: "300px",
						cursor: "pointer",
						// maxHeight: "170px",
						// maxWidth: "150px",
						objectFit: "contain",
						borderRadius: "5px",
						backgroundColor: "white",
					}}
					onClick={(e) => {
						onClick(e);
					}}
				/>
			)}
		</>
	);
};

export default CustomMedia;
