import React, { useContext, useEffect, useState } from "react";
import "./Comment.css";
import { Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { UserContext } from "../contexts/UserContext";
import dayjs from "dayjs";
import { CommentsModalContext } from "../contexts/CommentsModalContext";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { doc, getDoc } from "firebase/firestore";

const Comment = (props) => {
	const { comment, handleDelete } = props;
	const [user, setUser] = useContext(UserContext);
	const [ownerData, setOwnerData] = useState();
	const db = useContext(FirebaseContext);

	useEffect(() => {
		const handleGetCommentOwnerData = async () => {
			// console.log(comment);
			const docRef = doc(db, "users", comment.commentOwnerId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				docSnap.data() && setOwnerData(docSnap.data());
				// console.log("Comment Owner Data:", docSnap.data());
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		};
		handleGetCommentOwnerData();

		return () => {};
	}, []);

	return (
		<div className="comment">
			<div className="comment-content">
				<div className="comment-header">
					<div className="comment-profile-pic-container">
						<img
							src={ownerData && ownerData.photoURL}
							alt="comment-profile-pic"
							style={{
								height: "min(6vw, 6vh)",
								width: "min(6vw, 6vh)",
								borderRadius: "50%",
								// backgroundColor: "green",
								objectFit: "cover",
							}}
						/>
					</div>
					<div className="comment-header-text">
						<Typography
							style={{
								fontSize: "min(3vw, 3vh)",
								fontWeight: "bold",
								// background: "blue"
							}}
						>
							{comment.commentOwner}
						</Typography>
						<Typography
							style={{
								fontSize: "min(1.7vw, 1.7vh)",
								overflowX: "hidden",
								// overflow: "hidden",
							}}
						>
							{dayjs(comment.commentTime).format("h:mm a, MMMM DD YYYY")}
						</Typography>
					</div>
					{user.displayName === comment.commentOwner && (
						<div className="delete-icon">
							<DeleteRoundedIcon
								style={{ cursor: "pointer" }}
								onClick={() => {
									handleDelete(comment.id);
								}}
							/>
						</div>
					)}
				</div>
				<div className="comment-text">
					<Typography variant={"h7"}>{comment.commentText}</Typography>
				</div>
			</div>
		</div>
	);
};

export default Comment;
