import { getDocs, query, where, collection, addDoc } from "firebase/firestore";
import * as XLSX from "xlsx";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const GLTableAssignment = async (db, accessCode, EventAccessCode) => {
  try {
    const rsvpQuery = query(
      collection(db, "rsvps"),
      where("accessCode", "==", accessCode)
    );

    const querySnapshot = await getDocs(rsvpQuery);
    const rsvpData = querySnapshot.docs.map((doc) => doc.data());

    if (!Array.isArray(rsvpData)) {
      throw new Error("rsvpData is not an array.");
    }

    let tableAssignments = [];

    rsvpData.forEach((rsvp) => {
      const { accessCode, invitedRecord } = rsvp;
      const { firstName, lastName, foodChoice, guests } = invitedRecord;

      const recordTableAssignment = {
        AccessCode: accessCode,
        FirstName: firstName,
        LastName: lastName,
        FoodChoice: foodChoice,
      };

      tableAssignments.push(recordTableAssignment);

      guests.forEach((guest, index) => {
        const { name, foodChoice } = guest;
        const guestTableAssignment = {
          AccessCode: accessCode,
          FirstName: firstName,
          LastName: lastName,
          GuestName: name,
          GuestFoodChoice: foodChoice,
        };
        tableAssignments.push(guestTableAssignment);
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(tableAssignments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Assignments");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `YourAreInvited/${accessCode}/RSVPS/table_assignments.xlsx`
    );
    await uploadBytes(storageRef, excelData);

    const downloadURL = await getDownloadURL(storageRef);

    await addDoc(collection(db, "excelFiles"), {
      downloadURL: downloadURL,
      createdAt: new Date(),
      accessCode: accessCode,
    });

    console.log("Excel file uploaded successfully!");
  } catch (error) {
    console.error("Error uploading Excel file:", error);
  }
};

export default GLTableAssignment;
