import { useContext, useEffect, useState } from "react";
import admin from "./admin";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";

const useUnreadNotificationCount = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [user, setUser] = useContext(UserContext);
  const { db } = admin();

  useEffect(() => {
    const fetchUserNotifications = async () => {
      // Ensure db object and user are defined
      if (!db || !user) return;

      const docRef = doc(db, "notifications", user.uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {    
        // Ensure that 'notifications' property exists in the userNotificationsData object
        if (docSnap.data() && docSnap.data().notifies) {
          const notifies = docSnap.data().notifies;
          
          // Calculate the count of unread notifications
          const unreadCount = notifies.filter(notification => notification.sender !== user.uid && !notification.read).length;
          setUnreadCount(unreadCount);
        } else {
          // If 'notifications' property is not found or is undefined, set unreadCount to 0
          setUnreadCount(0);
        }
      } else {
        // If document does not exist, set unreadCount to 0
        setUnreadCount(0);
      }
    };

    fetchUserNotifications();
  }, [user, db]);

  return unreadCount;
};

export default useUnreadNotificationCount;
