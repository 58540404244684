import { Button, Typography } from '@mui/material'
import React from 'react'

const MarketplaceCard = () => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            padding: "10px",
            height: "60%",
            backgroundColor: "white",
            borderRadius: "20px",
            marginTop: "10px",
            maxWidth: "90%",
            width: "90%",
        }}>
            <div style={{
                display: "flex",
                padding: "5px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <Typography width={"30%"} fontWeight={"bold"} > Item 1 </Typography>
                <Typography width={"30%"}> Rating 1 </Typography>
            </div>
            <div style={{
                display: "flex",
                padding: "5px",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Typography width={"30%"} > Price:  </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    // backgroundColor: "skyblue",
                    padding: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center ",
                    maxHeight: "55%",
                }}
            >
                <img src="https://picsum.photos/id/163/1000/600"
                    alt='yip-input-profile-pic'
                    style={{
                        // height: "min(10vw, 10vh)",
                        // width: "min(10vw, 10vh)",
                        height: "100%",
                        width: "80%",
                        maxHeight: "95%",
                        // backgroundColor: "white",
                        objectFit: "cover",
                    }}
                />

            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center ",
                }}
            >
                <Button title='Add To Basket' variant='contained' > Add To Basket </Button>

            </div>



        </div>
    )
}

export default MarketplaceCard