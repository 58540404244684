import React, { useContext, useState, useEffect } from "react";
import { Avatar, Grid, Input, TextField, Typography } from "@mui/material";
import Nova from "../assets/Profileimages/Nova.jpg";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  limit,
  doc,
} from "firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/base";
import { ChatContext } from "../contexts/ChatContext";

const Search = () => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useContext(UserContext);
  const [err, setErr] = useState(false);
  const db = useContext(FirebaseContext);
  const { currentUser } = useContext(AuthContext);
  const [searchChatMembers, setSearchChatMembers] = useState({});
  const [searchChatMemberName, setSearchChatMemberName] = useState("");
  const { dispatch } = useContext(ChatContext);

  const handleSearch = async () => {
    if (!username) {
      // Handle empty search query
      return;
    }
    try {
      const q = query(
        collection(db, "users"),
        where("firstName", "==", username),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty || !querySnapshot) {
        setErr(true);
        return;
      }
      querySnapshot.forEach((doc) => {
        if (!doc || !doc.exists()) {
          setErr(true);
          return;
        }
        // Extract doc.id along with data
        setSearchChatMembers({ id: doc.id, ...doc.data() });
        setSearchChatMemberName(doc.firstName);
      });
    } catch (error) {
      console.error("Error searching for user:", error);
      setErr(true);
    }
  };

  const handleKey = (e) => {
    if (e.code === "Enter" || e.type === "click") {
      handleSearch();
    }
  };

  const handleSelect = async () => {
    //   // Check if the group (chats collection in Firestore) exists, if not create a new one
    const combinedId =
      currentUser.uid > searchChatMembers.id
        ? currentUser.uid + searchChatMembers.id
        : searchChatMembers.id + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        // Create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        // Create user chats
        const currentUserChatData = {
          [combinedId]: {
            userInfo: {
              id: searchChatMembers.id,
              firstName: searchChatMembers.firstName,
              photoURL: searchChatMembers.photoURL,
            },
            date: serverTimestamp(),
          },
        };

        const otherUserChatData = {
          [combinedId]: {
            userInfo: {
              id: currentUser.uid,
              firstName: currentUser.displayName,
              photoURL: currentUser.photoURL,
            },
            date: serverTimestamp(),
          },
        };

        await setDoc(
          doc(db, "userChats", currentUser.uid),
          currentUserChatData
        );
        await setDoc(
          doc(db, "userChats", searchChatMembers.id),
          otherUserChatData
        );
      } else {
        // Dispatch an action to change the user based on the combinedId
        dispatch({
          type: "CHANGE_USER",
          payload: {
            id: searchChatMembers.id,
            firstName: searchChatMembers.firstName,
            photoURL: searchChatMembers.photoURL,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
    // setSearchChatMembers("");
    // setSearchChatMemberName("")
  };

  useEffect(() => {
    if (Object.keys(searchChatMembers).length > 0) {
      // User found
      setErr(false);
    }
  }, [searchChatMembers]);

  return (
    <Grid container item xs={12} className="search">
      <Grid item container xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* TextField for search */}
          <TextField
            id="search"
            placeholder="find User"
            size="small"
            InputProps={{
              style: {
                color: "white",
                marginTop: "2px",
                fontFamily: "cursive",
                fontSize: "10px", // Set font size to 10px
                outline: "none",
              },
              inputProps: {
                maxLength: 10, // Limit the number of characters to 10
              },
            }}
            InputLabelProps={{ style: { color: "secondary" } }} // Set placeholder color to white
            onKeyDown={handleKey}
            onChange={(e) => setUsername(e.target.value)}
          />

          {/* Button wrapping SearchIcon */}
          <Button
            onClick={handleKey}
            style={{
              height: "26px", // Set height to 'fit-content' to match TextField height
              marginLeft: "-1px", // Adjust margin to properly align with TextField
              backgroundColor: "#3e3c61",
            }}
          >
            <SearchIcon style={{ color: "white" }} />
          </Button>
        </div>
        <br />
      </Grid>
      <Grid item xs={12}>
        {err && (
          <span>
            <Typography
              style={{ color: "red", fontSize: "12px", fontFamily: "cursive" }}
            >
              User not found!
            </Typography>
          </span>
        )}
        {/* {searchChatMembers.photoURL && ( */}
        <br />
        <div className="userChat" onClick={handleSelect}>
          <br />

          {searchChatMembers.photoURL ? (
            <img
              className="sideBareImage"
              src={searchChatMembers.photoURL}
              alt=""
              onClick={handleSelect}
              id="serachimage"
            />
          ) : (
            searchChatMembers &&
            searchChatMembers.firstName && (
              <Avatar
                width={100}
                height={100}
                initials="JD" // Initials for the avatar
                rounded={true} // Whether to round the avatar
                backgroundColor="#007bff" // Background color of the avatar
                color="#ffffff" // Text color of the initials
                fontSize={40} // Font size of the initials
                onClick={handleSelect}
              />
            )
          )}
          {searchChatMembers.firstName &&
            searchChatMembers.firstName.trim() !== "" && (
              <div
                id="searchChatMemberfirstName"
                style={{
                  maxWidth: "100%",
                  fontSize: "10px",
                  color: "lightgrey",
                  textWrap: "wrap",
                  position: "relative",
                }}
                onClick={handleSelect}
              >
                <span>{searchChatMembers.firstName}</span>
              </div>
            )}
        </div>
      </Grid>
      {/* )} */}
    </Grid>
  );
};

export default Search;
