import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import XIcon from '@mui/icons-material/X';
import "./Footer.css";
import {createTheme, ThemeProvider} from "@mui/material"; 
import { blue } from "@mui/material/colors";
import VisitorCounter from "./VisitorCounter";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "white",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "10px",
        }
      }
    }
  }})


const NewFooter = () => {
  const FacebookLink = () => (
    <a href="https://www.facebook.com">
      <Facebook  style={{ color: "white" }}/>
    </a>
  );

  const TwiterLink = () => (
    <a href="https://twitter.com">
      <XIcon style={{ color: "white" }}/>
    </a>
  );

  const InstagramLink = () => (
    <a href="https://www.instagram.com">
      <Instagram style={{ color: "white" }}/>
    </a>
  );
  return (
    <ThemeProvider theme={theme}>
    <div style={{ 
      backgroundColor: "black", 
    padding: 20,
    color: "white",
      }}
      >
      <Grid container spacing={2}>
        {/* Socials */}
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography variant="h6" align="center" ><Button style={{  color: 'white',
                    textTransform: "none"}} fontWeight={"bold"}  href="/letsconnect">Socials</Button></Typography>
          <Grid item>
            <Button href="/letsconnect"><Typography fontWeight={"bold"} >Lets Connect</Typography></Button>
          </Grid>
          <Grid item>
            <Button href="/latestlaevents"><Typography fontWeight={"bold"} >Latest Event</Typography></Button>
          </Grid>
        </Grid>
        {/* Sports */}
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography variant="h6" fontWeight={"bold"}  align="center" ><Button  style={{ color: 'white',
             textTransform: "none"
       }} href="/sports">
         Sports Illustration 
        </Button></Typography>
          <Grid item>
            <Button href="/sports"><Typography fontWeight={"bold"} >Galore Sports</Typography></Button>
          </Grid>
        </Grid>
        {/* Our Company */}
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography variant="h6" align="center" >  <Button  style={{ color: 'white',
                    textTransform: "none"

         }} fontWeight={"bold"}  href="/about">Our Company</Button></Typography>
          <Grid item >
            <Button href="/about"><Typography fontWeight={"bold"} >About </Typography></Button>
          </Grid>
          <Grid item>
            <Button href="/contact"><Typography fontWeight={"bold"} >Contact</Typography></Button>
          </Grid>
          <Grid item>
            <Button href="https://africangalore.com/asa-sc_aswa-sc"><Typography fontWeight={"bold"} > African Galore</Typography></Button>
          </Grid>
        </Grid>
      </Grid>

      {/* New Section: Visit Us */}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h4" fontWeight={"bold"} fontSize={"14px"} fontFamily={"cursive"} align="center">Follow us</Typography>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Button >
                <FacebookLink />
              </Button>
            </Grid>
            <Grid item>
              <TwiterLink />
            </Grid>
            <Grid item>
              <InstagramLink />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
      <Typography style={{fontSize: "10px", color:"white"}}>Copyright © 2023 Galore Land Services LLC. All rights reserved. </Typography>
      </Grid>
      <Grid item xs={12}>
          <VisitorCounter/>
        </Grid> 
    </div>
    </ThemeProvider>
  );
};

export default NewFooter;
