import admin from "./admin";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const resetMessageCount = async (user) => {
  const { db } = admin();

  const docRef = doc(db, "userChats", user.uid);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) return;

  const userChatsData = docSnap.data();
  console.log("userChatsData from the Chating system", userChatsData);

  const chatKeys = Object.keys(userChatsData);
  const chatKey0 = chatKeys[0];
  console.log("chatKey0", chatKey0);

  // Access the specific chat document using its ID
  const chatDocRef = doc(db, "chats", chatKey0);
  const chatDocSnap = await getDoc(chatDocRef);
  if (!chatDocSnap.exists()) return;

  // Access the "messages" field from the chat document
  const chatData = chatDocSnap.data();
  console.log("chatData at resetMessageCount =", chatData, " user.id =", user.uid);

  // Reset the count of messages and update messageRead to false
  const messages = chatData.messages || [];
  const resetCount = messages.reduce((count, message) => {
    if (message.senderId !== user.uid && !message.messageRead) {
      // Update messageRead to false
      message.messageRead = true;
    }
    return count;
  }, 0);

  // Update the chat document with modified messages
  await updateDoc(chatDocRef, { messages });

  console.log("resetCount=", resetCount);
  return resetCount;
};

export default resetMessageCount;
