// import React, { useState } from "react";
import { ListItemButton, ListItemText, Avatar } from "@mui/material";
import {
  Block,
  Edit,
  Event as EventIcon,
  Sports as SportsIcon,
} from "@mui/icons-material";
import { Diversity3 as Diversity3Icon } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";

import LetsconnetPink from "../assets/Background/LetsconnetPink.jpg";
import { createTheme, ThemeProvider } from "@mui/material";

/******************************************************* */
import { Button, Grid, List, Modal, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import "../screens/LetsConnect.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import { collection, getDocs } from "@firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { EditProfileContext } from "../contexts/EditProfileContext";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import { StorageContext } from "../contexts/StorageContext";

import { AuthContext } from "../contexts/AuthContext";

import "../components/ProfileCard.css";
import { signOut } from "firebase/auth";
import dayjs from "dayjs";
import LoginIcon from '@mui/icons-material/Login';
import SoulMateModal from "../screens/SoulMateModal";


const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "10px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const ItemIconList = ({ index, glComp }) => {
const icons= [FavoriteIcon, EventIcon, Diversity3Icon, SportsIcon];
  const Icon = icons[index % icons.length];
  let color = "black";
  if (index === 0) {
    color = "red";
  } else if (index === 1) {
    color = "purple";
  } else if (index === 2) {
    color = "black";
  } else if (index === 3) {
    color = "blue";
  }

  return <Icon style={{ color }} />;
};

const GlDrawer1 = (glComp) => {
  const [user, setUser] = useContext(UserContext);
  const [yips, setYips] = useState([]);

  const auth = useContext(AuthContext);

  const storage = useContext(StorageContext);
  const navigation = useNavigate();
  const db = useContext(FirebaseContext);

  const [modal1Open, setModal1Open] = useState(false);
  const [profilPicture, setProfilePicture] = useState("");
  const [glsearch, setGlsearch] = useState("");
  const [userName, setUserName] = useState("");
  const [datejoined, setDateJoined] = useState("");
  const [ editProfileModal, setEditProfileModal ] =
    useContext(EditProfileContext);
  const navigate = useNavigate();


  const toggleModal1 = () => {
    setModal1Open(!modal1Open);
  };



  

  useEffect(() => {
    const setHeaderInfo = async () => {
      if (user) {
        setProfilePicture(user.photoURL);
        setUserName(user.displayName);
        setDateJoined(dayjs(user.metadata.creationTime).format("MMMM D, YYYY"));
      }
    };
    const getYips = async () => {
      let tempYips = [];
      const querySnapshot = await getDocs(collection(db, "yips"));
      querySnapshot.forEach(async (yip) => {
        let tempMedia = [];
        let yipData = yip.data();
        if (yipData.photos) {
          const pathRef = ref(storage, `${yipData.owner}/yips/${yipData.id}`);
          const storageSnap = await list(pathRef, { maxResults: 100 });
          storageSnap.items.forEach(async (itemRef) => {
            let media = {};
            const url = await getDownloadURL(itemRef);
            const res = await getMetadata(itemRef);
            media.url = url;
            media.type = res.contentType;
            tempMedia.push(media);
          });
          yipData.photos = tempMedia;
          tempYips.push(yipData);
        }
        setTimeout(() => {
          setYips(tempYips);
        }, 1000);
      });
    };

    getYips();

    setHeaderInfo();
    // console.log("refreshing");
  }, [user, storage, db]);


  return (
    <Grid
      container
      item
      // spacing={2}
      xs={12}
      sx={{
        backgroundImage: `url(${LetsconnetPink})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <EditProfileContext.Provider
        value={[ editProfileModal, setEditProfileModal ]}
      >
             
        <Grid container item xs={12}>
          <Modal
               open={modal1Open}
               onClose={toggleModal1}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
            >
            <div>
               <SoulMateModal
                 onClose={toggleModal1}
                 modalTitle="Pick Your Match"
                 modalContent="This is the content of Modal 1."
               />
               </div>
             </Modal>        
          <Grid item xs={4} style={{ maxHeight: "450px", borderRadius: "50px"}}>
            <Grid item xs={12} borderRadius={"30px"}>
              <ThemeProvider theme={theme} >
                <Grid item xs={12} >
                  <List id="transpose">
                    {[
                      "Find Soul Mate",
                      "Latest Events",
                      "Friends",
                      "Sports",
                    ].map((text, index) => (
                      <ListItemButton
                        key={text}
                        disablepadding="true"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "cursive",
                        }}
                        onClick={() => {
                          if (index === 0 ) 
                          {
                            if(!user){
                              navigate("/letsconnect")
                            }else {
                            toggleModal1()};
                          } else if (index === 1) {
                            navigate("/latestlaevents");
                          } else if (index === 2) {
                            navigation("/letsconnect");
                          } else if (index === 3) {
                            navigation("/sports");
                          }
                        }}
                      >
                        <ItemIconList index={index} glComp />
                        <ListItemText
                          primary={text}
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          <Typography>{text}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </Grid>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </EditProfileContext.Provider>
    </Grid>
  );
};

export default GlDrawer1;
