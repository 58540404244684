import { Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import SportsCard from "../components/SportsCard";
import { StorageContext } from "../contexts/StorageContext";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import CustomMedia from "../components/CustomMedia";
import ScrollGrid from "../components/ScrollGrid";
import GlDrawer1 from "../components/GlDrawer1";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const innerPadding = "10px";
const outerPadding = "20px";
const innerMargin = "10px";
const outerMargin = "20px";

const Sports = () => {
  return (
    <Grid
      container
      item
      display={"flex"}
      direction={"column"}
      xs={12}
      height={"50%"}
      sx={{
        // bgcolor: "#670404",
        // height: '140vh',
        flex: 1,
        maxWidth: "vw",
        // minHeight: "100vh",
        maxHeight: "vh",
        // maxHeight: 'vh',
      }}
    >
      <Body />
    </Grid>
  );
};
const Body = () => {
  const theme = useContext(ThemeContext);
  const storage = useContext(StorageContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setOpen1(true);
  };

  const handleCloseDialog = () => {
    setSelectedItem(null);
    setOpen1(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const links = [
    {
      title: "High School Basketball, Boys",
      url: "https://www.espn.com/college-sports/basketball/recruiting/playerrankings",
    },
    {
      title: "High School Basketball, Girls",
      url: "https://www.espn.com/high-school/girls-basketball/recruiting/prospects/_/class/2024",
    },
    {
      title: "High School Football Recruits",
      url: "https://www.espn.com/college-sports/football/recruiting/playerrankings/_/class/2024/order/true",
    },
    {
      title: "High School Track & Field Boys",
      url: "https://www.dyestat.com/gprofile.php?mgroup_id=44531&do=news&news_id=667288",
    },
    {
      title: "High School Track & Field Girls",
      url: "https://www.dyestat.com/gprofile.php?mgroup_id=44531&do=news&news_id=667287",
    },
  ];
  const [photos, setPhotos] = useState([]);
  const [giannis, setGiannis] = useState(null);
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    const pathRef = ref(storage, "SportsImages/");
    list(pathRef).then((res) => {
      let tempPhotos = [];
      res.items.forEach(async (itemRef) => {
        let media = {};
        const url = await getDownloadURL(itemRef);
        const res = await getMetadata(itemRef);
        media.url = url;
        media.type = res.contentType;
        tempPhotos.push(media);
      });
      setTimeout(() => {
        setPhotos(tempPhotos);
      }, 1000);
    });
    const gianisRef = ref(storage, "SportsImages/Gianis-A.mp4");
    getDownloadURL(gianisRef)
      .then((url) => {
        setGiannis(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storage]);

  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContents: "center",
        }}
      >
        <GlDrawer1 glcom="sports" />
      </Grid>
      <Grid item xs={8}>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            display={"flex"}
            // minHeight={"95vh"}
            padding={1}
            // justifyContent={"space-evenly"}
          >
            <Typography
              width={"100%"}
              variant="h5"
              fontWeight={"bold"}
              fontSize={"min(6.5vw, 6.5vh)"}
              style={{
                textAlign: "center",
                margin: "min(1vh,1vw)",
                marginBottom: "min(4vh,4vw)",
              }}
            >
              Sports Highlights
            </Typography>
            <Grid
              item
              // bgcolor={"red"}
              display={"flex"}
              xs={12}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "min(3vh,3vw)",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                  padding: "10px",
                  height: "100%",
                  maxHeight: "100%",
                  marginBottom: "min(1vh,1vw)",
                }}
              >
                <div>
                  <video
                    id=""
                    src={giannis + "#t=0.1"}
                    onClick={handleOpen}
                    style={{ cursor: "pointer" }}
                    // controls
                    width={"100%"}
                    height={"90%"}
                    objectFit="cover"
                  />
                  <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <IconButton
                      aria-label="close"
                      style={{ position: "absolute", right: 0, top: 0 }}
                      onClick={handleClose}
                    >
                      <Close />
                    </IconButton>
                    <DialogContent>
                      <video
                        playsInline
                        src={giannis + "#t=0.1"}
                        alt="news-image-card"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "90vh",
                          objectFit: "cover",
                        }}
                        controls
                        autoPlay
                        onMouseOver={() => {
                          setHovering(true);
                        }}
                        onMouseLeave={() => {
                          setHovering(false);
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
                <Typography
                  width={"100%"}
                  variant="h5"
                  fontWeight={"bold"}
                  fontSize={"min(3vw, 3vh)"}
                >
                  Giannis Antetokounmpo's greatest blocks of all time.
                </Typography>
              </Paper>
            </Grid>

            <Grid
              maxHeight={"300px"}
              overflow={"scroll"}
              id="sports-grid"
              container
              // item
              // justifyContent="center"
              display={"flex"}
              // justifyContent={"space-evenly"}
              // spacing={0}
              gap={0}
              // bgcolor={"red"}
            >
              <ScrollGrid title={"Top Prospects"}>
                {links.map((item) => (
                  <a
                    key={item.title}
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      height: "100px",
                      width: "100%",
                      background: theme.color,
                      borderRadius: innerPadding,
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: outerMargin,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "100%",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </a>
                ))}
              </ScrollGrid>

              <ScrollGrid title={"Top Elites"}>
                {photos.map((item) => (
                  <div key={item.title} onClick={() => handleOpenDialog(item)}>
                    <SportsCard src={item} />
                  </div>
                ))}
              </ScrollGrid>

              <ScrollGrid title={"Breaking News"}>
                <Typography
                  variant="body2"
                  // color="textSecondary"
                  style={{ fontSize: 20 }}
                >
                  <a
                    href="https://www.si.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "white",
                    }}
                  >
                    Sports Illustrated
                  </a>
                </Typography>
                <Typography
                  variant="body2"
                  // color="textSecondary"
                  style={{ fontSize: 20 }}
                >
                  <a
                    href="https://www.twitter.com/"
                    target="_blank"
                    style={{
                      color: "white",
                    }}
                  >
                    Twitter
                  </a>
                </Typography>
                <Typography style={{ fontSize: 20, color: "white" }}>
                  <a
                    href="https://www.espn.com/"
                    style={{
                      color: "white",
                    }}
                    target="_blank"
                  >
                    ESPN
                  </a>
                </Typography>
              </ScrollGrid>
              <Dialog open={open1} onClose={handleCloseDialog} maxWidth="md">
                <IconButton
                  aria-label="close"
                  style={{ position: "absolute", right: 0, top: 0 }}
                  onClick={handleCloseDialog}
             	>
                  <Close />
                </IconButton>
                <DialogContent>
                  {selectedItem && (
                    <div>
                      <SportsCard src={selectedItem} />
                    </div>
                  )}
                </DialogContent>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Sports;
