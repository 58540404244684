import { Avatar, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { CommentsModalContext } from "../contexts/CommentsModalContext";
import { FirebaseContext } from "../contexts/FirebaseContext";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import { useParams } from "react-router-dom";

const YipFooter = (props) => {
  // const { showComments, setCommentModalData, yip } = props;
  const { yip } = props;
  const [user, setUser] = useContext(UserContext);
  const id = useParams().id;
  const { setShowComments, setCommentModalData } =
    useContext(CommentsModalContext);
  const db = useContext(FirebaseContext);

  useEffect(() => {
    // console.log("Yip Footer " + JSON.stringify(yip));
  }, [yip]);

  const handleLikeYip = async () => {
    const yipRef = doc(db, "yips", yip.id);
    const userRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userRef);
    const yipSnapshot = await getDoc(yipRef);
    if (yipSnapshot.exists()) {
      let data = yipSnapshot.data();
      let newLikes = data.likes;
      //   console.log("New Likes " + JSON.stringify(data.likes));

      // ---------------------------- Unliking a yip ----------------------------
      if (newLikes && newLikes.includes(user.uid)) {
        newLikes = newLikes.filter((like) => like !== user.uid);
        setDoc(
          yipRef,
          {
            likes: newLikes,
          },
          { merge: true }
        )
          .then(async () => {
            if (id) {
              let userYips = [];
              const q = query(collection(db, "yips"), where("owner", "==", id));
              await getDocs(q).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  userYips.push(doc.data());
                });
                props.setYips(userYips);
              });
              return;
            }
            await getDocs(collection(db, "yips")).then((docs) => {
              let yips = [];
              docs.forEach((doc) => {
                yips.push(doc.data());
              });
              props.setYips(yips);
            });
            // console.log("Yip " + yip.id + " liked");
          })
          .catch((error) => {
            console.log(error);
          });
        return;
      }

      // ----------------------------Liking a yip START ----------------------------
      // Add Likes to yip database
      newLikes.push(user.uid);
      setDoc(
        yipRef,
        {
          likes: newLikes,
        },
        { merge: true }
      )
        .then(async () => {
          if (id) {
            let userYips = [];
            const q = query(collection(db, "yips"), where("owner", "==", id));
            await getDocs(q).then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                userYips.push(doc.data());
              });
              props.setYips(userYips);
            });
            return;
          }
          await getDocs(collection(db, "yips")).then((docs) => {
            let yips = [];
            docs.forEach((doc) => {
              yips.push(doc.data());
            });
            props.setYips(yips);
          });
          //   console.log("Yip " + yip.id + " liked");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Add Likes to user profile
    if (userSnapshot.exists()) {
      let data = userSnapshot.data();
      let newLikes = data.likes;
      //   console.log("New Likes " + JSON.stringify(data.likes));
      if (newLikes && newLikes.includes(yip.id)) {
        newLikes = newLikes.filter((like) => like !== yip.id);
        setDoc(
          userRef,
          {
            likes: newLikes,
          },
          { merge: true }
        )
          .then(() => {
            // console.log("User " + user.displayName + " un-liked " + yip.id);
          })
          .catch((error) => {
            console.log(error);
          });
        return;
      }
      newLikes?.push(yip.id);
      newLikes &&
        setDoc(
          userRef,
          {
            likes: newLikes,
          },
          { merge: true }
        )
          .then(() => {
            //   console.log("User " + user.displayName + " liked" + yip.id);
          })
          .catch((error) => {
            console.log(error);
          });
      //Create a  notification for the Like
      const notificationLikeData = {
        createdAt: new Date(),
        recipient: yip.owner,
        sender: user.displayName,
        senderId: user.uid,
        senderPhotoURL: user.photoURL ? user.photoURL : <Avatar>{user.displayName.charAt(0)}</Avatar>,
        type: "like",
        read: false,
        yipId: yip.id,
      };
      
      // Check if the document already exists in the notifications collection
      const notificationRef = doc(db, "notifications", yip.owner);
      const notificationDoc = await getDoc(notificationRef);
      
      if (notificationDoc.exists()) {
        // Document exists, update it by appending a new child
        const existingData = notificationDoc.data();
        const updatedNotify = existingData.notifies ? [...existingData.notifies, notificationLikeData] : [notificationLikeData];
        
        await updateDoc(notificationRef, {
          notifies: updatedNotify,
        });
      } else {
        // Document does not exist, create a new notification document
        await setDoc(notificationRef, {
          notifies: [notificationLikeData],
        });
      }
              
    }
    // ---------------------------- Liking a yip END ----------------------------
  };

  const handleOpenComments = () => {
    setCommentModalData(yip);
    setShowComments(true);
  };

  return (
    <Grid
      className="yip-footer"
      container
      item
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      justifySelf={"flex-end"}
      color={"670404"}
      xs={"auto"}
    >
      <Grid
        item
        xs={5}
        // bgcolor={"blue"}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: "1vh",
        }}
      >
        <FavoriteRoundedIcon
          style={{
            height: "min(2.5vh,2.5vw)",
            width: "min(2.5vh,2.5vw)",
            cursor: "pointer",
            color: yip.likes && yip.likes.includes(user.uid) ? "red" : "grey",
          }}
          onClick={handleLikeYip}
        />
        <Typography sx={{ fontSize: "min(1.7vh,1.7vw)" }}>
          {(yip.likes && yip.likes.length) || "0"} Likes
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        // bgcolor={"blue"}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "100%",
          width: "100%",
          // padding: "1vh",
        }}
      >
        <Typography
          sx={{ cursor: "pointer", fontSize: "min(1.7vh,1.7vw)" }}
          onClick={() => {
            handleOpenComments();
          }}
        >
          {yip.comments && yip.comments.length} Comments
        </Typography>
        <AddRoundedIcon
          style={{ color: "670404" }}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleOpenComments();
          }}
        />
        <UnfoldMoreRoundedIcon
          style={{ color: "670404" }}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleOpenComments();
          }}
          p={2}
        />
      </Grid>
    </Grid>
  );
};

export default YipFooter;
