import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { ChatContext } from "../contexts/ChatContext";
import { Grid } from "@mui/material";
import ZoomImageModal from "./ZoomImageModal";
import { Message } from "@mui/icons-material";

const GLChatMessage = ({ glmessage }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const [mediaSrc, setMediaSrc] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [isMediaZoom, setMediaZoom] = useState(false);

  // Define TimestampDisplay component to handle timestamp conversion
  const TimestampDisplay = ({ timestamp }) => {
    // Check if timestamp is defined
    if (!timestamp) return null;

    // Convert Firebase Timestamp to JavaScript Date object
    const dateObject = timestamp.toDate();

    // Get the date and time components
    const date = dateObject.toLocaleDateString(); // Format: MM/DD/YYYY
    const time = dateObject.toLocaleTimeString(); // Format: HH:MM:SS AM/PM

    return (
      <p>
        {date}, {time}
      </p>
    );
  };

  const ref = useRef();
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [glmessage]);

  useEffect(() => {
    setMediaSrcClicked();
  }, [glmessage]); // Only call setMediaSrcClicked when glmessage changes

  const openMedia = (type) => {
    setMediaType(type);
    setMediaZoom(true);
  };

  const closeMedia = () => {
    setMediaZoom(false);
  };

  const handleMediaClick = (type) => {
    openMedia(type);
  };

  const setMediaSrcClicked = () => {
    if (glmessage?.mediaType === "img") {
      setMediaSrc(glmessage.media);
      setMediaType("image");
    } else if (glmessage?.mediaType === "video") {
      setMediaSrc(glmessage.media);
      setMediaType("video");
    } else if (glmessage?.senderId === currentUser?.uid) {
      setMediaSrc(currentUser?.photoURL);
    } else {
      setMediaSrc(data?.ChatUser?.photoURL);
    }
  };

  return (
    <>
      <ZoomImageModal
        open={isMediaZoom}
        handleClose={closeMedia}
        mediaSrc={mediaSrc}
        mediaType={mediaType}
      />
      <Grid
        item
        container
        xs={12}
        className={`glMessage ${
          glmessage?.senderId === currentUser?.uid && "owner"
        }`}
      >
        <Grid item xs={12} className="glMessageInfo">
          {glmessage?.mediaType === "img" && (
            <>
              <img
                onClick={() => handleMediaClick("image")}
                src={mediaSrc}
                alt=""
              />
              {glmessage?.text && <p>{glmessage.text}</p>}
            </>
          )}
          {glmessage?.mediaType === "video" && (
            <video
              onClick={() => handleMediaClick("video")}
              src={mediaSrc}
              muted
              controls
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                objectFit: "scale-down",
                borderRadius: "5px",
              }}
            ></video>
          )}
          <TimestampDisplay timestamp={glmessage?.date} />
        </Grid>
      </Grid>
    </>
  );
};

export default GLChatMessage;
