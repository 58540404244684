import React, { useContext, useState } from "react";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { collection, addDoc, limit, getDocs, where, query } from "firebase/firestore";
import { Box, Grid, TextField, Button } from "@mui/material";
import AlertModal from "./glModals/AlertModal";
import { PhoneInput } from "react-international-phone";

const InvitationListForm = ({
  Open,
  Close,
  modalTitle,
  organizerCode,
  EventAccessCode,
}) => {
  const db = useContext(FirebaseContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstName === "" || lastName === "" || phoneNumber === "") {
      alert("Please fill in all fields");
      return;
    }

    try {
      // Check if the Guest already exists in the "invitationList" collection
      const guestQuery = query(
        collection(db, "invitationList"),
        where("firstName", "==", firstName),
        where("lastName", "==", lastName),
        where("phoneNumber", "==", phoneNumber),
        limit(1)
      );
      const guestSnapshot = await getDocs(guestQuery);
      if (!guestSnapshot.empty) {
        const message =
          "So sorry, your name is already on the invited guests list. Please,contact the event organizer for more information.";
        const title = "Not in the list Error!";
        const severity = "error";

        // Show alert in separate modal
        setAlertMessage(message);
        setAlertTitle(title);
        setAlertSeverity(severity);
        setAlertModalIsOpen(true);

        return; // Exit function
      }

      // Add data to the 'invitationList' collection
      const docRef = await addDoc(collection(db, "invitationList"), {
        firstName,
        lastName,
        phoneNumber,
      });
      console.log("Document written with ID: ", docRef.id);

      // Clear input fields after submission
      setFirstName("");
      setLastName("");
      setPhoneNumber("");

      // Close the modal after submission
      Close();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  if (!Open) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "16%",
        left: "9%",
        transform: "translate(-5%, -5%)",
        bgcolor: "purple", // Set background color to purple
        boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "40px",
        minHeight: "58%",
        color: "#fff", // Set text color to white
      }}
    >
      <Grid
        container
        item
        xs={12}
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <h2
            style={{
              textAlign: "center",
              fontSize: "24px",
              marginBottom: "20px",
              color: "black",
            }}
          >
            {modalTitle}
          </h2>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                  borderWidth: "2px", // Set the border width to make it bold
                },
              }}
              InputProps={{
                sx: {
                  "& .MuiInputBase-input": {
                    fontWeight: "bold", // Set the font weight to bold
                  },
                },
              }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                  borderWidth: "2px", // Set the border width to make it bold
                },
              }}
              InputProps={{
                sx: {
                  "& .MuiInputBase-input": {
                    fontWeight: "bold", // Set the font weight to bold
                  },
                },
              }}
            />
            <PhoneInput
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={Close}>
                  Close
                </Button>
              </Grid>
              <AlertModal
                isOpen={alertModalIsOpen}
                closeModal={() => setAlertModalIsOpen(false)}
                alertMessage={alertMessage}
                alertTitle={alertTitle}
                alertSeverity={alertSeverity}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvitationListForm;

// import React, { useContext, useState } from "react";
// import { FirebaseContext } from "../contexts/FirebaseContext";
// import { collection, addDoc } from "firebase/firestore";
// import { Box, Grid, TextField, Button } from "@mui/material";
// import Draggable from "react-draggable"; // Import Draggable component

// const InvitationListForm = ({
//   Open,
//   Close,
//   modalTitle,
//   organizerCode,
//   EventAccessCode,
// }) => {
//   const db = useContext(FirebaseContext);
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (firstName === "" || lastName === "" || phoneNumber === "") {
//       alert("Please fill in all fields");
//       return;
//     }

//     try {
//       // Add data to the 'invitationList' collection
//       const docRef = await addDoc(collection(db, "invitationList"), {
//         firstName,
//         lastName,
//         phoneNumber,
//       });
//       console.log("Document written with ID: ", docRef.id);

//       // Clear input fields after submission
//       setFirstName("");
//       setLastName("");
//       setPhoneNumber("");

//       // Close the modal after submission
//       Close();
//     } catch (error) {
//       console.error("Error adding document: ", error);
//     }
//   };

//   if (!Open) return null;

//   return (
//     // <Draggable cancel=".cancel-draggable">
//       <Box
//         sx={{
//           position: "absolute",
//           top: "16%",
//           left: "9%",
//           transform: "translate(-5%, -5%)",
//           bgcolor: "purple", // Set background color to purple
//           boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
//           borderRadius: "8px",
//           padding: "40px",
//           minHeight: "58%",
//           color: "#fff", // Set text color to white
//         }}
//       >
//         <Grid
//           container
//           item
//           xs={12}
//           justifyContent="center"
//           alignItems="center"
//           spacing={2}
//           style={{ backgroundColor: "white" }}
//         >
//           <Grid item xs={12} container justifyContent="center">
//             <h2
//               style={{
//                 textAlign: "center",
//                 fontSize: "24px",
//                 marginBottom: "20px",
//                 color: "black",
//               }}
//             >
//               {modalTitle}
//             </h2>
//           </Grid>
//           <Grid item xs={12} container justifyContent="center">
//             <form onSubmit={handleSubmit}>
//               <TextField
//                 label="First Name"
//                 variant="outlined"
//                 fullWidth
//                 value={firstName}
//                 onChange={(e) => setFirstName(e.target.value)}
//                 required
//                 sx={{
//                   marginBottom: "20px",
//                   "& .MuiOutlinedInput-notchedOutline": {
//                     borderColor: "black", // Set the border color to black
//                     borderWidth: "2px", // Set the border width to make it bold
//                   },
//                 }}
//                 InputProps={{
//                   sx: {
//                     "& .MuiInputBase-input": {
//                       fontWeight: "bold", // Set the font weight to bold
//                     },
//                   },
//                 }}
//               />
//               <TextField
//                 label="Last Name"
//                 variant="outlined"
//                 fullWidth
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//                 required
//                 sx={{
//                   marginBottom: "20px",
//                   "& .MuiOutlinedInput-notchedOutline": {
//                     borderColor: "black", // Set the border color to black
//                     borderWidth: "2px", // Set the border width to make it bold
//                   },
//                 }}
//                 InputProps={{
//                   sx: {
//                     "& .MuiInputBase-input": {
//                       fontWeight: "bold", // Set the font weight to bold
//                     },
//                   },
//                 }}
//               />
//               <TextField
//                 label="Phone Number"
//                 variant="outlined"
//                 fullWidth
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 required
//                 sx={{
//                   marginBottom: "20px",
//                   "& .MuiOutlinedInput-notchedOutline": {
//                     borderColor: "black", // Set the border color to black
//                     borderWidth: "2px", // Set the border width to make it bold
//                   },
//                 }}
//                 InputProps={{
//                   sx: {
//                     "& .MuiInputBase-input": {
//                       fontWeight: "bold", // Set the font weight to bold
//                     },
//                   },
//                 }}
//               />
//               <Grid container justifyContent="center" spacing={2}>
//                 <Grid item>
//                   <Button type="submit" variant="contained" color="primary">
//                     Submit
//                   </Button>
//                 </Grid>
//                 <Grid item>
//                   <Button
//                     // className="cancel-draggable"
//                     variant="contained"
//                     color="primary"
//                     onClick={Close}
//                   >
//                     Close
//                   </Button>
//                 </Grid>
//               </Grid>
//             </form>
//           </Grid>
//         </Grid>
//       </Box>
//   );
// };

// export default InvitationListForm;

// import React, { useContext, useState } from 'react';
// import { FirebaseContext } from '../contexts/FirebaseContext';
// import { collection, addDoc } from 'firebase/firestore';
// import { Box, Grid, TextField, Button } from '@mui/material';

// const InvitationListForm = ({ Open, Close, modalTitle, organizerCode,EventAccessCode }) => {
//     const db = useContext(FirebaseContext);
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [phoneNumber, setPhoneNumber] = useState('');

//     // if(organizerCode!== 'OGUGUARALPH2024' && EventAccessCode !== 'OGUGUARALPH2024') {
//     //   alert('Invalid Organizer Code');
//     //   return;
//     // }
//     // console.log('modalTitle12:', modalTitle);
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//       if(firstName === '' || lastName === '' || phoneNumber === '') {
//         alert('Please fill in all fields');
//         return;
//       }

//         try {
//             // Add data to the 'invitationList' collection
//             const docRef = await addDoc(collection(db, 'invitationList'), {
//                 firstName,
//                 lastName,
//                 phoneNumber,
//             });
//             console.log('Document written with ID: ', docRef.id);

//             // Clear input fields after submission
//             setFirstName('');
//             setLastName('');
//             setPhoneNumber('');

//             // Close the modal after submission
//             Close();
//         } catch (error) {
//             console.error('Error adding document: ', error);
//         }
//     };

//     if (!Open) return null;

//     return (
//       <Box
//       sx={{
//           position: 'absolute',
//            top: '16%',
//            left: '9%',
//            transform: 'translate(-5%, -5%)',
//            bgcolor: 'purple', // Set background color to purple
//           boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
//            borderRadius: '8px',
//            padding: '40px',
//            minHeight: '58%',
//         //   maxWidth: '100%',
//           color: '#fff', // Set text color to white
//       }}
//   >
//       <Grid container item xs={12} justifyContent="center" alignItems="center" spacing={2} style={{backgroundColor:"white"}}>
//           <h2 style={{ textAlign: 'center', fontSize: '24px', marginBottom: '30px', color:"black" }}>{modalTitle}</h2>
//           <form onSubmit={handleSubmit}>
//               <TextField
//                   label="First Name"
//                   variant="outlined"
//                   fullWidth
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                   required
//                   sx={{
//                       marginBottom: '20px',
//                       '& .MuiOutlinedInput-notchedOutline': {
//                           borderColor: 'black', // Set the border color to black
//                           borderWidth: '2px', // Set the border width to make it bold
//                       },
//                   }}
//                   InputProps={{
//                       sx: {
//                           '& .MuiInputBase-input': {
//                               fontWeight: 'bold', // Set the font weight to bold
//                           },
//                       },
//                   }}
//               />
//               <TextField
//                   label="Last Name"
//                   variant="outlined"
//                   fullWidth
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                   required
//                   sx={{ marginBottom: '20px',
//                       '& .MuiOutlinedInput-notchedOutline': {
//                           borderColor: 'black', // Set the border color to black
//                           borderWidth: '2px', // Set the border width to make it bold
//                       },
//                   }}
//                   InputProps={{
//                       sx: {
//                           '& .MuiInputBase-input': {
//                               fontWeight: 'bold', // Set the font weight to bold
//                           },
//                       },
//                   }}
//               />
//               <TextField
//                   label="Phone Number"
//                   variant="outlined"
//                   fullWidth
//                   value={phoneNumber}
//                   onChange={(e) => setPhoneNumber(e.target.value)}
//                   required
//                   sx={{ marginBottom: '20px',
//                       '& .MuiOutlinedInput-notchedOutline': {
//                           borderColor: 'black', // Set the border color to black
//                           borderWidth: '2px', // Set the border width to make it bold
//                       },
//                   }}
//                   InputProps={{
//                       sx: {
//                           '& .MuiInputBase-input': {
//                               fontWeight: 'bold', // Set the font weight to bold
//                           },
//                       },
//                   }}
//               />
//               <Grid container justifyContent="center" spacing={2}>
//                   <Grid item>
//                       <Button type="submit" variant="contained" color="primary">
//                           Submit
//                       </Button>
//                   </Grid>
//                   <Grid item>
//                       <Button variant="contained" color="primary" onClick={Close}>
//                           Close
//                       </Button>
//                   </Grid>
//               </Grid>
//           </form>
//       </Grid>
//   </Box>

//     );
// };

// export default InvitationListForm;
