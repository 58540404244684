import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { CountryRegionData } from "react-country-region-selector";
import { AuthContext } from "../contexts/AuthContext";
import {ChatContext } from "../contexts/ChatContext";

const GLChat = (props) => {
  const {userId} = props;
  const { gLChat, setgLChat} =
    useContext(ChatContext);
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);
  const db = useContext(FirebaseContext);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Listen for incoming messages from the database
  
    const messagesRef = db.ref('userChats').orderByChild('id').equalTo(user.uid).orderBy('timestamp', 'desc').limit(10);
    messagesRef.on('value', (snapshot) => {
      const messagesData = snapshot.val();
      if (messagesData) {
        const messagesArray = Object.keys(messagesData).map(key => messagesData[key]);
        setMessages(messagesArray);
      } else {
        setMessages([]);
      }
    });

    // Clean up event listener on unmount
    return () => {
      messagesRef.off('value');
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const sendMessage = () => {
    if (inputValue.trim() !== '') {
      // Push the message to the database
      db.ref('messages').push({ sender: 'user', text: inputValue });
      setInputValue('');
    }
  };

  return (
    <div>
      <div className="chat-window">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.text}
          </div>
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default GLChat;
