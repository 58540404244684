import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import NewsCard from '../components/NewsCard'

const News = () => {
    return (
        <Grid
            container
            display={'flex'}
            direction={"column"}
            xs={12}
            height={'100%'}
            sx={{
                bgcolor: '#670404',
                // height: '140vh',
                flex: 1,
                maxWidth: 'vw',
                minHeight: '100vh',
                // maxHeight: 'vh',

            }}
        >
            <Body />
        </Grid>
    )
}
const Body = () => {
    return (
        <Grid
            container
            item
            xs={8.5}
            direction={"column"}
            display={'flex'}
            minHeight={'95vh'}
            justifyContent={'space-evenly'}
            sx={{
                bgcolor: "#DBA622",
            }}
        >
            <Grid
                container
                item
                xs={0.5}
                // bgcolor={"red"}
                textAlign={'center'}
            >
                <Typography variant='h4' width={"100%"}> Welcome To Galore News </Typography>
            </Grid>
            <Grid
                item
                xs={10.5}
                display={'flex'}
                // bgcolor={"blue"}
                justifyContent={'space-evenly'}
            >
                <Grid
                    container
                    item
                    xs={3.7}
                    display={'flex'}
                    // bgcolor={'green'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                >
                    <NewsCard />
                    <NewsCard />
                </Grid>
                <Grid
                    container
                    item
                    xs={3.7}
                    display={'flex'}
                    // bgcolor={'green'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                >
                    <NewsCard />
                    <NewsCard />
                </Grid>

                <Grid
                    container
                    item
                    xs={3.7}
                    display={'flex'}
                    // bgcolor={'green'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            padding: "10px",
                            borderRadius: '10px',
                            width: '90%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h4'> Breaking News </Typography>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: 'yellow',
                            justifySelf: 'flex-end',
                            padding: '2vh',
                            width: '80%',
                            maxWidth: '95%',
                            height: '90%',
                        }}>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                <Typography variant='h6'> Headline {item} </Typography>
                            ))}
                        </div>

                    </div>

                </Grid>

            </Grid>


        </Grid>
    )
}
export default News