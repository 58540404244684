import { Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import MarketplaceCard from '../components/MarketplaceCard'




const Marketplace = () => {
    return (
        <Grid
            container
            display={'flex'}
            direction={"column"}
            xs={12}
            sx={{
                bgcolor: '#670404',
                flex: 1,
                maxWidth: 'vw',
                minHeight: '100vh',

            }}
        >
            <Body />
        </Grid>
    )
}
const Body = () => {
    return (
        <Grid
            container
            item
            xs={8.5}
            direction={"column"}
            display={'flex'}
            justifyContent={'space-evenly'}
            sx={{
                bgcolor: "#DBA622",
            }}
        >
            <Grid
                // bgcolor={"red"}
                container
                item
                xs={0.5}
                textAlign={'center'}
            >
                <Typography variant='h4' width={"100%"}>
                    Welcome To Galore Marketplace
                </Typography>
            </Grid>
            <Grid
                // bgcolor={"blue"}
                item
                display={'flex'}
                justifyContent={'space-evenly'}
            >
                <div style={{
                    // backgroundColor: 'green',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src="https://picsum.photos/id/292/1000/400"
                        alt='yip-input-profile-pic'
                        style={{
                            height: "100%",
                            width: "100%",
                            maxHeight: "90%",
                            objectFit: "cover",
                        }}
                    />

                </div>

            </Grid>
            <Grid
                // bgcolor={"blue"}
                item
                display={'flex'}
                justifyContent={'space-evenly'}
                height={"50vh"}
            >
                <Grid
                    id={"leftMarketContainer"}
                    // bgcolor={'green'}
                    container
                    item
                    xs={5.5}
                    display={'flex'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <MarketplaceCard />

                </Grid>
                <Grid
                    id={"rightMarketContainer"}
                    // bgcolor={'green'}
                    container
                    item
                    xs={5.5}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <MarketplaceCard />
                </Grid>


            </Grid>


        </Grid>
    )
}
export default Marketplace