// import React, { useState } from "react";
import { Avatar, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";

/******************************************************* */
import { Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router";
import { collection, getDocs } from "@firebase/firestore";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { EditProfileContext } from "../contexts/EditProfileContext";
import { getDownloadURL, getMetadata, list, ref } from "firebase/storage";
import { StorageContext } from "../contexts/StorageContext";

import { AuthContext } from "../contexts/AuthContext";

import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import "../components/ProfileCard.css";
import { signOut } from "firebase/auth";
import { PhotosModalContext } from "../contexts/PhotosModalContext";
import EditProfileModal from "./EditProfileModal";
import PhotosModal from "./PhotosModal";
import dayjs from "dayjs";

import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "10px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const ProfileHead = () => {
  const auth = useContext(AuthContext);
  const [user, setUser] = useContext(UserContext);

  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [profilPicture, setProfilePicture] = useState(null);
  const [showPhotos, setShowPhotos] = useState(false); //useContext(PhotosModalContext);

  const useEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  useEffect(() => {
    const setHeaderInfo = async () => {
      if (user) {
        setProfilePicture(user.photoURL);
        // setUserName(user.displayName);
      }
    };
    setHeaderInfo();
  }, [user]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginLeft: "30px",
      }}
    >
      <PhotosModalContext.Provider value={{ showPhotos, setShowPhotos }}>
        <Grid
          item
          xs={4}
          style={{
            display: "block",
            // flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid item xs={6}>
            <div>
              {/* <Avatar
              src={profilPicture}
              alt="Profile Picture"
              sx={{ width: 80, height: 80 }}
            /> */}

              {auth.currentUser && auth.currentUser.photoURL ? (
                <Avatar
                  src={auth.currentUser.photoURL}
                  alt="profile-pic"
                  sx={{ width: 80, height: 80 }}
                  onClick={() => {
                    setShowPhotos(true);
                  }}
                />
              ) : (
                <AccountCircleRoundedIcon
                  style={{
                    height: "min(20vw, 20vh)",
                    width: "min(20vw, 20vh)",
                    borderRadius: "50%",
                    // backgroundColor: "green",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "min(5vw, 5vh)",
              }}
            >
              {auth.currentUser && auth.currentUser.displayName}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <EventAvailableRoundedIcon
                style={{
                  display: "flex",
                  alignSelf: "center",
                  padding: "min(.8vw, .8vh)",
                  height: "min(2.3vw, 2.3vh)",
                  width: "min(3vw, 33vh)",
                  color: "purple",
                }}
              />
              <Typography
                style={{
                  fontSize: "min(2.5vw, 2.5vh)",
                  fontWeight: "bold",
                }}
              >
                Joined{" "}
                {auth.currentUser &&
                  dayjs(auth.currentUser.metadata.creationTime).format(
                    "MM/DD/YYYY"
                  )}
              </Typography>
            </div>

            <div
              style={{
                 display: "flex",
                 flexDirection: "column",
                // justifyContent: "space-between",
                alignItems: "flex-start",
                // width: "100%",
              }}
            >
              
              <Button
                sx={{
                  fontFamily: "cursive",
                  textTransform: "none",
                  color: "purple",
                }}
                onClick={useEditModal}
              >
                <EditNoteRoundedIcon
                  // Move the call to useEditModal outside of the callback function
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    padding: "min(.8vw, .8vh)",
                    height: "min(3.5vw, 3.5vh)",
                    width: "min(3.5vw, 3.5vh)",
                    cursor: "pointer",
                    color: "blue",
                    // backgroundColor: "green",
                  }}
                />
                <Typography
                  style={{
                    color: "blue",
                    fontFamily: "cursive",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Edit
                </Typography>
              </Button>
              <Button
                sx={{
                  fontFamily: "cursive",
                  textTransform: "none",
                  color: "purple",
                }}
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      setUser(null);
                      console.log("Sign-out successful.");
                      navigate("/");
                    })
                    .catch((error) => {
                      console.log("An error happened.");
                    });
                }}
              >
                <Typography
                  style={{
                    color: "blue",
                    fontFamily: "cursive",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  LogOut
                </Typography>
                <LogoutRoundedIcon
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    height: "min(3vw, 3vh)",
                    width: "min(3vw, 3vh)",
                    // padding: "min(2vw, 2vh)",
                    cursor: "pointer",
                    color: "red",
                  }}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Modal
            open={editModalOpen}
            onClose={useEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid item xs={12} sx={{ display: "flex" }}>
              <EditProfileModal
                onClose={useEditModal}
                modalTitle="Edit Your Profile"
                modalContent="This is the content of Modal 2."
              ></EditProfileModal>
            </Grid>
          </Modal>
          <Modal
            open={showPhotos}
            onClose={setShowPhotos}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <PhotosModal
                onClose={setShowPhotos}
                modalTitle="Photos"
                modalContent="This is the content of Modal 2."
              ></PhotosModal>
            </div>
          </Modal>
        </Grid>
      </PhotosModalContext.Provider>
    </Grid>
  );
};

export default ProfileHead;
