import { sendPasswordResetEmail } from "firebase/auth";
import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function ForgotPassword() {
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [valid, setValid] = useState(false);
	const theme = useContext(ThemeContext);
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const emailRegex = new RegExp(/[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

	return (
		<div
			className="body"
			style={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				backgroundColor: theme.bgColor,
				width: "100%",
				minHeight: "100vh",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div
				className="forgot-password-container"
				style={{
					display: "flex",
					flexDirection: "column",
                    flex: 1,
					width: "100%",
					maxWidth: "50vw",
					maxHeight: "35vh",
					backgroundColor: "white",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "min(2vw, 2vh)",
					borderRadius: "min(2.5vw, 2.5vh)",
				}}
			>
				<div
					className="close-button-container"
					style={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
						justifyContent: "flex-end",
						// background: "red",
					}}
				>
					<div className="close-button">
						<CloseRoundedIcon
							onClick={() => {
								navigate("/login");
							}}
						/>
					</div>
				</div>
				<div
					className="forgot-password-input-container"
					style={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
						justifyContent: "space-between",
						width: "100%",
						maxWidth: "100%",
						// height: "75%",
						// maxHeight: "75%",
						// backgroundColor: "grey",
					}}
				>
					<div
						style={{
							display: "flex",
							flex: 1,
							flexDirection: "column",
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							justifySelf: "center",
							// background: "blue",
						}}
					>
						<label
							style={{
								padding: "min(1vw, 1vh)",
								textAlign: "center",
								fontSize: "min(3.5vw, 3.5vh)",
							}}
						>
							Enter email to recieve password reset link
						</label>
						<input
							placeholder="johndoe@example.com"
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								maxWidth: "min(80%,250px)",
								fontSize: "min(3vw, 3vh)",
							}}
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
						{emailError && emailError != "" && (
							<label
								style={{
									color: "red",
									padding: "min(1vw, 1vh)",
								}}
							>
								{emailError}
							</label>
						)}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							justifyContent: "center",
							alignItems: "center",
							// justifySelf: "flex-end",
							marginRight: "min(3vw, 3vh)",
							// background: "green",
						}}
					>
						<div
							style={{
								display: "flex",
								margin: "min(1vw, 1vh)",
								width: "100%",
								textAlign: "center",
								justifyContent: "center",
								justifySelf: "flex-end",
								fontSize: "min(5vw, 5vh)",
								borderRadius: "min(1vw, 1vh)",
								padding: "min(1vw, 1vh)",
								background: !emailRegex.test(email) ? "lightgrey" : theme.main,
								color: "white",
							}}
							disabled={!emailRegex.test(email)}
							onClick={() => {
								if (email && email != "") {
									sendPasswordResetEmail(auth, email)
										.then(() => {
											setEmailError("Password reset email sent");
										})
										.catch((error) => {
											const errorCode = error.code;
											const errorMessage = error.message;
											console.log(errorCode);
											switch (true) {
												case errorCode == "auth/invalid-email":
													setEmailError("Invalid email");
													break;
												case errorCode == "auth/user-not-found":
													setEmailError("Email does not exist in our system");
													break;
												default:
													setEmailError(errorMessage);
													break;
											}
										});
								}
							}}
						>
							Send email
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
