import { Paper, Typography } from '@mui/material'
import React from 'react'
import "./NewsCard.css"


const NewsCard = () => {
    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '90%',
                padding: '10px',
                height: '45%',
            }}
        >
            <img src="https://picsum.photos/id/594/1000/400"
                alt='news-image-card'
                style={{
                    // height: "min(10vw, 10vh)",
                    // width: "min(10vw, 10vh)",
                    height: "100%",
                    width: "100%",
                    maxHeight: "90%",
                    backgroundColor: "white",
                    objectFit: "cover",
                }}
            />
            <Typography width={"100%"} variant='h5' fontWeight={"bold"}>Sports News 1</Typography>
            <Typography width={"100%"}> Description </Typography>
        </Paper>
    )
}

export default NewsCard