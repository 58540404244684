import React, { useContext, useEffect } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import { Grid, Typography } from "@mui/material";
import YipHeader from "./YipHeader";
import "./Yip.css";
import YipFooter from "./YipFooter";
import YipContent from "./YipContent";
import { YipFeedContext } from "../contexts/YipFeedContext";
// import { StorageContext } from "../contexts/StorageContext";
// import { UserContext } from "../contexts/UserContext";
// import { getDownloadURL, list, ref } from "firebase/storage";

const Yip = (props) => {
  const { yip } = props;
  const [yips, setYips] = useContext(YipFeedContext);
  useEffect(() => {
  }, [yips]);
  return (
    <Grid
      container
      item
      display={"flex"}
      direction={"column"}
      // maxHeight={"85vh"}
      flex={1}
      sx={{
        // flex: 1,
        margin: "min(1vw, 0.5vh)",
        width: "100%",
         backgroundColor: "Pink",
        borderRadius: "10px",
        color: "black",
      }}
    >
      <YipHeader yip={yip} setYips={setYips} />
      <YipContent yip={yip} />
      <YipFooter yip={yip} setYips={setYips} />
    </Grid>
  );
};

export default Yip;
