import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import "./Checkout.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { FirebaseContext } from "../contexts/FirebaseContext";
import {
  getDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "purple",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "bold",
          textAlign: "center",
          backgroundColor: "white",
        },
      },
    },
  },
});

function Checkout() {
  const { width, height } = useWindowSize();
  const [members, setMembers] = useState([]);
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const db = useContext(FirebaseContext);
  const [errors, setErrors] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  const handleGetMembers = async () => {
    let membersRef = collection(db, "users");
    if (gender == "" && age == "") {
      // document.getElementById();
      setErrors(true);
      return;
    }
    if (gender !== "") {
      setErrors(false);
      membersRef = query(membersRef, where("gender", "==", gender));
    }
    if (age !== "") {
      setErrors(false);
      let ageRange = age.split("-");
      let minAge = ageRange[0];
      let maxAge = ageRange[1];
      //   console.log("minAge " + dayjs().subtract(minAge, "year").toDate());
      //   console.log("maxAge " + dayjs().subtract(maxAge, "year").toDate());
      membersRef = query(
        membersRef,
        where("dateOfBirth", ">=", dayjs().subtract(maxAge, "year").toDate()),
        where("dateOfBirth", "<=", dayjs().subtract(minAge, "year").toDate())
      );
    }
    const fullDocSnap = await getDocs(membersRef);
    let members = [];
    fullDocSnap?.forEach((doc) => {
      members.push({ ...doc.data(), id: doc._key.path.segments[6] });
    });
    setMembers(members);
    setChecked(true);
    console.log("Members " + JSON.stringify(members));
  };

  return (
    <Grid
      // container
      item
      xs={12}
      // sm={12}
      // md={6}
      display={"flex"}
      direction={"column"}
      alignContent={"center"}
      // bgcolor={"hotpink"}
    >
      <Grid
        container
        item
        xs={12}
        direction={"column"}
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
        maxHeight={height * 0.85}
        // bgcolor={"green"}
      >
        <div className="checkout-list">
          <div
            className="checkout-button"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleGetMembers();
            }}
          >
            <ThemeProvider theme={theme}>
                <Typography fontWeight={"bold"} fontSize={"100%"}>
                    Select your criteria and click Here to find your Soul Mate
                </Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              // alignItems: "center",
              paddingTop: "min(2vw, 2vh)",
              width: "100%",
            }}
          >
            <FormControl
              error={errors}
              style={{
                display: "flex",
                flexDirection: "column",
                // width: "70%",
                // alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <InputLabel
                id="gender"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#670404",
                    padding: 10,
                  },
                }}
              >
                Gender
              </InputLabel>
              <div className="dropdown">
                <Select
                  name="gender"
                  label="gender"
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  value={gender}
                >
                  {["Male", "Female", "Perfer not to answer"].map(
                    (item, index) => (
                      <MenuItem key={`gender-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
            </FormControl>
            <FormControl
              error={errors}
              style={{
                display: "flex",
                flexDirection: "column",
                // width: "70%",
                flex: 1,
              }}
            >
              <InputLabel id="age">Age</InputLabel>
              <div className="dropdown">
                <Select
                  name="age"
                  label="age"
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  value={age}
                  // sx={{
                  //   "& .MuiInputBase-root": {
                  //     "& fieldset": {
                  //       borderColor: "#670404",
                  //     },
                  //     "&:hover fieldset": {
                  //       borderColor: "#670404",
                  //     },
                  //     "&.Mui-focused fieldset": {
                  //       borderColor: "#670404",
                  //     },
                  //   },
                  // }}
                >
                  {["18-29", "30-45", "46-55", "56-69", "70-100"].map(
                    (item, index) => (
                      <MenuItem key={`age-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
            </FormControl>
          </div>
          {errors ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography color={"red"}> Required </Typography>
            </div>
          ) : (
            <></>
          )}
          <div
            className="other-users-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {checked && members.length == 0 && (
              <Typography
                fontWeight={"bold"}
                fontSize={"100%"}
                color={"#670404"}
              >
                No Users Found
              </Typography>
            )}
            {members.length != 0 &&
              members.map((member) => {
                return <CheckoutItem key={member.firstName} member={member} />;
              })}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const CheckoutItem = (props) => {
  const { member } = props;
  var relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);
  const navigate = useNavigate();
  return (
    <div className="checkout-item">
      <div
        className="checkout-item-header"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "space-around",
        }}
      >
        {member.photoURL ? (
          <img
            src={member.photoURL}
            alt="yip-input-profile-pic"
            style={{
              height: "min(4.9vw, 4.9vh)",
              width: "min(4.9vw, 4.9vh)",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "min(2vw, 2vh)",
              cursor: "pointer",
            }}
            onClick={() => {
              console.log(member);
              navigate(`/user/${member.id}`);
            }}
          />
        ) : (
          <AccountCircleRoundedIcon
            style={{
              height: "min(4.9vw, 4.9vh)",
              width: "min(4.9vw, 4.9vh)",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "min(2vw, 2vh)",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/user/${member.id}`);
            }}
          />
        )}

        <Typography
          fontWeight={"bold"}
          fontSize={"min(3vw,3vh)"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/user/${member.id}`);
          }}
        >
          {member?.nicfirstName}
        </Typography>
      </div>
      <div className="bio">
        <Typography fontWeight={"bold"} sx={{ fontSize: "min(4vw,4vh)" }}>
          Bio
        </Typography>
        <Typography sx={{ fontSize: "min(6vw,6vh)" }}>
          {member?.notes}
        </Typography>
      </div>

      <div className="details-container">
        <Typography fontWeight={"bold"} sx={{ fontSize: "min(6vw,6vh)" }}>
          Details
        </Typography>
        <div
          className="details"
          style={{
            maxWidth: "100%",
          }}
        >
          {member?.dateOfBirth ? (
            <div>
              <Typography id="bold" style={{ fontSize: "13px" }}>
                {" "}
                Age:{" "}
              </Typography>
              {dayjs().diff(dayjs(member?.dateOfBirth.toDate()), "year")}
            </div>
          ) : (
            <></>
          )}
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              Gender:
            </Typography>{" "}
            {member?.gender}{" "}
          </div>
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              Country of Origin:{" "}
            </Typography>{" "}
            {" " + member?.origin}{" "}
          </div>
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              State of Origin:{" "}
            </Typography>{" "}
            {member?.region}{" "}
          </div>
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              Town of Origin:{" "}
            </Typography>{" "}
            {member?.town}{" "}
          </div>
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              Race:
            </Typography>{" "}
            {member?.race}{" "}
          </div>
          <div>
            <Typography id="bold" style={{ fontSize: "13px" }}>
              Occupation:
            </Typography>{" "}
            {member?.occupation}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
