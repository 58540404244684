import React, { useContext } from "react";
import { createTheme, ThemeProvider, Avatar } from "@mui/material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

import "./YipInput.css";
import { PostYipContext } from "../contexts/PostYipContext";
import { AuthContext } from "../contexts/AuthContext";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "purple",
          fontFamily: "cursive",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const NewYipInput = (props) => {
  const { setPostYipModal } = useContext(PostYipContext);
  const auth = useContext(AuthContext);
  return (
    <Grid
      className="yip-input"
      container
      item
      //   bgcolor={"#670404"}
      // color={"white"}
    >
      <ThemeProvider theme={theme}>
        <Grid
          item
          xs={12}
          // bgcolor={"skyblue"}
          display={"flex"}
        //   justifyContent={"center"}
        >
          <Button
            variant="outlined"
            // style={{ textTransform: "none", fontFamily: "cursive" }}
            color="secondary"
            fullWidth
            autoCapitalize="false"
            onClick={() => {
              setPostYipModal(true);
              console.log("mouse up");
            }}
          >
            {/* <span> */}
              {auth.currentUser && auth.currentUser.photoURL ? (
                <Avatar
                  alt="profile-pic"
                  src={auth.currentUser.photoURL}
                  style={{
                    height: "min(8vw, 8vh)",
                    width: "min(8vw, 8vh)",
                    // borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <AccountCircleRoundedIcon
                  style={{
                    height: "min(20vw, 20vh)",
                    width: "min(20vw, 20vh)",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}

              <Typography>Got An Update? Share Here!</Typography>
            {/* </span> */}
          </Button>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};

export default NewYipInput;
