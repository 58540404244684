import * as React from "react";
import Dialog from "@mui/material/Dialog";

export default function ZoomImageModal({ mediaSrc, mediaType, open, handleClose }) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {open && mediaType === 'image' && mediaSrc && (
          <img src={mediaSrc} alt="" 
            style={{ maxWidth: "50%", maxHeight: "50%", objectFit: "contain" }} 
          />
        )}
        {open && mediaType === 'video' && mediaSrc && (
          <video src={mediaSrc} alt="" controls 
            style={{ maxWidth: "50%", maxHeight: "50%", objectFit: "scale-down" }} 
          />
        )}
      </div>
    </Dialog>
  );
}

// import * as React from "react";
// import Dialog from "@mui/material/Dialog";

// export default function ZoomImageModal({ imgSrc, open, handleClose }) {
//   return (
//     <Dialog onClose={handleClose} open={open} 
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         border:"5px"
//       }}
//     >
//       <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//         <img src={imgSrc} alt="" 
//         style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} 
//         />
//       </div>
//     </Dialog>
//   );
// }

// // import * as React from "react";
// // import Dialog from "@mui/material/Dialog";


// // // cj work start here
// // export default function ZoomImageModal({ imgSrc, open, handleClose }) {
// //   return (
// //     <Dialog onClose={handleClose} open={open} 
// //       style={{display: "flex",justifyContent: "center", alignItems: "center"}}
// //     >
// //       <img src={imgSrc} alt="" style={{ width: "50%", objectFit: "scale-down" }} />
// //     </Dialog>
// //   );
// // }

