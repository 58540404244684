import React from "react";

import Home from "../screens/Home";
import Contact from "../screens/Contact";
import Sports from "../screens/Sports";
import About from "../screens/About";
import Marketplace from "../screens/Marketplace";
import Login from "../screens/Login";
import Signup from "../screens/Signup";
import News from "../screens/News";
import ForgotPassword from "../screens/ForgotPassword";
import FriendProfile from "../screens/FriendProfile";
import ResponsiveAppBar from "./ResponsiveAppBar";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import LetsConnet from "../screens/LetsConnet";
import NewFooter from "./NewFooter";
import LatestLaEvents from "../screens/LatestLaEvents";
import GLChatingSystem from "../screens/GLChatingSystem";
import GLChat from "./GLChat";
import MemberSearch from "./MemberSearch";
import Invite from "./Invite";

export const Paths = {
  LETSCONNET: "/letsconnect",
  INVITE: "/invite"
}
const Router = () => {
  const Layout = () => {
    return (
      <div>
        <ResponsiveAppBar />
        <Outlet/>
        <NewFooter/>
      </div>
    );
  };
  const BrowserRoutes = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path:Paths.LETSCONNET,
          element: <LetsConnet/>,
        },
        {
          path:"/membersearch/:searchTerm",
          element: <MemberSearch/>,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/sports",
          element: <Sports />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/marketplace",
          element: <Marketplace />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/signup",
          element: <Signup />,
        },
        {
          path: "/news",
          element: <News />,
        },
        {
          path: "/latestlaevents",
          element: <LatestLaEvents/>,
        },
        {
          path: "/login/forgotpassword",
          element: <ForgotPassword />,
        },
        {
          path: Paths.INVITE,
          element: <Invite />,
        },
        {
          path: "/user/:id",
          element: <FriendProfile />,
        },
        {
          path: "/glchatingsystem",
          element: <GLChatingSystem />,
        },
        {
          path: "/glchat",
          element: <GLChat />,
        },        
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={BrowserRoutes} />
    </div>
  );
};

export default Router;
