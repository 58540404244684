import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";

import NavigateWeb from "./NavigateWeb";
import Logo from "../assets/lovelogoHeart.png";
import NavigateMobil from "./NavigateMobil";
import { Grid } from "@mui/material";

function ResponsiveAppBar() {
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "purple",
        // backgroundColor: "#670404",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavigateWeb />
          <NavigateMobil />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
