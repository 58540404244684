 import "core-js/stable"; // Import the polyfill
import "../App.css";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";

import { getStorage } from "firebase/storage";
import firebaseConfig from './firebaseConfig';

function admin() {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const storage = getStorage(app);
  const sessionIdKey = "sessionID";  

    // Set authentication persistence to 'SESSION' for browser session persistence
    setPersistence(auth, browserSessionPersistence);
  return { app, db, auth, storage, onAuthStateChanged, sessionIdKey }; // Return the parameters as an object
}



 export default admin;


 