import React, {createContext, useReducer} from "react";
import admin from "../util/admin";
import {  } from "@firebase/auth";
import { AuthContext } from "./AuthContext";

export const ChatContext = createContext(AuthContext);
export const ChatContextProvider = ({ children}) => {
    const { auth,  } = admin();

    const INITIAL_STATE = {
        chatId: "null",
        chatUser: {}
    }
    const chatReducer = (state, Action) =>{
        switch(Action.type){
            case "CHANGE_USER":
                return{
                    chatUser:Action.payload,
                    chatId :auth.currentUser.uid > Action.payload.id
                    ?auth.currentUser.uid + Action.payload.id
                    : Action.payload.id + auth.currentUser.uid,
                };
                default:
                    return state;
        }
    }

    const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);
    return (
        <ChatContext.Provider 
        value={{ data:state, dispatch}}
        >
            {children}
        </ChatContext.Provider>
    )
}
