import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import "./YipFeed.css";
import NewYipInput from "./NewYipInput";
import Yip from "./Yip";
import { YipFeedContext } from "../contexts/YipFeedContext";
import { useWindowSize } from "@uidotdev/usehooks";

const YipFeed = (props) => {
  const [yips, setYips] = useContext(YipFeedContext);
  const { width, height } = useWindowSize();

  useEffect(() => {}, [yips]);

  return (
    <Grid
      item
      container
      direction={"column"}
      justifyContent={"center"}
      xs={12}
      sm={12}
      md={6}
    >
      <Grid
        container
        item
        xs={12}
        direction={"column"}
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
        maxHeight={height * 0.85}
      >
        <Grid item container xs={12} className="yip-list">
        <Grid item xs={12} sx={{ border: "4px solid purple" }}>
        <NewYipInput />
        </Grid>
        <Grid item xs={12}>
          {yips &&
            yips.map((yip, index) => {
              return <Yip key={`yip-${index}`} yip={yip} />;
            })}
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YipFeed;
