import React, { useContext, useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import NewYipFeed from "./YipFeed";
import PostYipModal from "./PostYipModal";
import CommentsModal from "./CommentsModal";
import { CommentsModalContext } from "../contexts/CommentsModalContext";
import { PhotosModalContext } from "../contexts/PhotosModalContext";
import { PostYipContext } from "../contexts/PostYipContext";
import PhotosModal from "./PhotosModal";
import { EditProfileContext } from "../contexts/EditProfileContext";
import {
    Button,
    Grid,
    Input,
    List,
    ListItem,
    Modal,
    Typography,
  } from "@mui/material";


const LetsConnetBody = () => {
    const [showPhotos, setShowPhotos] = useState(false);
    const [postYipModal, setPostYipModal] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [commentModalData, setCommentModalData] = useState();
  
    useEffect(() => {
      // console.log(commentModalData);
    }, [commentModalData]);
  
    return (
      <CommentsModalContext.Provider
        value={{
          showComments,
          setShowComments,
          commentModalData,
          setCommentModalData,
        }}
      >
        <PhotosModalContext.Provider value={{ showPhotos, setShowPhotos }}>
          <PostYipContext.Provider value={{ postYipModal, setPostYipModal }}>
            <EditProfileContext.Provider value={[ editProfileModal, setEditProfileModal ]}
            >
              <Grid
                container
                item
                xs={8}
                minHeight={"85vh"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "min(2vh,2vw)",
                  paddingBottom: "min(2vh,2vw)",
                  // background: "red",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "min(2vh,2vw)",
                  }}
                >
                  {/* <OnlineFriends /> */}
  
                  <Grid
                    item
                    container
                    xs={12}
                    display={"flex"}
                    // backgroundColor={"blue"}
                    p={"min(4vh,2vw)"}
                    spacing={"3.5vw"}
                  >
                    <NewYipFeed />
                  </Grid>
                  {showPhotos && <PhotosModal />}
                  <PostYipModal />
                  <CommentsModal />
                </Grid>
              </Grid>
            </EditProfileContext.Provider>
          </PostYipContext.Provider>
        </PhotosModalContext.Provider>
      </CommentsModalContext.Provider>
    );
  };

  export default LetsConnetBody;