import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  TextField,
  Modal,
  InputLabel,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { purple } from "@mui/material/colors";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { customAlphabet } from "nanoid";
import { CountryRegionData } from "react-country-region-selector";
import { Formik } from "formik";
import * as Yup from "yup";
import "./EditProfileModal.css";
import { AuthContext } from "../contexts/AuthContext";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { UserContext } from "../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().min(2).max(50),
  middleName: Yup.string().max(50),
  lastName: Yup.string().min(2).max(50),
  residence: Yup.string().min(2).max(50),
  residenceRegion: Yup.string().min(2).max(50),
  town: Yup.string().min(2).max(50),
  residingTown: Yup.string().min(2),
  race: Yup.string().min(2).max(50),
  maritalStatus: Yup.string().min(2).max(50),
  gender: Yup.string().min(2).max(50),
  occupation: Yup.string().min(2).max(50),
  notes: Yup.string().max(250),
});

const EditProfileModal = ({ onClose, modalTitle, modalContent }) => {
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);
  const db = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [residence, setResidence] = useState("");
  const [initializing, setInitializing] = useState();
  const [initialValues, setInitialValues] = useState();
  const [editProfileModal, setEditProfileModal] = useState(true);
 const  [genderMap, setGenderMap] = useState(["Male", "Female", "Prefer not to answer"])
 const [maritalStatusMap, setMaritalStatusMap] = useState(["Married","Single","Prefer not to answer",])
  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "purple",
            fontFamily: "cursive",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          },
        },
      },
    },
  });

  useEffect(() => {
    const getUserData = async () => {
      setInitializing(true);
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const newData = {
            firstName: docSnap.data()?.firstName || "",
            middleName: docSnap.data()?.middleName || "",
            lastName: docSnap.data()?.lastName || "",
            residence: docSnap.data()?.residence || "",
            residenceRegion: docSnap.data()?.residenceRegion || "",
            town: docSnap.data()?.town || "",
            residingTown: docSnap.data()?.residingTown || "",
            race: docSnap.data()?.race || "",
            maritalStatus: docSnap.data()?.maritalStatus || "",
            gender: docSnap.data()?.gender || "",
            occupation: docSnap.data()?.occupation || "",
            notes: docSnap.data()?.notes || "",
          };
          setInitialValues(newData);
          if (newData.residence) {
            const regionData = CountryRegionData.find(
              (data) => data[0] === newData.residence
            );
            setResidence(regionData);
          }
        }
      }
      setInitializing(false);
    };

    if (editProfileModal) {
      getUserData();
    }
  }, [editProfileModal, user, db]);

  const handleSubmit = async (values) => {
    const docRef = doc(db, "users", user.uid);
    Object.keys(values).forEach(
      (key) => values[key] === "" && delete values[key]
    );
    try {
      await setDoc(docRef, values, { merge: true });
      setUser(auth.currentUser);
      setEditProfileModal(false);
      setResidence("");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  if (initializing) {
    return (
      <Modal open={initializing}>
        <Typography>Loading... Please, login</Typography>
      </Modal>
    );
  }

  return (
    <Box sx={{ height: "80px" }}>
      {/* Set maximum height */}
      <Grid container justifyContent="center" alignItems="center">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            overflow: "auto",
            // maxWidth: "80vw", // Adjust maximum width for responsiveness
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              variant="h6"
              id="modal-modal-title"
              style={{
                marginBottom: "10px",
                textAlign: "center",
                height: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {modalTitle}
              <span>
                <Button onClick={onClose} sx={{ mt: 2, position: "absolute" }}>
                  Close
                </Button>
              </span>
            </Typography>
          </ThemeProvider>
          <Typography
            variant="body1"
            id="modal-modal-description"
            style={{ textAlign: "center" }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2} // Add gap between grid items
              sx={{ height: "80vh", overflow: "scroll" }}
            >
              {initialValues && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid container spacing={2} marginTop={"2px"}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="firstName"
                            label="First Name"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.firstName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="middleName"
                            label="Middle Name"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.middleName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="lastName"
                            label="Last Name"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.lastName}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputLabel id="residence" sx={{fontSize:"12px"}}>
                            Residing Country
                          </InputLabel>
                          <div className="dropdown">
                            <Select
                              name="residence"
                              id="residence"
                              label="Residing Country"
                              value={
                                residence === ""
                                  ? initialValues.residence
                                  : residence
                              }
                              onChange={(e) => {
                                setResidence(e.target.value);
                                props.setFieldValue(
                                  "residence",
                                  e.target.value[0]
                                );
                                props.setFieldValue("residenceRegion", "");
                              }}
                              onBlur={props.handleBlur}
                              fullWidth
                            >
                              {CountryRegionData && CountryRegionData.map((item, index) => (
                                <MenuItem
                                  key={`residence-country-${index}`}
                                  value={item}
                                >
                                  {item[0]}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography variant="body2" color="error">
                              {props.touched.residence
                                ? props.errors.residence
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <InputLabel id="residenceRegion" sx={{fontSize:"12px"}}>Region</InputLabel>
                          <div className="dropdown">
                            <Select
                              name="residenceRegion"
                              id="residenceRegion"
                              label="Region"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.residenceRegion}
                              fullWidth
                            >
                              {residence &&  residence[2] &&
                                residence[2].split("|").map((item, index) => (
                                  <MenuItem
                                    key={`residence-region-${index}`}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                            <Typography variant="body2" color="error">
                              {props.touched.residenceRegion
                                ? props.errors.residenceRegion
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} marginTop={"20px"}>
                          <TextField
                            id="residingTown"
                            label="Residing Town"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.residingTown}
                            fullWidth
                            // sx={{fontSize: "bold"}}
                          />
                        </Grid>                        
                        {/* <Grid item xs={12} sm={4}>
                          <TextField
                            id="town"
                            label="Town"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.town}
                            fullWidth
                          />
                        </Grid> */}
                      </Grid>
                      <Grid container spacing={2} marginTop={"2px"}>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="race"
                            label="Race"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.race}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel id="maritalStatus" sx={{fontSize:"12px"}}>
                            Marital Status
                          </InputLabel>
                          <div className="dropdown">
                            <Select
                              name="maritalStatus"
                              label="Marital Status"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.maritalStatus}
                              fullWidth
                            >
                              {maritalStatusMap && maritalStatusMap.map((item, index) => (
                                <MenuItem
                                  key={`marital-status-${index}`}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography variant="body2" color="error">
                              {props.touched.maritalStatus
                                ? props.errors.maritalStatus
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputLabel id="gender">Gender</InputLabel>
                          <div className="dropdown">
                            <Select
                              id="gender"
                              name="gender"
                              label="Gender"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.gender}
                              fullWidth
                            >
                              {genderMap && genderMap.map(
                                (item, index) => (
                                  <MenuItem
                                    key={`gender-${index}`}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <Typography variant="body2" color="error">
                              {props.touched.gender ? props.errors.gender : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={"2px"}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="occupation"
                            label="Occupation"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.occupation}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="notes"
                            label="Notes"
                            multiline
                            rows={4}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.notes}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!props.isValid || !props.dirty}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>
              )}
            </Grid>
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default EditProfileModal;
