import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Modal,
} from "@mui/material";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useEffect, useState } from "react";

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as Yup from "yup";
import Logo from "../assets/love_icon.png";
import "./Signup.css";

// Import Swiper styles
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Formik } from "formik";
import formikInitialVaues from "../assets/formikInitialValues.json";
import { FirebaseContext } from "../contexts/FirebaseContext";
import {
  Timestamp,
  collection,
  doc,
  setDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { CountryRegionData } from "react-country-region-selector";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SignupContext } from "../contexts/SignupContext";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import ServiceLetterAgree from "../components/glModals/ServiceLetterAgree";

export const Signup = () => {
  const [dateValue, setDateValue] = useState();

  return (
    <Grid
      id="HomeContainer"
      container
      direction={"column"}
      // bgcolor={'#670404'}
      //   xs={12}
      display={"flex"}
      // bgcolor={"red"}
      sx={{
        alignItems: "center",
        height: "80%",
        maxWidth: "80vw",
        minHeight: "vh",
        overflowY: "scroll",
        borderRadius: "10px",
        "::-webkit-scrollbar": {
          backgroundColor: "white",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          marginRight: "10px",
          // alignTracks: "center",
        },
        "::-webkit-scrollbar-track-piece": {
          backgroundColor: "white",
          // width: "10px",
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Body dateValue={dateValue} setDateValue={setDateValue} />
      </LocalizationProvider>
    </Grid>
  );
};

const oneCapitalExp = RegExp(/(?=.*[A-Z])[A-Za-z\d@$!%*#?&\_\|\\]{8,}$/g);
const oneSymbolExp = RegExp(
  /(?=.*[@$!%*#?&\_\|\\])[A-Za-z\d@$!%*#?&\_\|\\]{8,}$/g
);
const oneNumberExp = RegExp(/(?=.*[\d])[A-Za-z\d@$!%*#?&\_\|\\]{8,}$/g);
const phoneNumberExp = RegExp(
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g
);

const Body = (props) => {
  const { dateValue, setDateValue } = props;
  const auth = useContext(AuthContext);
  const db = useContext(FirebaseContext);
  const [signup, setSignup] = useContext(SignupContext);
  // const [user, setUser] = useContext(UserContext)
  const navigate = useNavigate();
  const [origin, setOrigin] = useState(null);
  const [residence, setResidence] = useState(null);
  const [agree, setAgree] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [openServiceLetterModal, setOpenServiceLetterModal] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .min(5, "Too Short!")
      .max(50, "Too Long!")
      .test("used-email", "Email already exists", async (e) => {
        const q = query(collection(db, "users"), where("email", "==", e));
        const querySnapshot = await getDocs(q);
        return querySnapshot.empty;
      })
      .required("Required"),
    password: Yup.string()
      .min(8, "Must be at least 8 characters long")
      .matches(oneCapitalExp, "Requires at least 1 capital")
      .matches(oneSymbolExp, "Requires at least 1 symbol")
      .matches(oneNumberExp, "Requires at least 1 number")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords Must Match")
      .required("Required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    middleName: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    nickname: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    phone: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(phoneNumberExp, "Must be a valid phone number")
      .test("used-phone", "Phone Number already exists", async (e) => {
        const q = query(collection(db, "users"), where("phone", "==", e));
        const querySnapshot = await getDocs(q);
        return querySnapshot.empty;
      })
      .required("Required"),
    height: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    origin: Yup.string().required("Required"),
    region: Yup.string().required("Required"),
    residence: Yup.string().required("Required"),
    residenceRegion: Yup.string().required("Required"),
    town: Yup.string().required("Required"),
    residingTown: Yup.string().required("Required"),
    race: Yup.string().required("Required"),
    maritalStatus: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    occupation: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required("Required"),
    // notes: Yup.string()
    //     .required("Required"),
  });
  // const handleRecaptchaVerify = (e) => {
  //   e.preventDefault();
  //   grecaptcha.enterprise.ready(async () => {
  //     const token = await grecaptcha.enterprise.execute('6LcjiNApAAAAADskkg_AbDzGc0IRVT-vYs8nBhTU', {action: 'LOGIN'});
  //   });
  //   // Perform reCAPTCHA verification here
  //   // Once verified, set agree to true
  //   setAgree(true);
  // };

  const openServiceModal = () => {
    // setSignup(false);
    setOpenServiceLetterModal(true);
  };

  const handleSubmit = async (values) => {
    //document.getElementById("signup-form").submit();
    await createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (credentials) => {
        const randomPhotoId = Math.floor(Math.random() * 1000);
        updateProfile(credentials.user, {
          displayName: values.firstName,
          photoURL: `https://picsum.photos/id/${randomPhotoId}/00/`,
          phoneNumber: values.phone,
        }).then(async () => {
          const docRef = doc(db, "users", credentials.user.uid);
          await setDoc(docRef, values)
            .then(() => {
              console.log("User Creted in firestore with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding user to firestore: ", error);
            });
          await setDoc(doc(db, "userChats", credentials.user.uid), {}) //Creating Chat information on signup.
            .then(() => {
              console.log("userChat Created in firestore with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error creating userChat to firestore: ", error);
            });
          navigate("/letsconnect");
          console.log("Created user and Profile Updated");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClose = () => {
    setOpenServiceLetterModal(false);
    // setSignup(false);
  };
  return (
    <Grid
      container
      item
      sx={12}
      bgcolor={"white"}
      display={"flex"}
      direction={"column"}
      width={1}
    >
      <Grid
        container
        item
        xs={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          // bgcolor={"red"}
          container
          item
          xs={11}
          direction={"column"}
          display={"flex"}
          justifyContent={"space-evenly"}
          gap={0.5}
        >
          <Grid
            // bgcolor={'red'}
            item
            display={"flex"}
            alignItems={"center"}
            p={1}
            style={{
              flexDirection: "row",
            }}
          >
            <img
              src={Logo}
              style={{
                height: "min(10vw, 10vh)",
                borderRadius: "10px",
                padding: "min(0.4vw, 0.4vh)",
                backgroundColor: "#670404",
                objectFit: "contain",
              }}
              alt=""
            />
            <Typography p={2} variant="h4" width={"100%"}>
              Sign Up
            </Typography>
            <CloseRoundedIcon
              onClick={() => {
                setSignup(false);
              }}
            />
          </Grid>
          <Formik
            id="signup-form"
            initialValues={formikInitialVaues}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <>
                {/* <Modal
									open={signupOpen}
									onClose={() => {
										setSignupOpen(false);
									}}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											maxWidth: "750px",
											minHeight: "200px",
											width: "50%",
											// height: "50%",
											borderRadius: "min(5vw, 5vh)",
											padding: "min(2vw, 2vh)",
											backgroundColor: "white",
										}}
									>
										<PayPalScriptProvider
											options={{
												clientId:
													"AWgw5GJP9867jIxSTkqHgdWVNgfjRykUFeJc1-YRts3fzmRigfqHfGdU8ScMr1u2hdc2lxGQOnhDgfZq",
												//  "AX3VqsjMm8AQmGiq_X3ZzAEF5d_SptCzE4UQnjk6z4JW3aekwhHy8Fak1B_9X6kK0j93djJ7nrrg1mar", // test
												vault: true,
												components: "buttons",
												intent: "subscription",
												currency: "USD",
											}}
										>
											<Typography
												sx={{
													textAlign: "center",
													fontWeight: "800",
													fontSize: "min(3vw, 3vh)",
													color: "red",
												}}
											>
												Please, pay a $50 registration fee and $5 monthly
												service charge
											</Typography>
											<PayPalButtons
												createSubscription={(data, actions) => {
													return (
														actions.subscription
															// .revise()
															.create({
																plan_id: "P-6VB03995AP972225CMVPKCFY",
																// plan_id: "P-76E94193TF526605KMVPLSDI", // test
															})
													);
												}}
												onApprove={async (data, actions) => {
													alert(data.subscriptionID);
													props.handleSubmit(props.values);
												}}
											></PayPalButtons>
										</PayPalScriptProvider>
									</div>
								</Modal> */}
                <Grid
                  container
                  item
                  // bgcolor={"yellow"}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      type="email"
                      id="email"
                      placeholder="Email"
                      label="Email"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.email ? props.errors.email : ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      type="password"
                      id="password"
                      placeholder="Password"
                      label="Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.password ? props.errors.password : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.confirmPassword}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.confirmPassword
                        ? props.errors.confirmPassword
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // xs={1}
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="firstName"
                      placeholder="First Name"
                      label="First Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.firstName}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.firstName ? props.errors.firstName : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="middleName"
                      placeholder="Middle Name"
                      label="Middle Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.middleName}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.middleName ? props.errors.middleName : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.lastName}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.lastName ? props.errors.lastName : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // xs={1}
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="nickname"
                      placeholder="Nickname, oneword, please"
                      label="Nickname"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.nickname}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.nickname ? props.errors.nickname : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    {/* <div> */}
                    <PhoneInput
                      id="phone"
                      name="Mobile Phone"
                      value={props.values.phone}
                      onChange={(phone) => {
                        // Set the complete phone number to props.values.phone
                        props.setFieldValue("phone", phone);
                        // Log the phone number and props.values.phone for debugging
                        console.log(
                          "phone",
                          phone,
                          "props.values.phone",
                          props.values.phone
                        );
                      }}
                      onBlur={props.handleBlur}
                      style={{
                        width: "100%",
                        padding: "12px 14px", // Adjust padding to match TextField
                        boxSizing: "border-box", // Ensure padding is included in the width
                      }}
                    />
                    <Typography className="error">
                      {props.touched.phone ? props.errors.phone : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"} lg={4}>
                    <TextField
                      id="height"
                      placeholder="Height"
                      label="Height"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.height}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.height ? props.errors.height : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // xs={1}
                  // bgcolor={"yellow"}
                  display={"flex"}
                  direction={"row"}
                  // justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={origin ? 6 : 12}
                    display={"flex"}
                  >
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                        width: "100%",
                        // justifyContent: 'space-evenly',
                      }}
                    >
                      <InputLabel id="origin">Country of Origin</InputLabel>
                      <div className="dropdown">
                        <Select
                          name="origin"
                          id="origin"
                          label="origin"
                          value={origin ? origin : ""}
                          onChange={(e) => {
                            props.setFieldValue("origin", e.target.value[0]);
                            props.setFieldValue("region", "");
                            setOrigin(e.target.value);
                          }}
                          onBlur={props.handleBlur}
                        >
                          {CountryRegionData.map((item, index) => (
                            <MenuItem value={item}>{item[0]}</MenuItem>
                          ))}
                        </Select>
                        <Typography className="error">
                          {props.touched.origin ? props.errors.origin : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item display={"flex"} xs={12} sm={12} md={6}>
                    {origin && (
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                          width: "100%",

                          // justifyContent: 'space-evenly',
                        }}
                      >
                        <InputLabel id="region">Region</InputLabel>
                        <div className="dropdown">
                          <Select
                            name="region"
                            id="region"
                            label="region"
                            value={
                              props.values.region ? props.values.region : ""
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          >
                            {origin &&
                              origin[2]
                                .split("|")
                                .map((item, index) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                          <Typography className="error">
                            {props.touched.region ? props.errors.region : ""}
                          </Typography>
                        </div>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // xs={1}
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={residence ? 6 : 12}
                    display={"flex"}
                  >
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <InputLabel id="residence">Residence</InputLabel>
                      <div className="dropdown">
                        <Select
                          name="residence"
                          id="residence"
                          label="residence"
                          value={residence ? residence : ""}
                          onChange={(e) => {
                            props.setFieldValue("residence", e.target.value[0]);
                            props.setFieldValue("residenceRegion", "");
                            setResidence(e.target.value);
                          }}
                          onBlur={props.handleBlur}
                        >
                          {CountryRegionData.map((item, index) => (
                            <MenuItem value={item}>{item[0]}</MenuItem>
                          ))}
                        </Select>
                        <Typography className="error">
                          {props.touched.residence
                            ? props.errors.residence
                            : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} display={"flex"}>
                    {residence && (
                      <FormControl
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: 'space-evenly',
                          width: "100%",
                        }}
                      >
                        <InputLabel id="residenceRegion">Region</InputLabel>
                        <div className="dropdown">
                          <Select
                            name="residenceRegion"
                            id="residenceRegion"
                            label="residenceRegion"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.residenceRegion}
                            style={{
                              width: "100%",
                            }}
                          >
                            {residence &&
                              residence[2]
                                .split("|")
                                .map((item, index) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                          <Typography className="error">
                            {props.touched.residenceRegion
                              ? props.errors.residenceRegion
                              : ""}
                          </Typography>
                        </div>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid item xs={12} sm={12} md={"auto"}>
                    <TextField
                      id="town"
                      placeholder="Town of Origin"
                      label="Town of Origin"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.town}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.town ? props.errors.town : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"}>
                    <TextField
                      id="residingTown"
                      placeholder="Resident Town/City"
                      label="Resident Town/City"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.residingTown}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.residingTown
                        ? props.errors.residingTown
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={"auto"}>
                    <TextField
                      id="race"
                      placeholder="Race"
                      label="Race"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.race}
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography className="error">
                      {props.touched.race ? props.errors.race : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  // bgcolor={'yellow'}
                  container
                  item
                  // xs={1}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <Grid item xs={12} sm={12} md={3} display={"flex"}>
                    <FormControl
                      name="dateOfBirth"
                      id="dateOfBirth"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                      onBlur={(e) => {
                        if (dateValue == undefined || dateValue === "") {
                          props.touched.dateOfBirth = true;
                          return props.setFieldError("dateOfBirth", "Required");
                        }
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <InputLabel id="Date of Birth">Date of Birth</InputLabel> */}
                        <DatePicker
                          label={"Date of Birth"}
                          //   value={dateValue}
                          onChange={(newValue) => {
                            props.setFieldValue(
                              "dateOfBirth",
                              Timestamp.fromMillis(Date.parse(newValue["$d"]))
                            );
                            setDateValue(newValue);
                            console.log(
                              Timestamp.fromMillis(Date.parse(newValue["$d"]))
                            );
                          }}
                          sx={{
                            width: "100%",
                          }}
                        />
                        <Typography className="error">
                          {props.touched.dateOfBirth
                            ? props.errors.dateOfBirth
                            : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} display={"flex"}>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <InputLabel id="maritalStatus">Marital Status</InputLabel>
                      <div className="dropdown">
                        <Select
                          name="maritalStatus"
                          label="maritalStatus"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.maritalStatus}
                          style={{
                            width: "100%",
                          }}
                        >
                          {["Married", "Single", "Perfer not to answer"].map(
                            (item, index) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            )
                          )}
                        </Select>
                        <Typography className="error">
                          {props.touched.maritalStatus
                            ? props.errors.maritalStatus
                            : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} display={"flex"}>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <InputLabel id="gender">Gender</InputLabel>
                      <div className="dropdown">
                        <Select
                          name="gender"
                          label="gender"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.gender}
                          style={{
                            width: "100%",
                          }}
                        >
                          {["Male", "Female", "Perfer not to answer"].map(
                            (item, index) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            )
                          )}
                        </Select>
                        <Typography className="error">
                          {props.touched.gender ? props.errors.gender : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} display={"flex"}>
                    <FormControl
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div className="dropdown">
                        <TextField
                          name="occupation"
                          id="occupation"
                          label="Occupation"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.occupation}
                          style={{
                            width: "100%",
                          }}
                        />
                        <Typography className="error">
                          {props.touched.occupation
                            ? props.errors.occupation
                            : ""}
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={1}
                >
                  <TextField id="Notes" placeholder="Notes" fullWidth />
                </Grid>
                <Grid
                  container
                  item
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={2}
                >
                  <FormControl
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // width: "22%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        id="agree"
                        value={agree}
                        disabled={!props.isValid || !props.dirty}
                        onChange={(e) => {
                          setAgree(e.target.checked);
                        }}
                      />
                      <Typography>
                        Agree:{" "}
                        <span>
                          By submitting this form you agree to the terms of the
                          <button
                            onClick={openServiceModal}
                            style={{
                              textDecoration: "underline",
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                            }}
                          >
                            Privacy Policy And Service Letter Agreement
                          </button>
                        </span>
                      </Typography>
                    </div>
                  </FormControl>
                  {/* <ServiceLetterAgree
                      isOpen={openServiceLetterModal}
                      closeModal={() => setOpenServiceLetterModal(false)}
                      style={{
                        zIndex: 999, // Set higher z-index for the modal
                      }}
                    /> */}
                </Grid>
                <Grid
                  item
                  // bgcolor={'yellow'}
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"space-evenly"}
                  p={2}
                >
                  <Typography>
                    Already have an account? <Link to={"/login"}>Login</Link>
                  </Typography>
                  {/* Add reCAPTCHA */}
                  {/* <div
                    className="g-recaptcha"
                    data-sitekey="***_AbDzGc0IRVT-vYs8nBhTU"
                    data-callback={handleSubmit}
                    data-action="submit"
                    style={{ marginBottom: "20px" }}
                  /> */}
                  <Button
                    //type="submit"
                    variant="contained"
                    disabled={!props.isValid || !props.dirty || !agree}
                    onClick={() => {
                      // setSignupOpen(true);
                      props.handleSubmit(props.values);
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Modal
          open={openServiceLetterModal}
          Close={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* {openServiceLetterModal &&( */}

          <ServiceLetterAgree
            className="service-letter-agree-modal"
            Open={openServiceLetterModal}
            Close={() => setOpenServiceLetterModal(false)}
            modalTitle={"Service Letter and Privacy Agreement"}
            odalContent={
              "This is the content of the modal. You can put any component you want in here."
            }
          />
          {/* )} */}
        </Modal>
      </Grid>
    </Grid>
  );
};

export default Signup;
