import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import pages from "./RoutData";
import Menu from "@mui/material/Menu";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Key } from "@mui/icons-material";
// import Logo from "../assets/lovelogoHeart.png";
import Logo from "../assets/MobileWebLogo4.gif";
const NavigateWeb = () => {
  return (
    <Box
      sx={{
         flexGrow: 1, display: { xs: "none", sm: "flex" }
        // display: "flex",
        // alignItems: "center",
      }}
    >
      <img
        src={Logo}
        onClick={() => window.location.replace("/")}
        alt="Logo"
        style={{
          // maxHeight: "50px",
          // cursor: "pointer",
          // alignSelf: "flex-start",
          cursor: "pointer",
          marginRight: "10px", // Adjust margin as needed
          alignSelf: "flex-start",
          maxHeight: "50px",
        }}
      />
      {pages.map((page, index) => {
        return <Bmenu key={page.id} page={page} />;
      })}
    </Box>
  );
};

export default NavigateWeb;

const Bmenu = (props) => {
  let { page } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  let autoPageNames = pages.map((page) => page.name);

  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigateTo1 = (link, type) => {
    console.log("Link to Naigate to", link);
    if (type === "external") {
      return window.open(link, "_blank");
    } else if (type === "internal") {
      navigate(link);
    }
    handleClose();
  };

  const handleNavigateTo = (page) => {
    console.log("Link to Naigate to", page.link);
    if (page.type === "external") {
      return window.open(page.link, "_blank");
    } else if (page.type === "internal") {
      navigate(page.link);
    }
    handleClose();
  };

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={2}>
        <Button
          key={page.id}
          onClick={() => {
            if (
              [
                "Socials",
                "Sports Highlights",
                "Latest Happenings",
                "You'r Invited",
              ].includes(page.name)
            ) {
              {
                handleNavigateTo(page);
              }
            } else {
              if (user) {
                auth.signOut().then(() => {
                  setUser(null);
                  navigate("/");
                });
              } else {
                navigate("/login");
              }
            }
          }}
          style={{
            color: "white",
            fontFamily: "cursive",
            textTransform: "none",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {[
            "Socials",
            "Sports Highlights",
            "Latest Happenings",
            "You'r Invited",
          ].includes(page.name)
            ? page.name
            : user
            ? "Logout"
            : "Login"}
        </Button>
      </Grid>
    </Grid>
  );
};
