import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import dayjs from "dayjs";
import { FirebaseContext } from "../contexts/FirebaseContext";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useNavigate } from "react-router-dom";
import { YipFeedContext } from "../contexts/YipFeedContext";

const YipHeader = (props) => {
	const { yip, setYips } = props;
	const [yips] = useContext(YipFeedContext);
	const [ownerData, setOwnerData] = useState();
	const [user] = useContext(UserContext);
	const db = useContext(FirebaseContext);
	const navigate = useNavigate();

	useEffect(() => {
		// console.log(yip);
		const handleGetYipOwnerData = async () => {
			const docRef = doc(db, "users", yip.owner);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setOwnerData(docSnap.data());
				// console.log("Document data:", docSnap.data());
			}
		};
		handleGetYipOwnerData();

		return () => {
			// cleanup
			setOwnerData();
		};
	}, [yip.owner]);

	const handleDeleteYip = async () => {
		const docRef = doc(db, "yips", yip.id);
		if (user.uid !== yip.owner) {
			return;
		}
		await deleteDoc(docRef)
			.then(() => {
				console.log("Document successfully deleted!");
				setYips((prevYips) =>
					prevYips.filter((prevYip) => {
						return prevYip.id !== yip.id;
					})
				);
			})
			.catch((error) => {
				console.error("Error removing document: ", error);
			});
	};

	return (
		<Grid
			container
			item
			xs={"auto"}
			display={"flex"}
			direction={"column"}
			sx={{
				// alignItems: "center",
				justifyContent: "space-between",
				// bgcolor: "purple",
				color: "#670404",
				borderRadius: "10px",
			}}
		>
			<Grid
				container
				item
				xs={12}
				// bgcolor={"black"}
				display={"flex"}
				direction={"row"}
				// height={1}
			>
				<Grid
					container
					item
					xs={2.5}
					// bgcolor={"yellow"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					p={"min(.8vw, .8vh)"}
				>
					{ownerData && ownerData.photoURL ? (
						<img
							src={ownerData.photoURL}
							alt="yip-input-profile-pic"
							style={{
								height: "min(6.9svw, 6.9svh)",
								width: "min(6.9svw, 6.9svh)",
								borderRadius: "50%",
								backgroundColor: "brown",
								cursor: "pointer",
								objectFit: "cover",
							}}
							onClick={() => {
								navigate(`/user/${yip.owner}`);
							}}
						/>
					) : (
						<AccountCircleRoundedIcon
							style={{
								height: "min(4.9vw, 4.9vh)",
								width: "min(4.9vw, 4.9vh)",
								borderRadius: "50%",
								cursor: "pointer",
								objectFit: "cover",
							}}
							onClick={() => {
								navigate(`/user/${yip.owner}`);
							}}
						/>
					)}
				</Grid>
				<Grid
					container
					item
					xs={8}
					// bgcolor={"blue"}
					display={"flex"}
					direction={"column"}
					justifyContent={"center"}
				>
					<Grid
						item
						// bgcolor={"yellow"}
						display={"flex"}
						alignItems={"center"}
					>
						<div>
							<Typography
								fontSize={"min(3.5svh,3.5svw)"}
								fontWeight={"bold"}
								sx={{ cursor: "pointer" }}
								onClick={() => {
									navigate(`/user/${yip.owner}`);
								}}
							>
								{ownerData ? ownerData?.firstName : "Deleted User"}
							</Typography>
							<Typography fontSize={"min(2svh,2svw)"}>
								{yip &&
									dayjs(yip.timestamp, "MM-DD-YYYY HH:mm:ss").format(
										"h:mm a, MMMM DD YYYY"
									)}
							</Typography>
						</div>
					</Grid>
				</Grid>
				{user && user.uid === yip.owner && (
					<Grid
						container
						item
						xs={1.5}
						// bgcolor={"green"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<DeleteRoundedIcon
							onClick={handleDeleteYip}
							style={{ cursor: "pointer" }}
						/>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default YipHeader;
