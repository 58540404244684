import { Button, Grid, TextField, Typography, Modal, FormGroup } from "@mui/material";
import React, { useContext, useEffect, useState, createContext } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { styled } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../assets/love_icon.png";
import CarouselData from "../assets/carouselImages";
import Signup from "./Signup";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";
import { SignupContext } from "../contexts/SignupContext";
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import Carousel from "../components/Carousel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {createTheme} from "@mui/material"; 
import { Style } from "@mui/icons-material";
import { color } from "@mui/system";

export const Login = () => {
  const [user, setUser] = useContext(UserContext);
  const [signup, setSignup] = useState(false);
  const auth = useContext(AuthContext);
  const [initializing, setInitializing] = useState(true);
  // const navigate = useNavigate()

  useEffect(() => {
    // if (user) {
    //     console.log(user);
    //     navigate('/letsconnect')
    // }
    // console.log(user);

    return () => {};
  }, [user]);

	return (
		<Grid
			id="HomeContainer"
			container
			direction={"column"}
			xs={12}
			sx={{
				display: "flex",
				bgcolor: "#670404",
				flex: 1,
				minHeight: "100vh",
				maxWidth: "vw",
				maxHeight: "vh",
			}}
		>
			<SignupContext.Provider value={[signup, setSignup]}>
				<Body />
				<Modal
					open={signup}
					onClose={() => {
						setSignup(false);
					}}
					className="signup-modal"
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						// overflowY: "scroll",
						// backgroundColor: "white",
					}}
				>
					<Signup />
				</Modal>
			</SignupContext.Provider>
		</Grid>
	);
};

const RedTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "green",
    },
    "&:hover fieldset": {
      borderColor: "green",
	  
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
});

const OutlinedInputField = styled(OutlinedInput)({
	
		pallette: {
			primary: {
				main: "green",
			},
		},

  "& label.Mui-focused": {
	color: "green",
  },
  "& .MuiInput-underline:after": {
	borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
	"& fieldset": {
	  borderColor: "green",
	},
	"&:hover fieldset": {
	  borderColor: "green",
	  
	},
	"&.Mui-focused fieldset": {
			borderColor: "green",
		},
	},
}); 

const OutlinedInputLabel = styled(InputLabel)({
	"& label.Mui-focused": {
	  color: "green",
	},
	"& .MuiInput-underline:after": {
	  color: "green",
	},
	"& .MuiOutlinedInput-root": {
	  "& fieldset": {
		color: "green",
	  },
	  "&:hover fieldset": {
		color: "green",
		
	  },
	  "&.Mui-focused fieldset": {
		color: "green",
	},
		},
  });
const Body = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const auth = useContext(AuthContext);
  const [signup, setSignup] = useContext(SignupContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("login-password");

    signInWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        navigate("/letsconnect");
      })
      .catch((error) => {
        if (error && error.code === "auth/user-not-found") {
          setErrors({ ...errors, email: "User not found" });
        }
        if (error && error.code === "auth/wrong-password") {
          setErrors({ ...errors, password: "Wrong password" });
        }
      });
  };
  return (
    <Grid
      item
	  xs={12}
    //   height={"100%"}
      // width={'100%'}
      display={"flex"}
      direction={"column"}
      sx={{
        display: "flex",
        bgcolor: "#DBA622",
        // justifyContent: 'center',
      }}
    >
      <Grid
        container
        item
        xs={6} sm={6} md={4} lg={3} xl={2}
        display={"flex"}
        // bgcolor={'green'}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          container
		  item xs={6} sm={6} md={4} lg={3} xl={2}
		            display={"flex"}
          direction={"column"}
          // bgcolor={'red'}
          justifyContent={"center"}
          gap={"min(2vw, 2vh)"}
          alignItems={"center"}
          height={"560px"}
        >
           <Carousel
            containerWidth={"90%"}
            containerHeight={"90%"}
            carouselWidth={"100%"}
            objectFit={"contain"}
            autoplay={true}
          />
        </Grid>
        <Grid
          container
          item
		  xs={6} sm={6} md={4} lg={3} xl={2}
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#DCDCDC",
            // color: 'white',
            alignItems: "center",
            justifyContent: "center",
            gap: "min(2vw, 2vh)",
            width: "100%",
			height: "500px",
      borderRadius: "10px",
          }}

        >
		<FormControl>
          <Typography variant="h5" p={1} style={{textAlign: "center"}}>
            Log In
          </Typography>
          <RedTextField
            type="email"
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => {
              setTouched({ ...touched, email: true });
			  
            }}
            onBlur={(e) => {
              if (email === "") {
                setErrors({
                  ...errors,
                  email: "Email is required",
                });
              } else {
                setErrors({ ...errors, email: "" });
              }
            }}
          />
		  
          <Typography color={"red"} style={{marginTop: "5px", marginBottom: "3px"}}>
            {touched.email ? errors.email : ""}
          </Typography>
		  {""}
          <FormControl  variant="outlined" >
            <InputLabel htmlFor="outlined-adornment-password"  style={{color:"green", borderColor: "green"}}>
              Password
            </InputLabel>
            <OutlinedInput style={{textTransform: "none", gap: "min(2vw, 2vh)", width: "100%"}}
            //   id="outlined-adornment-password"
			id="login-password"
			value={password}
			onChange={(e) => setPassword(e.target.value)}
						onFocus={() => {
							setTouched({ ...touched, password: true });
						}}              type={showPassword ? "text" : "password"}
 
						onBlur={(e) => {
							if (password === "") {
								setErrors({
									...errors,
									password: "Password is required",
								});
							} else {
								setErrors({ ...errors, password: "" });
							}
						}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined"> */}
            {/* <RedTextField
						type="password"
						id="login-password"
						label="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onFocus={() => {
							setTouched({ ...touched, password: true });
						}}
						onBlur={(e) => {
							if (password === "") {
								setErrors({
									...errors,
									password: "Password is required",
								});
							} else {
								setErrors({ ...errors, password: "" });
							}
						}}
					/> */}
          {/* </FormControl> */}
		  <FormControl>
          <Typography color={"red"}>
            {touched.password ? errors.password : ""}
          </Typography>
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{ backgroundColor: "#DBA622", textTransform: "none", width: "50%", alignSelf: "center", marginTop: "5px", marginBottom: "5px" }}
          >
            Submit
          </Button>
          <Typography style={{textAlign: "center"}}>
            Don't have an account?
            {/* <Link to={"/signup"}>Sign Up</Link> */}
            <div
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setSignup(true);
              }}
            >
              Sign Up!
            </div>
          </Typography>
          <Typography style={{textAlign: "center"}}>
            <div
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                // sendPasswordResetEmail(auth, email)
                // 	.then(() => {
                // 		// Password reset email sent!
                // 		// ..
                // 	})
                // 	.catch((error) => {
                // 		const errorCode = error.code;
                // 		const errorMessage = error.message;
                // 		// ..
                // 	});
                navigate("forgotpassword");
              }}
            >
              Forgot your password?
			  
            </div>
          </Typography>
		  </FormControl>
		  </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
