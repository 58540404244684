import React, { useContext } from "react";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
// import TestingPage from '../pages/TestingPage';
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../contexts/AuthContext";

import { useNavigate } from "react-router-dom";
import pages1 from "./RoutData";
// import NavigateTo from "./NavigateTo";
// import Logo from "../assets/lovelogoWhite.png";
import Logo from "../assets/MobileWebLogo4.gif";

const NavigateMobil = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [pages, setPages] = React.useState(pages1);
  const [user, setUser] = useContext(UserContext);
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
  };

  const toggleSubmenu = (page) => {
    setPages((prevPages) => {
      const newPages = prevPages.map((p) => {
        if (p.id === page.id) {
          p.open = !p.open;
        } else {
          p.open = false;
        }
        return p;
      });
      return newPages;
    });
  };

  const handleNavigateTo = (page) => {
    console.log("Link to Naigate to", page.link);
    if (page.type === "external") {
      return window.open(page.link, "_blank");
    } else if (page.type === "internal") {
      navigate(page.link);
    }
    handleCloseNavMenu();
  };

  const menuItemsMobile = (
    <h6 style={{ textAlign: "center" }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon key={"MenuIcon"} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page, index) => (
          <div>
            <MenuItem key={page.id} onClick={() => toggleSubmenu(page)}>
              <Typography
                fontFamily={"cursive"}
                fontWeight="bold"
                fontSize="20px"
                color={page.open ? "primary" : "purple"}
                textAlign="center"
                onClick={() => {
                  if (
                    [
                      "Socials",
                      "Sports Highlights",
                      "Latest Happenings",
                      "You'r Invited",
                    ].includes(page.name)
                  ) {
                    {
                      handleNavigateTo(page);
                    }
                  } else {
                    if (user) {
                      auth.signOut().then(() => {
                        setUser(null);
                        navigate("/");
                        handleCloseNavMenu();
                      });
                    } else {
                      navigate("/login");
                      handleCloseNavMenu();
                    }
                  }
                }}
              >
                {[
                  "Socials",
                  "Sports Highlights",
                  "Latest Happenings",
                  "You'r Invited",
                ].includes(page.name)
                  ? page.name
                  : user
                  ? "Logout"
                  : "Login"}
              </Typography>
            </MenuItem>
          </div>
        ))}
      </Menu>
    </h6>
  );
  return (
    // <>
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={Logo}
          onClick={() => window.location.replace("/")}
          alt="Logo"
          style={{
            maxHeight: "50px",
            cursor: "pointer",
          }}
        />
        <div style={{ marginLeft: "auto", textAlign: "center" }}>
          {menuItemsMobile}
        </div>
      </div>
    </Box>
    // </>
  );
};

export default NavigateMobil;
