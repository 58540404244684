import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import ReactPlayer from "react-player";

const PostYipImages = (props) => {
	const { photos, imageContainerCounter, height, maxHeight } = props;
	// useEffect(() => {
	// 	console.log(photos);
	// }, [photos]);
	return (
		<Grid
			className="post-yip-images"
			xs={12}
			container
			item
			spacing={1}
			p={1}
			sx={{
				// maxHeight: "40%",
				// maxWidth: "90%",
				backgroundColor: "white",
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: "5px",
				// overflowY: "scroll",
			}}
		>
			{photos?.length > 0 &&
				photos.map((photo, index) => {
					switch (true) {
						case photos.length == 1:
							return (
								<Grid
									container
									item
									display={"flex"}
									xs={12}
								>
									{photo.type == "video/mp4" ? (
										// <video
										// 	style={{
										// 		display: "flex",
										// 		width: "100%",
										// 		height: "100%",
										// 		// maxHeight: "170px",
										// 		// maxWidth: "150px",
										// 		borderRadius: "5px",
										// 	}}
										// >
										// 	<source
										// 		src={photo ? URL.createObjectURL(photo) : null}
										// 	></source>
										// </video>
										<ReactPlayer
											url={photo}
										>

										</ReactPlayer>
									) : (
										<img
											src={URL.createObjectURL(photo)}
											alt="post-yip-photo"
											style={{
												display: "flex",
												width: "100%",
												height: "100%",
												// maxHeight: "170px",
												// maxWidth: "150px",
												objectFit: "cover",
												borderRadius: "5px",
											}}
										/>
									)}
								</Grid>
							);
						case photos.length == 2:
							return (
								<Grid
									container
									item
									display={"flex"}
									xs={12}
									height={"75%"}
								>
									{photo.type == "video/mp4" ? (
										<video
											style={{
												display: "flex",
												width: "100%",
												height: "100%",
												// maxHeight: "170px",
												// maxWidth: "150px",
												objectFit: "cover",
												borderRadius: "5px",
											}}
										>
											<source
												src={URL.createObjectURL(photo)}
												type={photo.type}
											></source>
										</video>
									) : (
										<img
											src={URL.createObjectURL(photo)}
											alt="post-yip-photo"
											style={{
												display: "flex",
												width: "100%",
												height: "100%",
												// maxHeight: "170px",
												// maxWidth: "150px",
												objectFit: "cover",
												borderRadius: "5px",
											}}
										/>
									)}
								</Grid>
							);
						case photos.length > 2:
							//  && photos.length < 5
							if (index === 0) {
								return (
									<Grid
										container
										item
										display={"flex"}
										xs={12}
										width={"100%"}
										height={"70%"}
									>
										{photo.type == "video/mp4" ? (
											<video
												style={{
													display: "flex",
													width: "100%",
													height: "100%",
													// maxHeight: "170px",
													// maxWidth: "150px",
													objectFit: "cover",
													borderRadius: "5px",
												}}
											>
												<source
													src={URL.createObjectURL(photo)}
													type={photo.type}
												></source>
											</video>
										) : (
											<img
												src={URL.createObjectURL(photo)}
												alt="post-yip-photo"
												style={{
													display: "flex",
													width: "100%",
													height: "100%",
													// maxHeight: "170px",
													// maxWidth: "150px",
													objectFit: "cover",
													borderRadius: "5px",
												}}
											/>
										)}
									</Grid>
								);
							}
							if (index < 4) {
								return (
									<Grid
										container
										item
										display={"flex"}
										xs={photos.length == 3 ? 6 : 4}
										width={"100%"}
										height={"30%"}
										// bgcolor={"blue"}
									>
										<div
											style={{
												display: "flex",
												width: "100%",
												height: "100%",
											}}
										>
											{photo.type == "video/mp4" ? (
												<video
													style={{
														display: "flex",
														width: "100%",
														height: "100%",
														// maxHeight: "170px",
														// maxWidth: "150px",
														objectFit: "cover",
														borderRadius: "5px",
													}}
												>
													<source
														src={URL.createObjectURL(photo)}
														type={photo.type}
													></source>
												</video>
											) : (
												<img
													src={URL.createObjectURL(photo)}
													alt="post-yip-photo"
													style={{
														display: "flex",
														width: "100%",
														height: "100%",
														// maxHeight: "170px",
														// maxWidth: "150px",
														objectFit: "cover",
														borderRadius: "5px",
													}}
												/>
											)}
											{photos?.length > 0 &&
											photos.length > 4 &&
											index == 3 &&
											photos.length > imageContainerCounter ? (
												<div
													style={{
														position: "absolute",
														fontSize: "min(5vw, 5vh)",
														color: "white",
														transform: "translate(50%,10%)",
														// background: "rgba(0,0,0,0.5)",
													}}
												>
													{"+" + (photos.length - imageContainerCounter - 1)}
												</div>
											) : (
												""
											)}
										</div>
									</Grid>
								);
							}
							break;
							default:

					}
				})}
		</Grid>
	);
};

export default PostYipImages;
